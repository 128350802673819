import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
// Components
import ControlType from '../../template/ControlType/ControlType';
import Switch from '../../atoms/Switch/Switch';
import Textarea from '../../atoms/Textarea/Textarea';
import Button from '../../atoms/Button/Button';
// Utils
import { GENERATING_SET_INPUTS } from '../../../utils/constant';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function GeneratingSet({ id, value, handleSubmit }) {
  // Hooks
  const { t } = useTranslation();

  const columns = [
    {
      label: value.title,
    },
    {
      label: t('inputs.state'),
      width: 280,
    },
  ];

  const [valuesGenerating, setValuesGenerating] = useState(value);
  const [openModal, setOpenModal] = useState(false);

  const formSwitches = [
    {
      id: 'oilChecked',
      name: GENERATING_SET_INPUTS.OIL,
      label: t('inputs.oil_level'),
    },
    {
      id: 'waterChecked',
      name: GENERATING_SET_INPUTS.WATER,
      label: t('inputs.water_level'),
    },
    {
      id: 'fuelLevelChecked',
      name: GENERATING_SET_INPUTS.FUEL_LEVEL,
      label: t('inputs.fuel_level'),
    },
    {
      id: 'engineWarmingSystemChecked',
      name: GENERATING_SET_INPUTS.ENGINE_WARMING_SYSTEM,
      label: t('inputs.engine_warming_system'),
    },
    {
      id: 'batteryChecked',
      name: GENERATING_SET_INPUTS.BATTERY,
      label: t('inputs.battery_status'),
    },
    {
      id: 'autoStart30minChecked',
      name: GENERATING_SET_INPUTS.AUTO_START_30MIN,
      label: t('inputs.auto_start_30min'),
    },
  ];

  const handleValuesSubmit = () => {
    handleSubmit({
      inputId: valuesGenerating?.id,
      oilChecked: valuesGenerating.oilChecked,
      waterChecked: valuesGenerating.waterChecked,
      fuelLevelChecked: valuesGenerating.fuelLevelChecked,
      engineWarmingSystemChecked: valuesGenerating.engineWarmingSystemChecked,
      batteryChecked: valuesGenerating.batteryChecked,
      autoStart30minChecked: valuesGenerating.autoStart30minChecked,
      comment: valuesGenerating.comment,
    });
    setOpenModal(false);
  };

  const isAtLeastOneValueNull = () => {
    if (valuesGenerating.oilChecked === null
        || valuesGenerating.waterChecked === null
        || valuesGenerating.fuelLevelChecked === null
        || valuesGenerating.engineWarmingSystemChecked === null
        || valuesGenerating.batteryChecked === null
        || valuesGenerating.autoStart30minChecked === null) {
      return true;
    }
    return false;
  };

  return (
    <>
      <ControlType
        columns={columns}
        onSubmit={() => (isAtLeastOneValueNull()
          ? setOpenModal(true)
          : handleValuesSubmit())}
      >
        {formSwitches.map((formSwitch) => (
          <div className="inputForm" key={value.id + formSwitch.id}>
            <div className="inputForm_label inputForm_control">
              {formSwitch.label}
            </div>
            <div className="inputForm_textarea inputForm_control inputForm_control--center">
              <Switch
                id={`${id}-switch-${formSwitch.id}`}
                value={valuesGenerating[formSwitch.id]}
                onChange={(event) => {
                  setValuesGenerating({
                    ...valuesGenerating,
                    [formSwitch.id]: event,
                  });
                }}
              />
            </div>
          </div>
        ))}
        <div className="inputForm">
          <div className="inputForm_label inputForm_control">
            {t('inputs.comment')}
          </div>
          <div className="inputForm_textarea inputForm_control">
            <Textarea
              id={`textarea-${value.id}`}
              name={`textarea-${value.id}`}
              label={t('inputs.comment')}
              labelHidden
              value={valuesGenerating.comment || ''}
              onChange={(event) => setValuesGenerating({
                ...valuesGenerating,
                comment: event.target.value,
              })}
            />
          </div>
        </div>
      </ControlType>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_some_value_neutral')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.no')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.yes')}
            onClick={handleValuesSubmit}
          />
        </div>
      </Modal>
    </>
  );
}

GeneratingSet.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default GeneratingSet;
