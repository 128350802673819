/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faChevronRight, faSignOut, faUser, faUserSecret, faBars, faXmark, faClose,
} from '@fortawesome/free-solid-svg-icons';
// Hooks
import Modal from 'react-modal';
import useAppContext from '../../store/useAppContext';
// Components
import Navigation from '../molecules/Navigation/Navigation';
import QueryError from '../molecules/QueryError/QueryError';
import Permissions from '../molecules/Permissions/Permissions';
// Utils
import userPicture from '../../assets/img/user.jpg';
import styles from './Layout.module.css';
import cn from '../../utils/cn';
import Button from '../atoms/Button/Button';
import { USERS_ROLES } from '../../utils/constant';
import Notification from '../../views/NotificationDashbord/NotificationDashbord';
import Picto from '../atoms/Picto/Picto';

function Layout({
  title, description, layout, queryError, children, isLoading,
}) {
  // States
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // Hooks
  const { t } = useTranslation();
  const [context, dispatch, currentUser] = useAppContext();
  const navigate = useNavigate();

  const handleSignOut = () => {
    dispatch({ type: 'CLEAR_CONTEXT' });
    navigate('/');
  };

  const handleGoBackAccount = () => {
    dispatch({ type: 'SET_USER', payload: context?.userImpersonated });
    dispatch({ type: 'SET_PERMISSIONS', payload: [] });
    dispatch({ type: 'CLEAR_USER_IMPERSONATED' });
    navigate('/dashboard');
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 60rem)');
    const resize = () => {
      if (mediaQuery.matches) {
        setMenuCollapsed(true);
      } else {
        setMenuCollapsed(false);
      }
    };
    resize();
    mediaQuery.addEventListener('change', resize);
    return () => mediaQuery.removeEventListener('change', resize);
  }, []);

  const isAdminOrSuperAdmin = context?.user?.role === USERS_ROLES.SUPER_ADMIN || context?.user?.role === USERS_ROLES.ADMIN || context?.userImpersonated?.role === USERS_ROLES.SUPER_ADMIN || context?.userImpersonated?.role === USERS_ROLES.ADMIN;
  const nameEstablishment = context?.choiceEstablishment?.name;
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      maxWidth: '90%',
      maxHeight: '70%',
      right: 'auto',
      bottom: 'auto',
      borderRadius: '1rem',
      boxShadow: '0.1rem 0.1rem white',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '4rem 2rem 0 2rem',
    },
  };
  return (
    <>
      <Helmet>
        <title>
          {title}
          {' '}
          -
          {' '}
          {t('global.site_name')}
        </title>
        <meta name="description" content={description} />
      </Helmet>
      <div className={styles.layout}>
        <aside className={cn([styles.aside, menuCollapsed ? styles.asideCollapsed : '', menuVisible ? styles.asideVisible : ''])}>
          <Navigation isCollapsed={menuCollapsed} />
          <button type="button" className={styles.menuToggle} onClick={() => setMenuCollapsed(!menuCollapsed)}>
            <FontAwesomeIcon icon={menuCollapsed ? faChevronRight : faChevronLeft} />
          </button>
        </aside>
        <header className={cn([styles.header, (context.choiceBooklet === 2 ? styles.headerSecurite : ''),
          (context.choiceBooklet === 1 ? styles.headerSanitaire : ''),
          (context.choiceBooklet === 3 ? styles.headerVehicule : '')])}
        >
          <button type="button" className={cn([styles.menuBurger, menuVisible ? styles.menuBurgerVisible : ''])} onClick={() => setMenuVisible(!menuVisible)}>
            <FontAwesomeIcon icon={menuVisible ? faXmark : faBars} />
          </button>
          <h2 className={cn([styles.nameEstablishment])}>
            { !!nameEstablishment && (
              <>
                <Picto className={styles.picto} name="etablissementsBlancSimple" width="40" height="40" />
                { nameEstablishment }
              </>
            )}
          </h2>
          { !isAdminOrSuperAdmin && (Permissions({ permission: 'NOTIFICATION_LIST' }) !== undefined
            || Permissions({ permission: 'BOOKLET_READ' }) !== undefined)
            ? (
              <div className={styles.spacer}>
                <div className={styles.profiles} onClick={() => setOpenModal(!openModal)} aria-hidden="true">
                  <div className={styles.dropdownNotif}>
                    <span className={styles.dropdownLinks}>{t('navigation.notifications')}</span>
                    <Picto className={styles.pictos} name="notificationwhite" width="40" height="40" />
                  </div>
                  <Modal
                    isOpen={openModal}
                    onRequestClose={() => setOpenModal(false)}
                    style={customStyles}
                    ariaHideApp={false}
                  >
                    <div className={styles.button}>
                      <Button
                        type="button"
                        className={styles.close}
                        label={<FontAwesomeIcon icon={faClose} />}
                        onClick={() => setOpenModal(false)}
                      />
                    </div>
                    <Notification />
                  </Modal>
                </div>
              </div>
            ) : null}
          <div role="button" className={styles.profile} onClick={() => setDropdown(!dropdown)} aria-hidden="true">
            <div className={styles.userName}>
              {`${currentUser?.firstName} ${currentUser?.lastName}`}
            </div>
            <img className={styles.userPicture} src={userPicture} alt="" width="32" height="32" />
          </div>
          <div className={cn([styles.dropdown, !dropdown ? 'hidden' : '', 'shadow-lg'])}>
            <Link className={styles.dropdownLink} to={`/Edituser/${currentUser?.id}`}>
              <FontAwesomeIcon icon={faUser} />
              <span className={styles.dropdownLabel}>Mon Profil</span>
            </Link>
            <div className={styles.divider} />
            <button type="button" className={styles.dropdownLink} onClick={handleSignOut}>
              <FontAwesomeIcon icon={faSignOut} />
              <span className={styles.dropdownLabel}>Déconnexion</span>
            </button>
          </div>
          {context.userImpersonated ? (
            <Button
              type="button"
              className={styles.switchUser}
              label={<FontAwesomeIcon icon={faUserSecret} />}
              title={t('global.go_back_account')}
              onClick={handleGoBackAccount}
            />
          ) : null}
        </header>
        <main className={cn([styles.main, layout === 'table' ? styles.table : ''])}>
          <QueryError error={queryError} />
          {children}
        </main>
        {isLoading && (
        <div className="loader" />
        )}
      </div>
    </>
  );
}

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  layout: PropTypes.oneOf(['table', '']),
  queryError: PropTypes.shape({
    response: PropTypes.shape({
      data: PropTypes.shape({
        message: PropTypes.string,
      }),
    }),
  }),
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

Layout.defaultProps = {
  layout: '',
  queryError: null,
  isLoading: false,
  description: 'Ecarnet - Expertise et accompagnement',
};

export default Layout;
