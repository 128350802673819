/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from 'react-query';
import {
  faEye, faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

// Components
import Table from '../../molecules/Table/Table';
import Pagination from '../../molecules/Pagination';

// services
import { getUsersList } from '../../../services/users';

// utils & misc
import { USERS_ROLES } from '../../../utils/constant';
import styles from './UserList.module.css';
import useAppContext from '../../../store/useAppContext';

function UsersList({ establishmentId }) {
  const { t } = useTranslation();
  const [responses, setResponses] = useState([]);
  const [info, setInfo] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [context] = useAppContext();

  // API Calls
  const getUsersListQuery = useQuery(
    ['usersList'],
    () => getUsersList({
      structureId: establishmentId,
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
    }),
    {
      onSuccess: (data) => {
        setStart(0);
        setEnd(10);
        setResponses(data?.data?.users?.slice(start, end));
        setInfo(data);
      },
    },
  );

  const columns = useMemo(
    () => [
      {
        Header: `${t('users.id')}`,
        accessor: 'id',
      },
      {
        Header: `${t('users.email')}`,
        accessor: 'email',
      },
      {
        Header: `${t('users.role')}`,
        accessor: 'role',
        Cell: ({ row: { original: { role } } }) => (
          `${role === USERS_ROLES.SUPER_ADMIN ? t('add_user.super_admin') : ''} 
          ${role === USERS_ROLES.ADMIN ? t('add_user.admin') : ''}
          ${role === USERS_ROLES.ADMIN_GROUP ? t('add_user.admin_group') : ''}
          ${role === USERS_ROLES.USER ? t('add_user.user') : ''}`
        ),
      },
      {
        Header: `${t('users.lastname')}`,
        accessor: 'lastName',
      },
      {
        Header: `${t('users.firstname')}`,
        accessor: 'firstName',
      },
      {
        Header: `${t('users.company')}`,
        accessor: 'company',
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            <Link
              className="action"
              to={`/users/edit/${id}`}
              title={t('users.edit')}
            >
              <FontAwesomeIcon icon={faEye} />
            </Link>
          </div>
        ),
      },
    ],
    [],
  );

  // pagination de la popup
  const next = () => {
    setCurrentPage(currentPage + 1);
    setStart(currentPage * 10);
    setEnd(currentPage * 10 + 10);
    setResponses(info?.data?.users?.slice(currentPage * 10, currentPage * 10 + 10));
  };
  const previous = () => {
    setCurrentPage(currentPage - 1);
    setResponses(info?.data?.users?.slice(currentPage * 10 - 10 - 10, currentPage * 10 - 10));
  };
  const changePage = (e) => {
    setCurrentPage(e);
    setStart((e - 1) * 10);
    setEnd(e * 10 + 10);
    setResponses(info?.data?.users?.slice((e - 1) * 10, e * 10 + 10));
  };

  return (
    <div className={styles.usersList}>
      <Table
        columns={columns}
        isLoading={getUsersListQuery.isLoading}
        data={responses || []}
        hasSort
        pagination={(
          <Pagination
            previousLabel={(
              <FontAwesomeIcon
                icon={faChevronLeft}
                onClick={() => { previous(); }}
              />
            )}
            nextLabel={(
              <FontAwesomeIcon
                icon={faChevronRight}
                onClick={() => {
                  next();
                }}
              />
            )}
            onPageChange={(e) => {
              changePage(e);
            }}
            totalCount={getUsersListQuery?.data?.data?.total || 0}
            currentPage={currentPage}
            pageSize={10}
          />
      )}
      />
    </div>
  );
}

UsersList.propTypes = {
  establishmentId: PropTypes.number,
};
UsersList.defaultProps = {
  establishmentId: null,
};

export default UsersList;
