/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-else-return */
import React, { useMemo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faChevronRight, faFileCsv,
} from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
// Components
// import { CSVLink } from 'react-csv';
import Layout from '../../components/template/Layout';
import Search from '../../components/molecules/Search/Search';
import Table from '../../components/molecules/Table/Table';
import Pagination from '../../components/molecules/Pagination';
import Button from '../../components/atoms/Button/Button';
// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import { getIndicator } from '../../services/indicator';
// Styles
import styles from './Indicateur.module.css';
// Utils
import { formatDateToUser } from '../../utils/dates';
import cn from '../../utils/cn';
import { downloadFileCsv } from '../../utils/downloadFile';

function Indicateur() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const [search, setSearch] = useState('');
  const [bookletChoice, setbookletChoice] = useState(2);
  // const currentPage = window.location.href.split('=')[1];
  const [response, setResponse] = useState([]);
  const [dowloadInfo, setDowloadInfo] = useState([]);
  // API Calls
  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const getIndicatorQuery = useQuery(['indicators', page, search], () => getIndicator({
    // bookletId: bookletChoice,
    page: 0,
    search,
  }), {
    onSuccess: (data) => {
      setResponse(data?.data?.indicators);
      setDowloadInfo(data?.data?.indicators);
    },
  });
  useEffect(() => {
    // sorry, for this hacky function, but it exist a little defer
    // between the feedback of back after post and the real refresh of list
    // and as back dev say it's not from DB then..
    // setTimeout(() => getReportsListQuery.refetch(), 1500);
  }, []);

  const isDateBeforeToday = (dateToCheck) => dateToCheck && new Date(dateToCheck) < new Date();

  const handleBuilding = (id) => {
    setbookletChoice(id);
    onPageChange(1);
  };
  const hearder = [
    {
      label: `${t('reports.building')}`,
      key: 'buildingName',
      cell: (row) => row?.original?.structure?.name || '-',
    },
    {
      label: `${t('indicateur.division')}`,
      key: 'division',
      cell: (row) => row?.original?.structure?.division?.name || '-',
    },
    {
      label: `${t('indicateur.subsidiary')}`,
      key: 'subsidiary',
      cell: (row) => row?.original?.structure?.subsidiary?.name || '-',
    },
    {
      label: `${t('indicateur.group')}`,
      key: 'group',
      cell: (row) => row?.original?.structure?.group?.name || '-',
    },
    {
      label: `${t('indicateur.recordKeepingRate1')}`,
      key: 'recordKeepingRate1',
      cell: (row) => row?.original?.recordKeepingRate1 || '-',
    },
    {
      label: `${t('indicateur.lateSecurityReportsCount')}`,
      key: 'lateSecurityReportsCount',
      cell: (row) => row?.original?.lateSecurityReportsCount || '-',
    },
    {
      label: `${t('indicateur.totalObservationsToLiftCount')}`,
      key: 'totalObservationsToLiftCount',
      cell: (row) => row?.original?.totalSecurityObservationsToLiftCount || '-',
    },
    {
      label: `${t('indicateur.totalObservationsToLiftPercent')}`,
      key: 'totalObservationsToLiftPercent',
      cell: (row) => row?.original?.techniciansSecurityLiftedObservationsPercent || '-',
    },
    {
      label: `${t('indicateur.lastsecuritycommitteenotice')}`,
      key: 'lastSecurityCommitteeNotice',
      Cell: ({ row }) => (
        <div>
          {row?.original?.lastSecurityCommitteeNotice !== null ? (
            <div className={`${row?.original?.lastSecurityCommitteeNotice === 1 ? 'tag--success' : 'tag--error'}`}>
              {row?.original?.lastSecurityCommitteeNotice === 1 ? t('indicateur.favorable') : t('indicateur.unfavorable')}
            </div>
          ) : '-'}
        </div>
      ),
    },
  ];

  const handleDowload = () => {
    downloadFileCsv(dowloadInfo, hearder, `${t('indicateur.title')}`);
  };
  const columnsSecurite = useMemo(
    () => [
      {
        Header: `${t('indicateur.establishment')}`,
        accessor: 'establishment',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.name || '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.division')}`,
        accessor: 'division',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.division?.name || '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.subsidiary')}`,
        accessor: 'subsidiary',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.subsidiary?.name || '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.group')}`,
        accessor: 'group',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.group?.name || '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.recordKeepingRate1')}`,
        accessor: 'securityRecordKeepingRate1',
        Cell: ({ row }) => (
          <div>
            {row?.original?.securityRecordKeepingRate1}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.lateSecurityReportsCount')}`,
        accessor: 'lateSecurityReportsCount',
        Cell: ({ row }) => (
          <div>
            {row?.original?.lateSecurityReportsCount}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.totalObservationsToLiftCount')}`,
        accessor: 'totalSecurityObservationsToLiftCount',
        Cell: ({ row }) => (
          <div>
            {row?.original?.totalSecurityObservationsToLiftCount}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.totalObservationsToLiftPercent')}`,
        accessor: 'techniciansSecurityLiftedObservationsPercent',
        Cell: ({ row }) => (
          <div>
            {row?.original?.techniciansSecurityLiftedObservationsPercent}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.lastsecuritycommitteenotice')}`,
        accessor: 'lastsecuritycommitteenotice',
        Cell: ({ row }) => (
          <div>
            {row?.original?.lastSecurityCommitteeNotice !== null ? (
              <div className={`${row?.original?.lastSecurityCommitteeNotice === 1 ? 'tag--success' : 'tag--error'}`}>
                {row?.original?.lastSecurityCommitteeNotice === 1 ? t('indicateur.favorable') : t('indicateur.unfavorable')}
              </div>
            ) : '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.nextCommitteeDate')}`,
        accessor: 'nextSecurityCommitteeDate',
        Cell: ({ row: { original: { nextSecurityCommitteeDate } } }) => (
          <div className={`${isDateBeforeToday(nextSecurityCommitteeDate) ? 'tag--error' : ''}`}>
            {formatDateToUser(nextSecurityCommitteeDate)}
          </div>
        ),
      },
    ],
    [],
  );

  const columnsSanitaire = useMemo(
    () => [
      {
        Header: `${t('indicateur.establishment')}`,
        accessor: 'establishment',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.name}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.division')}`,
        accessor: 'division',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.division?.name || '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.subsidiary')}`,
        accessor: 'subsidiary',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.subsidiary?.name || '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.group')}`,
        accessor: 'group',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.group?.name || '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.sanitaryInputsDonePercent')}`,
        accessor: 'sanitaryInputsDonePercent',
        Cell: ({ row }) => (
          <div>
            {row?.original?.sanitaryInputsDonePercent}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.lastTwoWeekSanitaryInputsDonePercent')}`,
        accessor: 'lastTwoWeekSanitaryInputsDonePercent',
        Cell: ({ row }) => (
          <div>
            {row?.original?.lastTwoWeekSanitaryInputsDonePercent}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.lastYearSanitaryInputsDonePercent')}`,
        accessor: 'lastYearSanitaryInputsDonePercent',
        Cell: ({ row }) => (
          <div>
            {row?.original?.lastYearSanitaryInputsDonePercent}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.pneumophilaAnalysisPercent')}`,
        accessor: 'pneumophilaAnalysisPercent',
        Cell: ({ row }) => (
          <div>
            {row?.original?.pneumophilaAnalysisSanitaryPercent}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.complianceLastSanitaryAnalysisPercent')}`,
        accessor: 'complianceLastSanitaryAnalysisPercent',
        Cell: ({ row }) => (
          <div>
            {row?.original?.complianceLastSanitaryAnalysisPercent}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.lateSanitaryReportsCount')}`,
        accessor: 'lateSanitaryReportsCount',
        Cell: ({ row }) => (
          <div>
            {row?.original?.lateSanitaryReportsCount}
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Layout
      title={t('indicateur.title')}
      layout="table"
      queryError={getIndicatorQuery?.error}
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate('/dashboard')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">{t('indicateur.title')}</h1>
        </div>
        <div className="row">
          <button
            className="add"
            type="button"
            onClick={handleDowload}
          >
            <FontAwesomeIcon icon={faFileCsv} />
            {' '}
            {t('reports.download')}
          </button>
          <Search className="mb-20" onChange={handleSearch} />
        </div>
      </header>
      <div className={styles.filters}>
        <div className={styles.filtersToComplete}>
          <Button
            type="button"
            label={t('indicateur.securite')}
            className={cn([styles.filter, bookletChoice === 2
              ? styles.active
              : '', 'shadow-md'])}
            onClick={() => handleBuilding(2)}
          />
          <Button
            type="button"
            label={t('indicateur.sanitaire')}
            className={cn([styles.filter, bookletChoice === 1
              ? styles.active
              : '', 'shadow-md'])}
            onClick={() => handleBuilding(1)}
          />
        </div>
      </div>
      { bookletChoice === 1 && (
      <div className={styles.table}>
        <Table
          columns={columnsSanitaire}
          isLoading={getIndicatorQuery.isLoading}
          data={response || []}
          hasSort
          hasPagination
          pagination={(
            <Pagination
              previousLabel={(
                <FontAwesomeIcon icon={faChevronLeft} />
              )}
              nextLabel={(
                <FontAwesomeIcon icon={faChevronRight} />
              )}
              onPageChange={onPageChange}
              totalCount={getIndicatorQuery?.data?.data?.total || 0}
              currentPage={page}
              // haveToPaginate
              pageSize={10}
              // numberElementsDisplayed={response?.length || 0}
            />
        )}
        />
      </div>
      )}
      { bookletChoice === 2 && (
      <div className={styles.table}>
        <Table
          columns={columnsSecurite}
          isLoading={getIndicatorQuery.isLoading}
          data={response || []}
          hasSort
          hasPagination
          pagination={(
            <Pagination
              previousLabel={(
                <FontAwesomeIcon icon={faChevronLeft} />
              )}
              nextLabel={(
                <FontAwesomeIcon icon={faChevronRight} />
              )}
              onPageChange={onPageChange}
              totalCount={getIndicatorQuery?.data?.data?.total || 0}
              currentPage={page}
              // haveToPaginate
              pageSize={10}
              // numberElementsDisplayed={response?.length || 0}
            />
        )}
        />
      </div>
      )}
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate('/dashboard')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default Indicateur;
