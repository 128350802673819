import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import * as Yup from 'yup';

// Components
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import { editFolder, getFolder, postFolder } from '../../services/dossier';
import useAppContext from '../../store/useAppContext';
import Radio from '../../components/atoms/Radio/Radio';
// import UploadFile from '../../components/atoms/UploadFile/UploadFile';
// import useAppContext from '../../store/useAppContext';
// css
// import styles from './AddDocument.module.css';

function AddDocument() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const urlParams = useParams();
  const [context] = useAppContext();

  const goBackUrl = (message) => {
    navigate(-1);
    toast.success(message);
  };

  const createFolderMutation = useMutation(postFolder, {
    onSuccess: () => {
      goBackUrl(t('dossierBase.adddossier'));
    },
  });

  const putFilder = useMutation(editFolder, {
    onSuccess: () => {
      goBackUrl(t('dossierBase.editdossier'));
    },
    onError: () => {
      toast.error(t('dossierBase.error'));
    },
  });

  const initialValues = {
    name: '',
    date: '',
    submitted: '',
    workautho: '',
    townhall: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('global.required_field')),
    date: Yup.string().required(t('global.required_field')),
    submitted: Yup.string().required(t('global.required_field')),
    workautho: Yup.number(),
    townhall: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('categoryId', urlParams.categoryid);
      formData.append('structureId', context.choiceEstablishment.id);
      formData.append('name', values.name);
      formData.append('date', values.date);
      formData.append('submitted', values.submitted);
      formData.append('workautho', values.workautho);
      if (urlParams.folderId) {
        putFilder.mutate({
          id: urlParams.folderId,
          data: {
            categoryId: urlParams.categoryid,
            structureId: context.choiceEstablishment.id,
            name: values.name,
            date: values.date,
            submitted: values.submitted,
            workautho: values.workautho,
          },
        });
      } else {
        createFolderMutation.mutate(formData);
      }
    },
  });

  const getFolderQuery = useMutation(getFolder, {
    onSuccess: (res) => {
      formik.setFieldValue('name', res.data.name);
      formik.setFieldValue('date', format(new Date(res?.data?.date), 'yyyy-MM-dd'));
      formik.setFieldValue('submitted', res.data.submitted);
      formik.setFieldValue('workautho', res.data.workautho);
      formik.setFieldValue('document', res.data.document);
    },
  });
  useEffect(() => {
    if (urlParams.folderId) {
      getFolderQuery.mutate(urlParams.folderId);
    }
  }, []);
  return (
    <Layout
      title="Documentaire Base Item"
      layout="table"
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('dossierBase.return')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">
            {urlParams.folderId ? t('dossierBase.editfolder') : t('dossierBase.addfolder')}
          </h1>
        </div>
      </header>
      <div>
        <form onSubmit={formik.handleSubmit} className="form shadow-sm">
          <div className="form_group">
            <Input
              id="name"
              name="name"
              type="text"
              label={t('dossierBase.name')}
              placeholder={t('dossierBase.name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              required
            />
            {formik?.errors?.name && formik?.touched?.name ? (
              <div className="error">
                {t(formik?.errors?.name)}
              </div>
            ) : null }
          </div>
          <div className="form_group">
            <Input
              id="date"
              name="date"
              type="date"
              label={t('documentary.date')}
              placeholder={t('documentary.date')}
              value={formik.values.date}
              onChange={formik.handleChange}
              required
            />
            {formik?.errors?.date && formik?.touched?.date ? (
              <div className="error">
                {t(formik?.errors?.date)}
              </div>
            ) : null }
          </div>
          <div className="form_group">
            <div className="inputForm_label">{t('dossierBase.soumis')}</div>
            <Radio
              type="radio"
              id="declamation"
              name="declamation"
              label={t('dossierBase.declamation')}
              value="declamation"
              checked={formik.values.submitted === 'declamation'}
              onChange={(e) => formik.setFieldValue('submitted', e.target.value)}
              onBlur={formik.handleBlur}
            />
            <Radio
              type="radio"
              id="permis"
              name="permis"
              label={t('dossierBase.permis')}
              value="permis"
              checked={formik.values.submitted === 'permis'}
              onChange={(e) => formik.setFieldValue('submitted', e.target.value)}
              onBlur={formik.handleBlur}
            />
            <Radio
              type="radio"
              id="autorisation"
              name="autorisation"
              label={t('dossierBase.autorisation')}
              value="autorisation"
              checked={formik.values.submitted === 'autorisation'}
              onChange={(e) => formik.setFieldValue('submitted', e.target.value)}
              onBlur={formik.handleBlur}
            />
            {formik.errors.submitted && formik.touched.submitted ? (
              <div className="error">
                {t(formik.errors.submitted)}
              </div>
            ) : null }
          </div>
          <div className="form_group">
            <Input
              id="workautho"
              name="workautho"
              type="number"
              label={t('dossierBase.workautho')}
              placeholder={t('dossierBase.workautho')}
              value={formik.values.workautho}
              onChange={formik.handleChange}
            />
            {formik?.errors?.workautho && formik?.touched?.workautho ? (
              <div className="error">
                {t(formik?.errors?.workautho)}
              </div>
            ) : null }
          </div>
          {/* <div className="form_group">
            <div className="inputForm_label">{t('dossierBase.townhall')}</div>
            <UploadFile
              id="document"
              name="document"
              label={t('addReports.report')}
              fileName={formik.values.document ? formik.values.document.name : formik.values.document}
              onChange={(event) => formik.setFieldValue('document', event.currentTarget.files[0])}
              onBlur={formik.handleBlur}
            />
            {formik.errors.townhall && formik.touched.townhall ? (
              <div className="error">
                {t(formik.errors.townhall)}
              </div>
            ) : null }
          </div>
          <div className="form_group">
            {' '}
          </div>
          <div className="form_group">
            <Checkbox
              id="townhall"
              name="townhall"
              label={t('dossierBase.checkbox')}
              value={formik.values.townhall === '1' ? '0' : '1'}
              checked={formik.values.townhall === '1'}
              disabled={formik.values.document}
              onChange={(e) => formik.setFieldValue('townhall', e.target.value)}
              onBlur={formik.handleBlur}
            />
          </div> */}
          <div className="form_footer">
            <div className="form_infos">
              <small>{t('addReports.mandatory_fields')}</small>
            </div>
            <Button
              type="submit"
              className="form_submit"
              label={t('documentary.save')}
            />
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default AddDocument;
