/* eslint-disable import/no-unresolved */
import SignaturePad from 'react-signature-canvas';
import PropTypes from 'prop-types';
import styles from './CanvasSignature.module.css';
import Button from '../Button/Button';
import cn from '../../../utils/cn';

function CanvasSignature({
  trim, clear, sigCanvas, canvasProps, className,
}) {
  // const setSignature = signature.trim().length > 0;
  return (
    <div className={styles.canvasSignature}>
      <div className={cn([className, styles.sigContainer])}>
        <SignaturePad
          ref={sigCanvas}
          canvasProps={canvasProps}
        />
      </div>
      <div className={styles.signatureContainer}>
        <Button
          type="button"
          className={cn(['action suppr', styles.clearbtn])}
          onClick={clear}
          label="effacer"
        />
        <Button
          type="button"
          className={cn(['action', styles.trimbtn])}
          label="Valider"
          onClick={trim}
        />
      </div>
    </div>
  );
}
CanvasSignature.propTypes = {
  trim: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  // signature: PropTypes.string
  sigCanvas: PropTypes.object.isRequired,
  canvasProps: PropTypes.object,
  className: PropTypes.string,
};
CanvasSignature.defaultProps = {
  canvasProps: {},
  className: '',
};
export default CanvasSignature;
