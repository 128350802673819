import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { sortBy } from 'lodash';
import {
  createContractVehicle,
  updateContractVehicle,
  getContractVehicle,
  endContractVehicle,
} from '../../services/contracts';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import Layout from '../../components/template/Layout';
import Select from '../../components/atoms/Select/Select';
// import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';
import { getVehiculelist } from '../../services/vehicules';
import useAppContext from '../../store/useAppContext';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';

function Contract() {
  const { t } = useTranslation();
  const context = useAppContext();
  const navigate = useNavigate();
  const [buildingId] = useState(context?.choiceEstablishment?.buildingIds);
  const urlParams = useParams();

  const goBackUrl = (message) => {
    navigate(-1);
    toast.success(message);
  };

  const VehiculeQueryList = useQuery(['vehicle', buildingId], () => getVehiculelist({
    establishmentId: context?.choiceEstablishment?.id,
    buildingId,
  }), {
    onSuccess: () => {
    },
  });

  const createContractMutation = useMutation(createContractVehicle, {
    onSuccess: () => {
      goBackUrl(t('contract.add_success'));
    },
  });

  const updateContractMutation = useMutation(updateContractVehicle, {
    onSuccess: () => {
      toast.success(t('contract.success'));
    },
  });

  const endContractMutation = useMutation(endContractVehicle);

  const mapForSelect = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['name']).map((item) => ({
      ...item,
      label: `${item.model} ${item.licensePlate}`,
      value: item.id,
    }))
    : []);

  const initialValues = {
    vehicle: '',
    leasingCompany: '',
    effectiveDate: '',
    endDate: '',
    milesIncluded: '',
    milesInStart: '',
    documentFile: '',
    enabled: true,
  };
  const validationSchema = Yup.object({
    vehicle: Yup.string(),
    leasingCompany: Yup.string()
      .required(t('contract.leasingCompany_required')),
    effectiveDate: Yup.string(),
    endDate: Yup.string(),
    milesIncluded: Yup.string(),
    milesInStart: Yup.string(),
    documentFile: Yup.string(),
    enabled: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      if (!urlParams?.id) {
        formData.append('vehicleId', values.vehicle);
        formData.append('leasingCompany', values.leasingCompany);
        formData.append('effectiveDate', values.effectiveDate);
        formData.append('endDate', values.endDate);
        formData.append('milesIncluded', values.milesIncluded);
        formData.append('milesInStart', values.milesInStart);
        formData.append('documentFile', values.documentFile);
        formData.append('enabled', values.enabled);
        createContractMutation.mutate(formData);
      } else {
        const config = {
          id: urlParams?.id,
          data: values,
        };
        const enabled = {
          id: urlParams?.id,
          enabled: values.enabled,
        };
        endContractMutation.mutate(enabled);
        updateContractMutation.mutate(config);
      }
    },
  });

  const getContractQuery = useQuery(['contract', buildingId], () => getContractVehicle({
    establishmentId: context?.choiceEstablishment?.id,
    buildingId,
    id: urlParams?.id,
  }), {
    enabled: !!urlParams?.id,
    onSuccess: (data) => {
      formik.setFieldValue('vehicle', data?.data?.vehicle[0]?.id);
      formik.setFieldValue('leasingCompany', data?.data?.leasingCompany);
      formik.setFieldValue('effectiveDate', data?.data?.effectiveDate);
      formik.setFieldValue('endDate', data?.data?.endDate);
      formik.setFieldValue('milesIncluded', data?.data?.milesIncluded);
      formik.setFieldValue('milesInStart', data?.data?.milesInStart);
      formik.setFieldValue('documentFile', data?.data?.documentFileName);
      formik.setFieldValue('enabled', data?.data?.enabled);
    },
  });

  return (
    <Layout
      title="Contrat"
      layout="form"
      queryError={
        createContractMutation.error
        || VehiculeQueryList.error
        || updateContractMutation.error
        || getContractQuery.error
        || endContractMutation.error
      }
    >
      <div>
        <header>
          <div className="row mb-20">
            <button
              type="button"
              className="link"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('contract.back_add')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t(urlParams?.id ? 'contract.edit_contract' : 'contract.add_contract')}</h1>
          </div>
        </header>
      </div>
      <form onSubmit={formik.handleSubmit} className="form shadow-sm">
        <div className="form_group">
          <Select
            name="vehicle"
            type="text"
            label={t('contract.vehicle')}
            value={
              mapForSelect(VehiculeQueryList?.data?.data?.vehicles)?.find(
                (item) => item.value === formik.values.vehicle,
              )
            }
            onChange={(value) => formik.setFieldValue('vehicle', value.value)}
            options={mapForSelect(VehiculeQueryList?.data?.data?.vehicles)}
            required
            valueInput={formik.values.vehicle}
            loading={VehiculeQueryList.isLoading}
          />
          {formik.errors.vehicle && formik.touched.vehicle ? (
            <div className="error">
              {t(formik.errors.vehicle)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            name="leasingCompany"
            type="text"
            min="0"
            label={t('contract.loc')}
            value={formik.values.leasingCompany}
            onChange={formik.handleChange}
            required
          />
          {formik.errors.leasingCompany && formik.touched.leasingCompany ? (
            <div className="error">
              {t(formik.errors.leasingCompany)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            name="effectiveDate"
            type="date"
            min="0"
            label={t('contract.dateStart')}
            value={formik.values.effectiveDate}
            onChange={formik.handleChange}
            required
          />
          {formik.errors.effectiveDate && formik.touched.effectiveDate ? (
            <div className="error">
              {t(formik.errors.effectiveDate)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            name="endDate"
            type="date"
            min="0"
            label={t('contract.dateEnd')}
            value={formik.values.endDate}
            onChange={formik.handleChange}
            required
          />
          {formik.errors.endDate && formik.touched.endDate ? (
            <div className="error">
              {t(formik.errors.endDate)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            name="milesInStart"
            type="number"
            min="0"
            label={t('contract.km_debut')}
            value={formik.values.milesInStart}
            onChange={formik.handleChange}
            required
          />
          {formik.errors.milesInStart && formik.touched.milesInStart ? (
            <div className="error">
              {t(formik.errors.milesInStart)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            name="milesIncluded"
            type="number"
            min="0"
            label={t('contract.km_inclus')}
            value={formik.values.milesIncluded}
            onChange={formik.handleChange}
            required
          />
          {formik.errors.milesIncluded && formik.touched.milesIncluded ? (
            <div className="error">
              {t(formik.errors.milesIncluded)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <div className="label">{t('add_training.document_file')}</div>
          <UploadFile
            id="documentFile"
            name="documentFile"
            label={t('contract.report')}
            fileName={formik.values.documentFile ? formik.values.documentFile.name : formik.values.documentFile}
            onChange={(event) => formik.setFieldValue('documentFile', event.currentTarget.files[0])}
            onBlur={formik.handleBlur}
          />
          {formik.errors.documentFile && formik.touched.documentFile ? (
            <div className="error">
              {t(formik.errors.documentFile)}
            </div>
          ) : null }
        </div>
        {urlParams?.id && (
          <div className="form_group">
            <Checkbox
              name="enabled"
              label={t('contract.contract_actif')}
              checked={formik.values.enabled}
              onChange={formik.handleChange}
            />
          </div>
        )}
        <section className="form_footer">
          <div className="form_infos">
            <small>{t('add_structure.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t(urlParams?.id ? 'contract.edit_contract' : 'contract.add_contract')}
          />
        </section>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('contract.back_add')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default Contract;
