/* eslint-disable max-len */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Components
import Switch from '../../../atoms/Switch/Switch';
import Select from '../../../atoms/Select/Select';
import Textarea from '../../../atoms/Textarea/Textarea';
// Utils

function SwitchListComment({
  id, label, value, options, onChange,
}) {
  // Hooks
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const noop = () => {
    // no operation (do nothing real quick)
  };

  const isTroubleTypeRequired = () => {
    if (value.isDirty && value.checked === false) return true;
    return false;
  };
  const isCommRequired = () => {
    if (value.isDirty && (value.checked === false || value.checked === null)) return true;
    return false;
  };

  return (
    <div className="inputForm" data-input-type={value?.inputType}>
      <div className="inputForm_control inputForm_label">
        {label}
      </div>
      <div className="inputForm_control inputForm_switch">
        <Switch
          id={`switch-${id}`}
          value={value.checked}
          onChange={(event) => onChange({
            ...value,
            checked: event,
          })}
        />
      </div>
      <div className="inputForm_control inputForm_select">
        <Select
          id={`select-${id}`}
          label={t('inputs.disturbance_type')}
          options={options}
          ref={inputRef}
          labelHidden
          value={options.find((option) => option.value === value.troubleType)}
          onChange={(event) => onChange({
            ...value,
            troubleType: event.value,
          })}
          valueInput={value.troubleType}
          required={isTroubleTypeRequired()}
        />
        {isTroubleTypeRequired() ? (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              width: '17.5rem',
              height: 0,
              position: 'absolute',
            }}
            value={value.troubleType}
            onChange={noop}
            onFocus={() => inputRef.current.focus()}
            required
          />
        ) : null}
      </div>
      <div className="inputForm_control inputForm_textarea">
        <Textarea
          id={`textarea-${id}`}
          name={`textarea-${id}`}
          label={t('inputs.comment')}
          labelHidden
          value={value.comment}
          onChange={(event) => onChange({
            ...value,
            comment: event.target.value,
          })}
          required={isCommRequired()}
        />
      </div>
    </div>
  );
}

SwitchListComment.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.shape({
    checked: PropTypes.bool,
    troubleType: PropTypes.string,
    comment: PropTypes.string,
    inputType: PropTypes.string,
    isDirty: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SwitchListComment;
