/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  // faFileArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { sortBy } from 'lodash';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from 'react-query';
import { getBuildingsList } from '../../services/structures';
import Input from '../../components/atoms/Input/Input';
// import UploadFile from '../../components/atoms/UploadFile/UploadFile';
import Button from '../../components/atoms/Button/Button';
import Select from '../../components/atoms/Select/Select';
import useAppContext from '../../store/useAppContext';
import { postDocumentBaseItem } from '../../services/docBase';
import Layout from '../../components/template/Layout';
import { getEquipmentsList } from '../../services/equipments';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';

function DepotDoc() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const context = useAppContext();
  const [typeDocument, setTypeDocument] = useState([]);

  const goBack = (message) => {
    toast.success(message);
    navigate(-1);
  };

  const typeDocumentSanitaire = useMemo(() => [
    {
      value: 44,
      label: 'Devis',
    },
    {
      value: 45,
      label: 'Maintenance corrective (dépannage)',
    },
    {
      value: 46,
      label: 'Remplacement de matériel',
    },
    {
      value: 47,
      label: 'Documentation',
    },
    {
      value: 48,
      label: 'Autre',
    },
  ], []);

  const typeDocumentSecurite = useMemo(() => [
    {
      value: 49,
      label: 'Devis',
    },
    {
      value: 50,
      label: 'Maintenance corrective (dépannage)',
    },
    {
      value: 51,
      label: 'Remplacement de matériel',
    },
    {
      value: 52,
      label: 'Documentation',
    },
    {
      value: 53,
      label: 'Autre',
    },
  ], []);

  useEffect(() => {
    if (context[0].choiceBooklet === 1) {
      setTypeDocument(typeDocumentSanitaire);
    } else if (context[0].choiceBooklet === 2) {
      setTypeDocument(typeDocumentSecurite);
    }
  }, [context[0].choiceBooklet]);

  const postDocumentBaseItemMutation = useMutation(postDocumentBaseItem, {
    onSuccess: () => {
      goBack(t('documentary.succesAdd'));
    },
  });

  const initialValues = {
    buildingId: null,
    equipmentId: null,
    date: null,
    document: null,
    numero: null,
    typeId: null,
    society: '',
    structureIds: [],
  };

  const validationSchema = Yup.object().shape({
    buildingId: Yup.number().required(t('docVehicule.required')),
    equipmentId: Yup.number().required(t('docVehicule.required')),
    date: Yup.date().required(t('docVehicule.required')),
    document: Yup.mixed().nullable().required('global.required_field')
      .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 10000000)
      .test('type', 'global.accepted_formats', (value) => value && (value.type === 'application/pdf'
        || value.type === 'application/x-pdf'
        || value.type === 'image/jpeg'
        || value.type === 'image/jpg'
        || value.type === 'image/png'
        || value.type === 'image/tiff'
        || value.type === 'image/bmp'
        || value.type === 'image/heic'
        || value.type === 'image/vnd.dwg')),
    numero: Yup.string().required(t('docVehicule.required')),
    typeId: Yup.number().required(t('docVehicule.required')),
    society: Yup.string().required(t('docVehicule.required')),
    structureIds: Yup.array(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('buildingId', values.buildingId);
      formData.append('equipmentId', values.equipmentId);
      formData.append('date', values.date);
      formData.append('documentFile', values.document);
      formData.append('name', values.numero);
      formData.append('typeId', values.typeId);
      formData.append('society', values.society);
      if (context[0].choiceBooklet === 1) {
        formData.append('categoryId', 16);
      } else if (context[0].choiceBooklet === 2) {
        formData.append('categoryId', 17);
      }
      formData.append('structureIds[0]', context[0].choiceEstablishment.id);
      postDocumentBaseItemMutation.mutate(formData);
    },
  });

  const getBuildingsListQuery = useQuery(['buildings'], () => getBuildingsList({
    bookletId: context[0].choiceBooklet,
    establishmentId: context[0]?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      if (data.data?.buildings.length === 1) {
        formik.setFieldValue('buildingId', data.data?.buildings[0].id);
      }
    },
  });
  const buildingsList = getBuildingsListQuery?.data?.data?.buildings || [];

  const getEquipmentsListQuery = useQuery(['equipments'], () => getEquipmentsList({
    bookletId: context[0]?.choiceBooklet,
    establishmentId: context[0]?.choiceEstablishment?.id,
    buildingId: formik.values.buildingId,
  }), {
    enabled: formik.values.buildingId !== null,
    onSuccess: (data) => {
      if (context.choiceBooklet === 3) {
        formik.setFieldValue('equipment', data.data.equipments[0].id);
      }
    },
  });

  const equipmentsList = getEquipmentsListQuery?.data?.data?.equipments || [];

  const mapForSelect = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['type']).map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }))
    : []);

  const equipmentsForBuildingSelected = () => buildingsList && mapForSelect(buildingsList)?.find(
    (building) => building.value === formik.values.buildingId,
  )?.equipmentIds;
  return (
    <Layout
      title="Depot de document"
      queryError={
          postDocumentBaseItemMutation?.isError
        || getBuildingsListQuery?.isError
        || getEquipmentsListQuery?.isError
        }
    >
      <div>
        <header>
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('docVehicule.back')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('docVehicule.add')}</h1>
          </div>
        </header>
      </div>
      <form onSubmit={formik.handleSubmit} className="form shadow-sm">
        {buildingsList.length < 1 ? (
          <div className="form_group">
            <Select
              id="building"
              label={t('addInterventions.input_building')}
              options={mapForSelect(buildingsList)}
              value={
                mapForSelect(buildingsList)
                  .find((building) => building.value === formik.values.buildingId)
              }
              onChange={(option) => formik.setFieldValue('buildingId', option.value)}
              valueInput={formik.values.buildingId}
              required
              loading={getBuildingsListQuery.isLoading}
            />
            {formik.errors.buildingId && formik.touched.buildingId ? (
              <div className="error">
                {t(formik.errors.buildingId)}
              </div>
            ) : null }
          </div>
        ) : (
          <div className="form_group">
            <Input
              type="text"
              name="buildingId"
              id="buildingId"
              label={t('dairy.batiment')}
              placeholder={buildingsList[0]?.name}
              value={buildingsList[0]?.name}
              disabled
            />
            {formik.errors.buildingId && formik.touched.buildingId ? (
              <div className="error">
                {t(formik.errors.buildingId)}
              </div>
            ) : null }
          </div>
        )}
        <div className="form_group">
          <Select
            id="equipments"
            label={t('addInterventions.input_equipments')}
            options={
              mapForSelect(equipmentsList)
                .filter((equipment) => equipmentsForBuildingSelected()?.includes(equipment.id))
            }
            value={
              mapForSelect(equipmentsList)
                .find((equipment) => equipment.value === formik.values.equipmentId)
            }
            onChange={(option) => {
              formik.setFieldValue('equipmentId', option.value);
            }}
            disabled={formik.values.buildingId === null}
            valueInput={formik.values.equipmentId}
            required
            loading={getEquipmentsListQuery.isLoading}
          />
          {formik.errors.equipmentId && formik.touched.equipmentId ? (
            <div className="error">
              {t(formik.errors.equipmentId)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            label={t('depotDoc.date')}
            id="date"
            name="date"
            type="date"
            required
            placeholder={t('depotDoc.date')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.date}
          />
          {formik.touched.date && formik.errors.date ? (
            <div className="error">{formik.errors.date}</div>
          ) : null}
        </div>
        <div className="form_group">
          <div className="label">{t('add_training.document_file')}</div>
          <UploadFile
            id="document"
            name="document"
            label={t('contract.report')}
            fileName={formik.values.document ? formik.values.document.name : formik.values.document}
            onChange={(event) => formik.setFieldValue('document', event.currentTarget.files[0])}
            onBlur={formik.handleBlur}
          />
          {formik.errors.document && formik.touched.document ? (
            <div className="error">
              {t(formik?.errors?.document)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            type="text"
            name="numero"
            id="numero"
            label={t('depotDoc.numero')}
            placeholder={t('depotDoc.numero')}
            value={formik.values.numero}
            onChange={formik.handleChange}
            required
          />
          {formik.touched.numero && formik.errors.numero ? (
            <div className="error">{formik.errors.numero}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Select
            label={t('depotDoc.typeDoc')}
            id="typeDoc"
            name="typeDoc"
            type="select"
            onChange={(value) => formik.setFieldValue('typeId', value.value)}
            onBlur={formik.handleBlur}
            options={typeDocument}
            value={
              mapForSelect(typeDocument).find(
                (item) => item.value === formik.values.typeId,
              )
            }
            valueInput={formik.values.typeId}
            required
          />
          {formik.touched.typeId && formik.errors.typeId ? (
            <div className="error">{formik.errors.typeId}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            type="text"
            name="society"
            id="society"
            label={t('depotDoc.society')}
            placeholder={t('depotDoc.society')}
            value={formik.values.society}
            onChange={formik.handleChange}
            required
          />
          {formik.touched.society && formik.errors.society ? (
            <div className="error">{formik.errors.society}</div>
          ) : null}
        </div>
        <div className="form_footer">
          <div className="form_infos">
            <small>{t('addInterventions.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t('depotDoc.safe')}
          />
        </div>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('docVehicule.back')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default DepotDoc;
