import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../../components/template/Layout';
import useAppContext from '../../store/useAppContext';

import styles from './Error.module.css';
import Button from '../../components/atoms/Button/Button';

function Error404() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [context] = useAppContext();

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context?.choiceBooklet === 1 && !context?.user?.role?.includes('ADMIN')) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    } else if (context.choiceBooklet === 2 && !context?.user?.role?.includes('ADMIN')) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    } else if (context.choiceBooklet === 3 && !context?.user?.role?.includes('ADMIN')) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    } else if (context?.user?.role?.includes('ADMIN')) {
      navigate(-1);
    } else {
      navigate('/choix-carnet');
    }
  };

  const handleRefresh = () => {
    navigate(-2);
  };

  return (
    <Layout
      title="Error"
      description="Error"
      keywords="Error"
    >
      <button type="button" className={styles.links} onClick={() => navigateDashboards()}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <span>{t('global.dashboard')}</span>
      </button>
      <div className={styles.content}>
        <p className="mb-20">
          {t('error404.message')}
        </p>
        <p className="mb-20">{t('error404.excuse')}</p>
      </div>
      <div className={styles.button}>
        <Button
          label={t('error.refresh')}
          className="add"
          onClick={handleRefresh}
        >
          {' '}
        </Button>
      </div>
    </Layout>
  );
}

export default Error404;
