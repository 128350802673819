/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';

// hooks
import useAppContext from '../../store/useAppContext';

// Components
import Layout from '../../components/template/Layout';
import Card from '../../components/atoms/Card/Card';
import Picto from '../../components/atoms/Picto/Picto';
import AdminDashboardGrid from './AdminDashboardGrid/AdminDashboardGrid';
import AdminGroupDashboardGrid from './AdminGroupDashboardGrid/AdminGroupDashboardGrid';
// import UserDashboardGrid from './UserDashboardGrid/UserDashboardGrid';
// utils & misc
import { USERS_ROLES } from '../../utils/constant';
import cn from '../../utils/cn';

// style
import styles from './Dashboard.module.css';

function Dashboard() {
  const [context] = useAppContext();
  const { t } = useTranslation();

  const isUserSuperAdmin = context.user.role === USERS_ROLES.SUPER_ADMIN || context.user.role === USERS_ROLES.ADMIN;
  const isAdminGroup = context.user.role === USERS_ROLES.ADMIN_GROUP;
  const isUser = context.user.role === USERS_ROLES.USER;

  return (
    <Layout title="Dashboard">
      <Card
        borderTopColored={isUser}
        className={
          cn([styles.topTitleWrapper, isUserSuperAdmin && styles.alignedCenter])
        }
      >
        <div className={cn([styles.topTitle, styles.alignedCenter])}>
          <Picto className={styles.topPicto} name="homeblue" />
          <h1 className="title no-margin">{t('navigation.dashboard')}</h1>
          { isUserSuperAdmin && <Picto className={styles.topPicto} name="homeblue" /> }
        </div>
        {/* {isUser && isSecurityBooklet && (
          <div className={styles.rightUserSecurity}>
            <div className={styles.lastCommissionWrapper}>
              <p className={styles.lastCommission}>
                Dernière commission :
                {formatDateToUser(getIndicatorByEtablissementQuery?.data?.data?.lastsecuritycommitteedate)}
              </p>
              <p className={styles.lastCommission}>
                Prochaine commission :
                {formatDateToUser(getIndicatorByEtablissementQuery?.data?.data?.nextCommitteeDate)}
              </p>
            </div>
            {getIndicatorByEtablissementQuery?.data?.data?.lastsecuritycommitteenotice ? (
              <div className={styles.evaluation}>Favorable</div>
            ) : (
              <div className={styles.deevaluation}>Défavorable</div>
            )}
          </div>
        )} */}
      </Card>
      { isUserSuperAdmin && <AdminDashboardGrid /> }
      { isAdminGroup && <AdminGroupDashboardGrid /> }
    </Layout>
  );
}

export default Dashboard;
