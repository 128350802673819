/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  useParams, useNavigate,
} from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { omit, sortBy } from 'lodash';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// Components
import Layout from '../../components/template/Layout';
import SelectForm from '../../components/atoms/Select/Select';
import Button from '../../components/atoms/Button/Button';
import Input from '../../components/atoms/Input/Input';

// services
import {
  editBuildingInformations,
  getBuilding, getBuildingsTemplatesList, postBuildingInformations,
} from '../../services/structures';
import {
  getEquipmentsList,
} from '../../services/equipments';

import { choicesBooklet } from '../ChoiceBooklet/ChoiceBooklet';
import useAppContext from '../../store/useAppContext';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';

const initialValues = {
  name: '',
  bookletId: null,
  equipmentIds: [],
  vehiclePackage: null,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('global.required_field'),
  bookletId: Yup.number().nullable(),
  equipmentIds: Yup.array(),
  vehiclePackage: Yup.number().nullable(),
});

function AddBuilding() {
  const { t } = useTranslation();
  const urlParams = useParams();
  const establishmentId = Number(urlParams.establishmentParentId);
  const isEditing = urlParams.action === 'edit';
  const isViewing = urlParams.action === 'view';
  const isCreating = !isEditing && !isViewing;

  const [pageLoaded, setPageLoaded] = useState(false);

  const [context] = useAppContext();

  const navigate = useNavigate();

  const goBackUrl = (message) => {
    navigate(-1);
    toast.success(message);
  };

  const postBuildingMutation = useMutation(postBuildingInformations, {
    onSuccess: () => goBackUrl(t('add_building.added')),
  });
  const editBuildingMutation = useMutation(editBuildingInformations, {
    onSuccess: () => goBackUrl(t('add_building.saved')),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      if (isEditing) {
        editBuildingMutation.mutate({ ...formValues, parentId: establishmentId });
      } else {
        postBuildingMutation.mutate({ ...formValues, parentId: establishmentId });
      }
    },
  });

  const getBuildingsTemplatesQuery = useQuery(
    ['buildingsTemplate'],
    () => getBuildingsTemplatesList({
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
    }),
  );

  const getEquipmentsListQuery = useQuery(
    ['equipments'],
    () => getEquipmentsList({
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
    }),
  );
  // voir avec kamel pour que la liste des équipements soit filtrée par le choix du carnet

  const getBuildingQuery = useQuery('building', () => getBuilding(urlParams.id), {
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    enabled: !!urlParams?.id,
    onSuccess: (data) => {
      formik.setValues(
        {
          ...omit({
            ...data.data,
          }, 'building'),
        },
      );
    },
  });

  useEffect(() => {
    if (getEquipmentsListQuery.isSuccess
      && getBuildingQuery.isSuccess
      && getBuildingsTemplatesQuery.isSuccess
      && !pageLoaded) {
      setPageLoaded(true);
    }
  }, [getEquipmentsListQuery.isSuccess,
    getBuildingQuery.isSuccess,
    getBuildingsTemplatesQuery.isSuccess, pageLoaded]);

  if (!getEquipmentsListQuery.isSuccess
    && !getBuildingQuery.isSuccess
    && !getBuildingsTemplatesQuery.isSuccess) {
    return (
      <Layout
        title={isCreating ? t('add_building.title_create') : t('add_building.title_edit')}
      >
        <div className="loader" />
      </Layout>
    );
  }

  const handleCheckboxClick = (fieldName, fieldValue, isChecked) => formik.setFieldValue(
    fieldName,
    isChecked
      ? formik.values[fieldName].concat(fieldValue)
      : formik.values[fieldName].filter((id) => id !== fieldValue),
  );

  const mapForSelect = (arrayToMap) => (arrayToMap.length
    ? sortBy(arrayToMap, ['name']).map((item) => ({
      ...item,
      label: item.label,
      value: item.id,
    }))
    : []);

  useEffect(() => {
    if (formik.values.bookletId === 3) {
      formik.setFieldValue('equipmentIds', [150]);
    }
  }, [formik.values.bookletId]);

  return (
    <Layout
      title={isCreating ? t('add_building.title_create') : t('add_building.title_edit')}
      queryError={
        getBuildingQuery?.error
        || editBuildingMutation?.error
        || postBuildingMutation?.error
      }
    >
      <div>
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('add_building.back_to_establishment')}</span>
          </button>
        </div>
        <header>
          <h1 className="title">
            {isCreating ? t('add_building.title_create') : t('add_building.title_edit')}
          </h1>
        </header>
        <form onSubmit={formik.handleSubmit} className="form shadow-sm">
          <div className="form_group" key="name">
            <Input
              id="name"
              type="text"
              name="name"
              label={t('add_building.input_name')}
              onChange={(event) => formik.setFieldValue('name', event.target.value.toUpperCase())}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              disabled={isViewing}
              required
            />
            {formik.errors.name && formik.touched.name ? (
              <div className="error">
                {t(formik.errors.name)}
              </div>
            ) : null }
          </div>
          <div className="form_group">
            <SelectForm
              id="bookletId"
              label={t('add_building.input_booklet')}
              options={mapForSelect(choicesBooklet)}
              value={choicesBooklet.find((option) => option.id === formik.values.bookletId)}
              onChange={(option) => formik.setFieldValue('bookletId', option.value)}
              disabled={isViewing}
            />
            {formik.errors.bookletId && formik.touched.bookletId ? (
              <div className="error">
                {t(formik.errors.bookletId)}
              </div>
            ) : null }
          </div>
          {getEquipmentsListQuery?.data?.data?.equipments && (
            <div className="form_group">
              <div className="label">{t('add_building.input_equipments')}</div>
              {getEquipmentsListQuery.data.data.equipments
                .filter((equipment) => equipment.bookletIds.includes(formik?.values?.bookletId))
                .map((equipment) => (
                  <div>
                    <Checkbox
                      id={`input-${equipment.id}`}
                      name="equipmentIds"
                      label={equipment.name}
                      onChange={(event) => handleCheckboxClick('equipmentIds', equipment.id, event.target.checked)}
                      checked={formik.values.equipmentIds?.includes(equipment.id)}
                      value={equipment.id}
                      disabled={isViewing}
                    />
                  </div>
                ))}
            </div>
          )}
          {getBuildingsTemplatesQuery?.data?.data?.buildingTemplates && formik.values.bookletId !== 3 && (
            <div className="form_group">
              <SelectForm
                id="buildingTemplate"
                label={t('add_building.input_template_buildings')}
                options={
                  getBuildingsTemplatesQuery.data.data.buildingTemplates
                    .filter((template) => template.bookletId === formik.values.bookletId)
                    .map(
                      (option) => ({ ...option, label: option.name, value: option.id }),
                    )
                }
                value={
                  getBuildingsTemplatesQuery?.data?.data?.buildingTemplates
                    .find((template) => template.value === formik.values.buildingTemplate)
                }
                onChange={(option) => {
                  formik.setFieldValue('equipmentIds', option.equipmentIds);
                  formik.setFieldValue('buildingTemplate', option.value);
                }}
                disabled={isViewing}
                loading={getBuildingsTemplatesQuery.isLoading}
              />
            </div>
          )}
          {formik.values.bookletId === 3 && (
            <div className="form_group">
              <Input
                id="vehiclePackage"
                type="number"
                name="vehiclePackage"
                label={t('add_building.VehiclePackage')}
                onChange={(event) => formik.setFieldValue('vehiclePackage', parseInt(event.target.value, 10))}
                onBlur={formik.handleBlur}
                value={formik.values.vehiclePackage}
                disabled={isViewing}
                min={0}
                required
              />
            </div>
          )}
          <section className="form_footer">
            <div className="form_infos">
              <small>{t('add_structure.mandatory_fields')}</small>
            </div>
            {!isViewing && (
            <Button
              type="submit"
              className="form_submit"
              label={isEditing ? t('add_building.save') : t('add_building.create')}
              isLoading={isEditing ? editBuildingMutation.isLoading : postBuildingMutation.isLoading}
            />
            )}
          </section>
        </form>
        <footer className="footer">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('add_building.back_to_establishment')}</span>
          </button>
        </footer>
      </div>
    </Layout>
  );
}

export default AddBuilding;
