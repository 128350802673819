/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature, faChevronLeft, faMinus } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import cn from '../../utils/cn';
import { createPermisFeu, getPermisFeuById, updatePermisFeu } from '../../services/permisfeu';

import '../../print.css';
import styles from './PermisFeu.module.css';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import Textarea from '../../components/atoms/Textarea/Textarea';
import Button from '../../components/atoms/Button/Button';
import Radio from '../../components/atoms/Radio/Radio';
import CanvasSignature from '../../components/atoms/Canvas/Canvas';
import useAppContext from '../../store/useAppContext';

function PermisFeu() {
  const [context] = useAppContext();
  const { t } = useTranslation();
  const urlParams = useParams();
  const navigate = useNavigate();
  const phoneRegExp = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gmi;
  const [Enregistre, setEnregistre] = useState();
  const [OpeArray, setOpeArray] = useState([0]);
  const [OpeCount, setOpeCount] = useState(0);
  const [APArray, setAPArray] = useState([0]);
  const [APCount, setAPCount] = useState(0);
  const [AEArray, setAEArray] = useState([0]);
  const [AECount, setAECount] = useState(0);
  const [signature, setSignature] = useState('');
  const [SignaturePendantTravaux, setSignaturePendantTravaux] = useState('');
  const [SignatureApresTravaux, setSignatureApresTravaux] = useState('');
  const [SignatureRespTravaux, setSignatureRespTravaux] = useState('');
  const [SignatureSurvTravaux, setSignatureSurvTravaux] = useState('');
  const [SignatureRespInter, setSignatureRespInter] = useState('');
  const [SignatureAutre, setSignatureAutre] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openSignaturePendantTravaux, setOpenSignaturePendantTravaux] = useState(false);
  const [openSignatureApresTravaux, setOpenSignatureApresTravaux] = useState(false);
  const [openSignatureRespTravaux, setOpenSignatureRespTravaux] = useState(false);
  const [openSignatureSurvTravaux, setOpenSignatureSurvTravaux] = useState(false);
  const [openSignatureRespInter, setOpenSignatureRespInter] = useState(false);
  const [openSignatureAutre, setOpenSignatureAutre] = useState(false);
  const sigCanvas = React.useRef({});
  const sigCanvasPendantTravaux = React.useRef({});
  const sigCanvasApresTravaux = React.useRef({});
  const sigCanvasRespTravaux = React.useRef({});
  const sigCanvasSurvTravaux = React.useRef({});
  const sigCanvasRespInter = React.useRef({});
  const sigCanvasAutre = React.useRef({});
  const timeElapsed = Date.now();
  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const postPermisFeu = useMutation(createPermisFeu, {
    onSuccess: () => {
      toast.success(t('permis_feu.success'));
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const updatePermisFeuMutation = useMutation(updatePermisFeu, {
    onSuccess: (res) => {
      console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const initialValues = {
    enregistrer: '',
    namedocument: '',
    name: '',
    fonction: '',
    mesdt: '',
    yousdt: '',
    plp: false,
    cds: false,
    autres: false,
    autres_consignes: '',
    raisonsocial: '',
    adresse: '',
    ville: '',
    codepostalville: '',
    tel: '',
    email: '',
    representepar: '',
    seigcoche: false,
    localisationtravaux: '',
    datedebut: '',
    datefin: '',
    heuredebut: '',
    heurefin: '',
    soudage: false,
    tronconnage: false,
    decoupage: false,
    meulage: false,
    goudronnage: false,
    autres_procedes: false,
    posteasouder: false,
    chalumeau: false,
    laser: false,
    tronconneuse: false,
    autres_equipement: false,
    desc_travaux: '',
    risque_structure: '',
    risque_environnement: '',
    risque_activitesite: '',
    ope_n: [],
    autres_procedes_n: [],
    autres_equipement_n: [],
    deplacement_eloignement: 2,
    deplacement_eloignement_qui: '',
    deplacement_eloignement_fait: '',
    delimitation_separation: 2,
    delimitation_separation_qui: '',
    delimitation_separation_fait: '',
    protect_element: 2,
    protect_element_qui: '',
    protect_element_fait: '',
    consignation: 2,
    consignation_qui: '',
    consignation_fait: '',
    vidange: 2,
    vidange_qui: '',
    vidange_fait: '',
    degazage: 2,
    degazage_qui: '',
    degazage_fait: '',
    remplissage: 2,
    remplissage_qui: '',
    remplissage_fait: '',
    isolation_tuyauterie: 2,
    isolation_tuyauterie_qui: '',
    isolation_tuyauterie_fait: '',
    demontage: 2,
    demontage_qui: '',
    demontage_fait: '',
    colmatage: 2,
    colmatage_qui: '',
    colmatage_fait: '',
    fermeture: 2,
    fermeture_qui: '',
    fermeture_fait: '',
    isolation_auto_incendie: 2,
    isolation_auto_incendie_qui: '',
    isolation_auto_incendie_fait: '',
    isolation_systeme_extinction: 2,
    isolation_systeme_extinction_qui: '',
    isolation_systeme_extinction_fait: '',
    ecrans_panneaux: 2,
    ecrans_panneaux_qui: '',
    ecrans_panneaux_fait: '',
    baches_ignifugees: 2,
    baches_ignifugees_qui: '',
    baches_ignifugees_fait: '',
    eau_arrosage: 2,
    eau_arrosage_qui: '',
    eau_arrosage_fait: '',
    sable: 2,
    sable_qui: '',
    sable_fait: '',
    absorbant: 2,
    absorbant_qui: '',
    absorbant_fait: '',
    moyens_lutte_incendie: 2,
    nombre_extincteur: 0,
    nombre_extincteur_type: '',
    autre_info: 2,
    autre_info_text: '',
    autre_info_fait: '',
    autre: 2,
    autre_text: '',
    autre_qui: '',
    autre_fait: '',
    autre_ms: 2,
    autre_ms_text: '',
    autre_ms_qui: '',
    autre_ms_fait: '',
    pendant_travaux: '',
    apres_travaux: '',
    apartirde: '',
    jusqua: '',
    preciser_consignes: '',
    personne_contacter_nom: '',
    personne_contacter_tel: '',
    resp_travaux_nom: '',
    resp_travaux_qualite: '',
    surveillant_travaux_nom: '',
    surveillant_travaux_qualite: '',
    resp_intervention_nom: '',
    resp_intervention_qualite: '',
    autre_signature: '',
    autre_signature_nom: '',
    autre_signature_qualite: '',
    permisfeu_delivrer: '',
    signature: null,
    SignaturePendantTravaux: null,
    SignatureApresTravaux: null,
    SignatureRespTravaux: null,
    SignatureSurvTravaux: null,
    SignatureRespInter: null,
    SignatureAutre: null,
  };

  const validationSchema = Yup.object().shape({
    enregistrer: Yup.mixed(),
    namedocument: Yup.string().required('global.required_field'),
    name: Yup.string().when('enregistrer', {
      is: true,
      then: Yup.string().required('global.required_field'),
    }),
    fonction: Yup.string(),
    mesdt: Yup.string(),
    yousdt: Yup.string(),
    plp: Yup.boolean().nullable(),
    cds: Yup.boolean().nullable(),
    autres: Yup.boolean().nullable(),
    autres_consignes: Yup.string(),
    raisonsocial: Yup.string(),
    adresse: Yup.string(),
    ville: Yup.string(),
    codepostalville: Yup.string()
      .matches(/^[0-9]+$/, (t('prestataire.validePostalCode')))
      .min(5, (t('prestataire.validePostalCode')))
      .max(5, (t('prestataire.validePostalCode'))),
    tel: Yup.string().matches(phoneRegExp, 'global.invalid_phone_number')
      .min(10, 'global.too_short')
      .max(10, 'global.too_long')
      .when('enregistrer', {
        is: true,
        then: Yup.string().required('global.required_field'),
      }),
    email: Yup.string().email('global.invalid_email_address'),
    representepar: Yup.string(),
    seigcoche: Yup.boolean(),
    localisationtravaux: Yup.string().when('enregistrer', {
      is: true,
      then: Yup.string().required('global.required_field'),
    }),
    datedebut: Yup.date().when('enregistrer', {
      is: true,
      then: Yup.date().required('global.required_field'),
    }),
    datefin: Yup.date(),
    heuredebut: Yup.string().when('enregistrer', {
      is: true,
      then: Yup.string().required('global.required_field'),
    }),
    heurefin: Yup.string(),
    soudage: Yup.boolean(),
    tronconnage: Yup.boolean(),
    decoupage: Yup.boolean(),
    meulage: Yup.boolean(),
    goudronnage: Yup.boolean(),
    autres_procedes: Yup.boolean(),
    autres_procedes_n: Yup.mixed(),
    posteasouder: Yup.boolean(),
    chalumeau: Yup.boolean(),
    laser: Yup.boolean(),
    tronconneuse: Yup.boolean(),
    autres_equipement: Yup.boolean(),
    autres_equipement_n: Yup.mixed(),
    desc_travaux: Yup.string(),
    risque_structure: Yup.string(),
    risque_environnement: Yup.string(),
    risque_activitesite: Yup.string(),
    ope_n: Yup.array().when('enregistrer', {
      is: true,
      then: Yup.array().min(1, 'Minimum 1').required('global.required_field'),
    }),
    deplacement_eloignement: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    deplacement_eloignement_qui: Yup.string(),
    deplacement_eloignement_fait: Yup.string(),
    delimitation_separation: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    delimitation_separation_qui: Yup.string(),
    delimitation_separation_fait: Yup.string(),
    protect_element: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    protect_element_qui: Yup.string(),
    protect_element_fait: Yup.string(),
    consignation: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    consignation_qui: Yup.string(),
    consignation_fait: Yup.string(),
    vidange: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    vidange_qui: Yup.string(),
    vidange_fait: Yup.string(),
    degazage: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    degazage_qui: Yup.string(),
    degazage_fait: Yup.string(),
    remplissage: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    remplissage_qui: Yup.string(),
    remplissage_fait: Yup.string(),
    isolation_tuyauterie: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    isolation_tuyauterie_qui: Yup.string(),
    isolation_tuyauterie_fait: Yup.string(),
    demontage: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    demontage_qui: Yup.string(),
    demontage_fait: Yup.string(),
    colmatage: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    colmatage_qui: Yup.string(),
    colmatage_fait: Yup.string(),
    fermeture: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    fermeture_qui: Yup.string(),
    fermeture_fait: Yup.string(),
    isolation_auto_incendie: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    isolation_auto_incendie_qui: Yup.string(),
    isolation_auto_incendie_fait: Yup.string(),
    isolation_systeme_extinction: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    isolation_systeme_extinction_qui: Yup.string(),
    isolation_systeme_extinction_fait: Yup.string(),
    autre_ms: Yup.number(),
    autre_ms_text: Yup.string(),
    autre_ms_qui: Yup.string(),
    autre_ms_fait: Yup.string(),
    ecrans_panneaux: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    ecrans_panneaux_qui: Yup.string(),
    ecrans_panneaux_fait: Yup.string(),
    baches_ignifugees: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    baches_ignifugees_qui: Yup.string(),
    baches_ignifugees_fait: Yup.string(),
    eau_arrosage: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    eau_arrosage_qui: Yup.string(),
    eau_arrosage_fait: Yup.string(),
    sable: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    sable_qui: Yup.string(),
    sable_fait: Yup.string(),
    absorbant: Yup.number().when('enregistrer', {
      is: true,
      then: Yup.number().required('global.required_field'),
    }),
    absorbant_qui: Yup.string(),
    absorbant_fait: Yup.string(),
    autre: Yup.number(),
    autre_text: Yup.string(),
    autre_qui: Yup.string(),
    autre_fait: Yup.string(),
    nombre_extincteur: Yup.number(),
    nombre_extincteur_type: Yup.string(),
    moyens_lutte_incendie: Yup.number(),
    autre_info: Yup.number(),
    autre_info_text: Yup.string(),
    autre_info_fait: Yup.string(),
    pendant_travaux: Yup.string(),
    apres_travaux: Yup.string(),
    apartirde: Yup.string(),
    jusqua: Yup.string(),
    preciser_consignes: Yup.string(),
    personne_contacter_nom: Yup.string(),
    personne_contacter_tel: Yup.string()
      .matches(phoneRegExp, 'global.invalid_phone_number')
      .min(10, 'global.too_short')
      .max(10, 'global.too_long'),
    resp_travaux_nom: Yup.string(),
    resp_travaux_qualite: Yup.string(),
    surveillant_travaux_nom: Yup.string(),
    surveillant_travaux_qualite: Yup.string(),
    resp_intervention_nom: Yup.string(),
    resp_intervention_qualite: Yup.string(),
    autre_signature: Yup.string(),
    autre_signature_nom: Yup.string(),
    autre_signature_qualite: Yup.string(),
    permisfeu_delivrer: Yup.string(),
    signature: Yup.mixed().when('enregistrer', {
      is: true,
      then: Yup.mixed().required('global.required_field'),
    }),
    SignaturePendantTravaux: Yup.mixed(),
    SignatureApresTravaux: Yup.mixed(),
    SignatureRespTravaux: Yup.mixed(),
    SignatureSurvTravaux: Yup.mixed(),
    SignatureRespInter: Yup.mixed(),
    SignatureAutre: Yup.mixed(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      if (values.enregistrer === true) {
        formData.append('draft', false);
      } else if (values.enregistrer === false) {
        formData.append('draft', true);
      }
      formData.append('structureId', context.choiceEstablishment.id);
      formData.append('documentName', values.namedocument);
      if (values.name !== '' && values.name !== undefined) {
        formData.append('representativeName', values.name);
      }
      if (values.fonction !== '' && values.fonction !== undefined) {
        formData.append('representativeFonction', values.fonction);
      }
      if (values.mesdt !== '' && values.mesdt !== undefined) {
        formData.append('worksSupervisorName', values.mesdt);
      }
      if (values.yousdt !== '' && values.yousdt !== undefined) {
        formData.append('worksSupervisorFonction', values.yousdt);
      }
      if (values.plp === true) {
        formData.append('preventionPlan', values.plp);
      }
      if (values.cds === true) {
        formData.append('safetyInstructions', values.cds);
      }
      if (values.autres === true) {
        formData.append('otherInstructions', values.autres);
        formData.append('autres_consignes', values.autres_consignes);
      }
      if (values.raisonsocial !== '' && values.raisonsocial !== undefined) {
        formData.append('companyName', values.raisonsocial.toUpperCase());
      }
      if (values.adresse !== '' && values.adresse !== undefined) {
        formData.append('companyAddress', values.adresse);
      }
      if (values.ville !== '' && values.ville !== undefined) {
        formData.append('companyCity', values.ville.toUpperCase());
      }
      if (values.codepostalville !== '' && values.codepostalville !== undefined) {
        formData.append('companyZipCode', values.codepostalville);
      }
      if (values.tel !== '' && values.tel !== undefined) {
        formData.append('companyPhone', values.tel);
      }
      if (values.email !== '' && values.email !== undefined) {
        formData.append('companyEmail', values.email);
      }
      if (values.representepar !== '' && values.representepar !== undefined) {
        formData.append('companyRepresentative', values.representepar);
      }
      if (values.seigcoche === true) {
        formData.append('isInternal', values.seigcoche);
      }
      if (values.localisationtravaux !== '' && values.localisationtravaux !== undefined) {
        formData.append('locationofWork', values.localisationtravaux);
      }
      if (values.datedebut !== '' && values.datedebut !== undefined) {
        formData.append('startDate', values.datedebut);
      }
      if (values.datefin !== '' && values.datefin !== undefined) {
        formData.append('endDate', values.datefin);
      }
      if (values.heuredebut !== '' && values.heuredebut !== undefined) {
        formData.append('hourStart', values.heuredebut);
      }
      if (values.heurefin !== '' && values.heurefin !== undefined) {
        formData.append('hourEnd', values.heurefin);
      }
      if (values.soudage === true) {
        formData.append('soudage', values.soudage);
      }
      if (values.tronconnage === true) {
        formData.append('tronconnage', values.tronconnage);
      }
      if (values.decoupage === true) {
        formData.append('decoupage', values.decoupage);
      }
      if (values.meulage === true) {
        formData.append('meulage', values.meulage);
      }
      if (values.goudronnage === true) {
        formData.append('goudronnage', values.goudronnage);
      }
      if (values.autres_procedes === true) {
        const FilteredAutresProcedes = values.autres_procedes_n.filter((x) => x !== undefined);
        formData.append('autres_procedes_n', FilteredAutresProcedes);
      }
      if (values.posteasouder === true) {
        formData.append('posteasouder', values.posteasouder);
      }
      if (values.chalumeau === true) {
        formData.append('chalumeau', values.chalumeau);
      }
      if (values.laser === true) {
        formData.append('laser', values.laser);
      }
      if (values.tronconneuse === true) {
        formData.append('tronconneuse', values.tronconneuse);
      }
      if (values.autres_equipement === true) {
        const FilteredAutresEquipement = values.autres_equipement_n.filter((x) => x !== undefined);
        formData.append('autres_equipement_n', FilteredAutresEquipement);
      }
      if (values.desc_travaux !== '' && values.desc_travaux !== undefined) {
        formData.append('workDescription', values.desc_travaux);
      }
      if (values.risque_structure !== '' && values.risque_structure !== undefined) {
        formData.append('risksLinkedToStructure', values.risque_structure);
      }
      if (values.risque_environnement !== '' && values.risque_environnement !== undefined) {
        formData.append('risksLinkedToEnvironment', values.risque_environnement);
      }
      if (values.risque_activitesite !== '' && values.risque_activitesite !== undefined) {
        formData.append('risksLinkedToSiteActivity', values.risque_activitesite);
      }
      if (values.ope_n.length > 0) {
        const FilteredOpe = values.ope_n.filter((x) => x !== undefined);
        FilteredOpe.forEach((element, index) => {
          formData.append(`ope_n[${index}]`, element);
        });
      }
      if (values.deplacement_eloignement !== '' && values.deplacement_eloignement !== undefined) {
        formData.append('travelDistance', values.deplacement_eloignement);
        if (values.deplacement_eloignement === 1) {
          formData.append('travelDistanceWho', values.deplacement_eloignement_qui);
          formData.append('travelDistanceDo', values.deplacement_eloignement_fait);
        }
      }
      if (values.delimitation_separation !== '' && values.delimitation_separation !== undefined) {
        formData.append('boundarySeparation', values.delimitation_separation);
        if (values.delimitation_separation === 1) {
          formData.append('boundarySeparationWho', values.delimitation_separation_qui);
          formData.append('boundarySeparationDo', values.delimitation_separation_fait);
        }
      }
      if (values.protect_element !== '' && values.protect_element !== undefined) {
        formData.append('protectElement', values.protect_element);
        if (values.protect_element === 1) {
          formData.append('protectElementWho', values.protect_element_qui);
          formData.append('protectElementDo', values.protect_element_fait);
        }
      }
      if (values.consignation !== '' && values.consignation !== undefined) {
        formData.append('consignment', values.consignation);
        if (values.consignation === 1) {
          formData.append('consignmentWho', values.consignation_qui);
          formData.append('consignmentDo', values.consignation_fait);
        }
      }
      if (values.vidange !== '' && values.vidange !== undefined) {
        formData.append('emptying', values.vidange);
        if (values.vidange === 1) {
          formData.append('emptyingWho', values.vidange_qui);
          formData.append('emptyingDo', values.vidange_fait);
        }
      }
      if (values.degazage !== '' && values.degazage !== undefined) {
        formData.append('degassing', values.degazage);
        if (values.degazage === 1) {
          formData.append('degassingWho', values.degazage_qui);
          formData.append('degassingDo', values.degazage_fait);
        }
      }
      if (values.remplissage !== '' && values.remplissage !== undefined) {
        formData.append('filling', values.remplissage);
        if (values.remplissage === 1) {
          formData.append('fillingWho', values.remplissage_qui);
          formData.append('fillingDo', values.remplissage_fait);
        }
      }
      if (values.isolation_tuyauterie !== '' && values.isolation_tuyauterie !== undefined) {
        formData.append('pipeInsulation', values.isolation_tuyauterie);
        if (values.isolation_tuyauterie === 1) {
          formData.append('pipeInsulationWho', values.isolation_tuyauterie_qui);
          formData.append('pipeInsulationDo', values.isolation_tuyauterie_fait);
        }
      }
      if (values.demontage !== '' && values.demontage !== undefined) {
        formData.append('disassembly', values.demontage);
        if (values.demontage === 1) {
          formData.append('disassemblyWho', values.demontage_qui);
          formData.append('disassemblyDo', values.demontage_fait);
        }
      }
      if (values.colmatage !== '' && values.colmatage !== undefined) {
        formData.append('clogging', values.colmatage);
        if (values.colmatage === 1) {
          formData.append('cloggingWho', values.colmatage_qui);
          formData.append('cloggingDo', values.colmatage_fait);
        }
      }
      if (values.fermeture !== '' && values.fermeture !== undefined) {
        formData.append('closing', values.fermeture);
        if (values.fermeture === 1) {
          formData.append('closingWho', values.fermeture_qui);
          formData.append('closingDo', values.fermeture_fait);
        }
      }
      if (values.isolation_auto_incendie !== '' && values.isolation_auto_incendie !== undefined) {
        formData.append('autoFireInsulation', values.isolation_auto_incendie);
        if (values.isolation_auto_incendie === 1) {
          formData.append('autoFireInsulationWho', values.isolation_auto_incendie_qui);
          formData.append('autoFireInsulationDo', values.isolation_auto_incendie_fait);
        }
      }
      if (values.isolation_systeme_extinction !== '' && values.isolation_systeme_extinction !== undefined) {
        formData.append('extinguishingSystemInsulation', values.isolation_systeme_extinction);
        if (values.isolation_systeme_extinction === 1) {
          formData.append('extinguishingSystemInsulationWho', values.isolation_systeme_extinction_qui);
          formData.append('extinguishingSystemInsulationDo', values.isolation_systeme_extinction_fait);
        }
      }
      if (values.ecrans_panneaux !== '' && values.ecrans_panneaux !== undefined) {
        formData.append('panelScreens', values.ecrans_panneaux);
        if (values.ecrans_panneaux === 1) {
          formData.append('panelScreensWho', values.ecrans_panneaux_qui);
          formData.append('panelScreensDo', values.ecrans_panneaux_fait);
        }
      }
      if (values.baches_ignifugees !== '' && values.baches_ignifugees !== undefined) {
        formData.append('fireproofTarpaulins', values.baches_ignifugees);
        if (values.baches_ignifugees === 1) {
          formData.append('fireproofTarpaulinsWho', values.baches_ignifugees_qui);
          formData.append('fireproofTarpaulinsDo', values.baches_ignifugees_fait);
        }
      }
      if (values.eau_arrosage !== '' && values.eau_arrosage !== undefined) {
        formData.append('wateringWater', values.eau_arrosage);
        if (values.eau_arrosage === 1) {
          formData.append('wateringWaterWho', values.eau_arrosage_qui);
          formData.append('wateringWaterDo', values.eau_arrosage_fait);
        }
      }
      if (values.sable !== '' && values.sable !== undefined) {
        formData.append('sand', values.sable);
        if (values.sable === 1) {
          formData.append('sandWho', values.sable_qui);
          formData.append('sandDo', values.sable_fait);
        }
      }
      if (values.absorbant !== '' && values.absorbant !== undefined) {
        formData.append('absorbent', values.absorbant);
        if (values.absorbant === 1) {
          formData.append('absorbentWho', values.absorbant_qui);
          formData.append('absorbentDo', values.absorbant_fait);
        }
      }
      if (values.moyens_lutte_incendie !== '' && values.moyens_lutte_incendie !== undefined) {
        formData.append('fireFightingMeans', values.moyens_lutte_incendie);
      }
      if (values.moyens_lutte_incendie === 1) {
        formData.append('fireExtinguisherNumber', values.nombre_extincteur);
        if (values.nombre_extincteur > 0) {
          formData.append('numberFireExtinguisherType', values.nombre_extincteur_type);
        }
        formData.append('otherInfo', values.autre_info);
        if (values.autre_info === 1) {
          formData.append('otherInfoText', values.autre_info_text);
          formData.append('otherInfoDo', values.autre_info_fait);
        }
      }
      if (values.autre === '' && values.autre !== undefined) {
        formData.append('other', values.autre);
      }
      if (values.autre === 1) {
        formData.append('otherText', values.autre_text);
        formData.append('otherWho', values.autre_qui);
        formData.append('otherDo', values.autre_fait);
      }
      if (values.autre_ms === '' && values.autre_ms !== undefined) {
        formData.append('otherMs', values.autre_ms);
      }
      if (values.autre_ms === 1) {
        formData.append('otherMsText', values.autre_ms_text);
        formData.append('otherMsWho', values.autre_ms_qui);
        formData.append('otherMsDo', values.autre_ms_fait);
      }
      if (values.pendant_travaux !== '' && values.pendant_travaux !== undefined) {
        formData.append('securityMonitoringDuringWork', values.pendant_travaux);
      }
      if (values.apres_travaux !== '' && values.apres_travaux !== undefined) {
        formData.append('securityMonitoringAfterWork', values.apres_travaux);
      }
      if (values.apartirde !== '' && values.apartirde !== undefined) {
        formData.append('fromhour', values.apartirde);
      }
      if (values.jusqua !== '' && values.jusqua !== undefined) {
        formData.append('tohour', values.jusqua);
      }
      if (values.preciser_consignes !== '' && values.preciser_consignes !== undefined) {
        formData.append('alertInstructions', values.preciser_consignes);
      }
      if (values.personne_contacter_nom !== '' && values.personne_contacter_nom !== undefined) {
        formData.append('emergencycontact', values.personne_contacter_nom);
      }
      if (values.personne_contacter_tel !== '' && values.personne_contacter_tel !== undefined) {
        formData.append('emergencyPhone', values.personne_contacter_tel);
      }
      if (values.resp_travaux_nom !== '' && values.resp_travaux_nom !== undefined) {
        formData.append('worksManagerName', values.resp_travaux_nom);
      }
      if (values.resp_travaux_qualite !== '' && values.resp_travaux_qualite !== undefined) {
        formData.append('worksManagerFunction', values.resp_travaux_qualite);
      }
      if (values.surveillant_travaux_nom !== '' && values.surveillant_travaux_nom !== undefined) {
        formData.append('supervisorGeneralName', values.surveillant_travaux_nom);
      }
      if (values.surveillant_travaux_qualite !== '' && values.surveillant_travaux_qualite !== undefined) {
        formData.append('supervisorGeneralFunction', values.surveillant_travaux_qualite);
      }
      if (values.resp_intervention_nom !== '' && values.resp_intervention_nom !== undefined) {
        formData.append('intervetionManagerName', values.resp_intervention_nom);
      }
      if (values.resp_intervention_qualite !== '' && values.resp_intervention_qualite !== undefined) {
        formData.append('intervetionManagerFunction', values.resp_intervention_qualite);
      }
      if (values.autre_signature !== '' && values.autre_signature !== undefined) {
        formData.append('otherSignature', values.autre_signature);
        formData.append('otherSignatureName', values.autre_signature_nom);
        formData.append('otherSignatureQuality', values.autre_signature_qualite);
      }
      if (values.permisfeu_delivrer !== '' && values.permisfeu_delivrer !== undefined) {
        formData.append('deliveryDate', values.permisfeu_delivrer);
      }
      if (values.signature !== null) {
        formData.append('signaturePermit', values.signature);
      }
      if (values.SignaturePendantTravaux !== null) {
        formData.append('signatureFileDuringWork', values.SignaturePendantTravaux);
      }
      if (values.SignatureApresTravaux !== null) {
        formData.append('signatureFileAfterWork', values.SignatureApresTravaux);
      }
      if (values.SignatureRespTravaux !== null) {
        formData.append('signatureFileWorksManager', values.SignatureRespTravaux);
      }
      if (values.SignatureSurvTravaux !== null) {
        formData.append('signatureFileSupervisorGeneral', values.SignatureSurvTravaux);
      }
      if (values.SignatureRespInter !== null) {
        formData.append('signatureFileIntervetionManager', values.SignatureRespInter);
      }
      if (values.SignatureAutre !== null && values.SignatureAutre !== undefined) {
        formData.append('SignatureAutre', values.SignatureAutre);
      }
      if (urlParams.id) {
        const config = {
          id: urlParams.id,
          data: formData,
        };
        updatePermisFeuMutation.mutate(config);
      } else {
        postPermisFeu.mutate(formData);
      }
    },
  });
  const getPermitFire = useQuery(['getPermisFeuById', urlParams.id], () => getPermisFeuById({
    id: urlParams?.id,
  }), {
    enabled: !!urlParams.id,
    onSuccess: (data) => {
      formik.setFieldValue('namedocument', data.data.documentName);
      formik.setFieldValue('name', data.data.name);
      formik.setFieldValue('fonction', data.data.representativeFonction);
      formik.setFieldValue('mesdt', data.data.worksSupervisorName);
      formik.setFieldValue('yousdt', data.data.worksSupervisorFonction);
      formik.setFieldValue('plp', data.data.preventionPlan);
      formik.setFieldValue('cds', data.data.safetyInstructions);
      formik.setFieldValue('autres', data.data.otherInstructions);
      formik.setFieldValue('autres_consignes', data.data.otherInstructionsText);
      formik.setFieldValue('raisonsocial', data.data.companyName);
      formik.setFieldValue('adresse', data.data.companyAddress);
      formik.setFieldValue('ville', data.data.companyCity);
      formik.setFieldValue('codepostalville', data.data.companyZipCode);
      formik.setFieldValue('tel', data.data.companyPhone);
      formik.setFieldValue('email', data.data.companyEmail);
      formik.setFieldValue('representepar', data.data.companyRepresentative);
      formik.setFieldValue('seigcoche', data.data.isInternal);
      formik.setFieldValue('localisationtravaux', data.data.locationofWork);
      formik.setFieldValue('datedebut', data.data.startDate);
      formik.setFieldValue('datefin', data.data.endDate);
      formik.setFieldValue('heuredebut', data.data.hourStrat);
      formik.setFieldValue('heurefin', data.data.hourEnd);
      formik.setFieldValue('soudage', data.data.soudage);
      formik.setFieldValue('tronconnage', data.data.tronconnage);
      formik.setFieldValue('decoupage', data.data.decoupage);
      formik.setFieldValue('meulage', data.data.meulage);
      formik.setFieldValue('goudronnage', data.data.goudronnage);
      formik.setFieldValue('autres_procedes', data.data.otherProcedures);
      formik.setFieldValue('autres_procedes_n', data.data.otherProceduresText);
      formik.setFieldValue('posteasouder', data.data.weldingMachine);
      formik.setFieldValue('chalumeau', data.data.torch);
      formik.setFieldValue('laser', data.data.laser);
      formik.setFieldValue('tronconneuse', data.data.chainsaw);
      formik.setFieldValue('autres_equipement', data.data.otherEquipment);
      formik.setFieldValue('autres_equipement_n', data.data.otherEquipmentText);
      formik.setFieldValue('desc_travaux', data.data.workDescription);
      formik.setFieldValue('risque_structure', data.data.risksLinkedToStructure);
      formik.setFieldValue('risque_environnement', data.data.risksLinkedToEnvironment);
      formik.setFieldValue('risque_activitesite', data.data.risksLinkedToSiteActivity);
      formik.setFieldValue('ope_n', data.data.operators);
      formik.setFieldValue('deplacement_eloignement', data.data.deplacement_eloignement);
      formik.setFieldValue('deplacement_eloignement_qui', data.data.deplacement_eloignement_qui);
      formik.setFieldValue('deplacement_eloignement_fait', data.data.deplacement_eloignement_fait);
      formik.setFieldValue('delimitation_separation', data.data.delimitation_separation);
      formik.setFieldValue('delimitation_separation_qui', data.data.delimitation_separation_qui);
      formik.setFieldValue('delimitation_separation_fait', data.data.delimitation_separation_fait);
      formik.setFieldValue('protect_element', data.data.protect_element);
      formik.setFieldValue('protect_element_qui', data.data.protect_element_qui);
      formik.setFieldValue('protect_element_fait', data.data.protect_element_fait);
      formik.setFieldValue('consignation', data.data.consignation);
      formik.setFieldValue('consignation_qui', data.data.consignation_qui);
      formik.setFieldValue('consignation_fait', data.data.consignation_fait);
      formik.setFieldValue('vidange', data.data.vidange);
      formik.setFieldValue('vidange_qui', data.data.vidange_qui);
      formik.setFieldValue('vidange_fait', data.data.vidange_fait);
      formik.setFieldValue('degazage', data.data.degazage);
      formik.setFieldValue('degazage_qui', data.data.degazage_qui);
      formik.setFieldValue('degazage_fait', data.data.degazage_fait);
      formik.setFieldValue('remplissage', data.data.remplissage);
      formik.setFieldValue('remplissage_qui', data.data.remplissage_qui);
      formik.setFieldValue('remplissage_fait', data.data.remplissage_fait);
      formik.setFieldValue('isolation_tuyauterie', data.data.isolation_tuyauterie);
      formik.setFieldValue('isolation_tuyauterie_qui', data.data.isolation_tuyauterie_qui);
      formik.setFieldValue('isolation_tuyauterie_fait', data.data.isolation_tuyauterie_fait);
      formik.setFieldValue('demontage', data.data.demontage);
      formik.setFieldValue('demontage_qui', data.data.demontage_qui);
      formik.setFieldValue('demontage_fait', data.data.demontage_fait);
      formik.setFieldValue('colmatage', data.data.colmatage);
      formik.setFieldValue('colmatage_qui', data.data.colmatage_qui);
      formik.setFieldValue('colmatage_fait', data.data.colmatage_fait);
      formik.setFieldValue('fermeture', data.data.fermeture);
      formik.setFieldValue('fermeture_qui', data.data.fermeture_qui);
      formik.setFieldValue('fermeture_fait', data.data.fermeture_fait);
      formik.setFieldValue('isolation_auto_incendie', data.data.isolation_auto_incendie);
      formik.setFieldValue('isolation_auto_incendie_qui', data.data.isolation_auto_incendie_qui);
      formik.setFieldValue('isolation_auto_incendie_fait', data.data.isolation_auto_incendie_fait);
      formik.setFieldValue('isolation_systeme_extinction', data.data.isolation_systeme_extinction);
      formik.setFieldValue('isolation_systeme_extinction_qui', data.data.isolation_systeme_extinction_qui);
      formik.setFieldValue('isolation_systeme_extinction_fait', data.data.isolation_systeme_extinction_fait);
      formik.setFieldValue('ecrans_panneaux', data.data.ecrans_panneaux);
      formik.setFieldValue('ecrans_panneaux_qui', data.data.ecrans_panneaux_qui);
      formik.setFieldValue('ecrans_panneaux_fait', data.data.ecrans_panneaux_fait);
      formik.setFieldValue('baches_ignifugees', data.data.baches_ignifugees);
      formik.setFieldValue('baches_ignifugees_qui', data.data.baches_ignifugees_qui);
      formik.setFieldValue('baches_ignifugees_fait', data.data.baches_ignifugees_fait);
      formik.setFieldValue('eau_arrosage', data.data.eau_arrosage);
      formik.setFieldValue('eau_arrosage_qui', data.data.eau_arrosage_qui);
      formik.setFieldValue('eau_arrosage_fait', data.data.eau_arrosage_fait);
      formik.setFieldValue('sable', data.data.sable);
      formik.setFieldValue('sable_qui', data.data.sable_qui);
      formik.setFieldValue('sable_fait', data.data.sable_fait);
      formik.setFieldValue('absorbant', data.data.absorbant);
      formik.setFieldValue('absorbant_qui', data.data.absorbant_qui);
      formik.setFieldValue('absorbant_fait', data.data.absorbant_fait);
      formik.setFieldValue('moyens_lutte_incendie', data.data.moyens_lutte_incendie);
      formik.setFieldValue('nombre_extincteur', data.data.nombre_extincteur);
      formik.setFieldValue('nombre_extincteur_type', data.data.nombre_extincteur_type);
      formik.setFieldValue('autre_info', data.data.autre_info);
      formik.setFieldValue('autre_info_text', data.data.autre_info_text);
      formik.setFieldValue('autre_info_fait', data.data.autre_info_fait);
      formik.setFieldValue('autre', data.data.autre);
      formik.setFieldValue('autre_text', data.data.autre_text);
      formik.setFieldValue('autre_qui', data.data.autre_qui);
      formik.setFieldValue('autre_fait', data.data.autre_fait);
      formik.setFieldValue('autre_ms', data.data.autre_ms);
      formik.setFieldValue('autre_ms_text', data.data.autre_ms_text);
      formik.setFieldValue('autre_ms_qui', data.data.autre_ms_qui);
      formik.setFieldValue('autre_ms_fait', data.data.autre_ms_fait);
      formik.setFieldValue('securityMonitoringDuringWork', data.data.securityMonitoringDuringWork);
      formik.setFieldValue('securityMonitoringAfterWork', data.data.securityMonitoringAfterWork);
      formik.setFieldValue('fromhour', data.data.fromhour);
      formik.setFieldValue('tohour', data.data.tohour);
      formik.setFieldValue('preciser_consignes', data.data.alertInstructions);
      formik.setFieldValue('personne_contacter_nom', data.data.emergencycontact);
      formik.setFieldValue('personne_contacter_tel', data.data.emergencyPhone);
      formik.setFieldValue('resp_travaux_nom', data.data.worksManagerName);
      formik.setFieldValue('resp_travaux_qualite', data.data.worksManagerFunction);
      formik.setFieldValue('surveillant_travaux_nom', data.data.supervisorGeneralName);
      formik.setFieldValue('surveillant_travaux_qualite', data.data.supervisorGeneralFunction);
      formik.setFieldValue('resp_intervention_nom', data.data.intervetionManagerName);
      formik.setFieldValue('resp_intervention_qualite', data.data.intervetionManagerFunction);
      formik.setFieldValue('autre_signature', data.data.autre_signature);
      formik.setFieldValue('autre_signature_nom', data.data.autre_signature_nom);
      formik.setFieldValue('autre_signature_qualite', data.data.autre_signature_qualite);
      formik.setFieldValue('permisfeu_delivrer', data.data.deliveryDate);
      formik.setFieldValue('signature', data.data.signaturePermit);
      formik.setFieldValue('SignaturePendantTravaux', data.data.signatureFileDuringWork);
      formik.setFieldValue('SignatureApresTravaux', data.data.signatureFileAfterWork);
      formik.setFieldValue('SignatureRespTravaux', data.data.signatureFileWorksManager);
      formik.setFieldValue('SignatureSurvTravaux', data.data.signatureFileSupervisorGeneral);
      formik.setFieldValue('SignatureRespInter', data.data.signatureFileIntervetionManager);
      formik.setFieldValue('SignatureAutre', data.data.SignatureAutre);
    },
  });

  const deleteObservation = () => {
    if (OpeCount > 0) {
      const updatedOpe = [...formik.values.ope_n];
      updatedOpe.pop();
      setOpeCount(OpeCount - 1);
      setOpeArray(OpeArray.slice(0, -1));
      formik.setFieldValue('ope_n', updatedOpe);
    }
  };

  const AddOperateur = () => {
    if (OpeCount < 2) {
      const counts = OpeCount + 1;
      setOpeCount(counts);
      const arr = Array.from(OpeArray);
      arr.push(counts);
      setOpeArray(arr);
    }
  };
  const AddAutresProc = () => {
    if (APCount < 2) {
      const counts = APCount + 1;
      setAPCount(counts);
      const arr = Array.from(APArray);
      arr.push(counts);
      setAPArray(arr);
    }
  };
  const AddAutresEqui = () => {
    if (AECount < 2) {
      const counts = AECount + 1;
      setAECount(counts);
      const arr = Array.from(AEArray);
      arr.push(counts);
      setAEArray(arr);
    }
  };
  const AutresProcRein = (e) => {
    if (e.target.value === 'false') {
      formik.setFieldValue('autres_procedes', true);
    } else if (e.target.value === 'true') {
      setAPCount(0);
      setAPArray([0]);
      formik.setFieldValue('autres_procedes', false);
    }
  };
  const AutresEquiRein = (e) => {
    if (e.target.value === 'false') {
      formik.setFieldValue('autres_equipement', true);
    } else if (e.target.value === 'true') {
      setAECount(0);
      setAEArray([0]);
      formik.setFieldValue('autres_equipement', false);
    }
  };
  const formatDate = (date) => {
    const d = new Date(date);
    let month = `${(d.getMonth() + 1)}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }
    return [year, month, day].join('-');
  };
  const canvasToPngDataURL = (canvas) => {
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  };
  const trim = () => {
    const trimmedDataURL = canvasToPngDataURL(sigCanvas.current.getTrimmedCanvas());
    setSignature(trimmedDataURL);
    formik.setFieldValue('signature', trimmedDataURL);
    setOpenModal(false);
  };
  const clear = () => {
    sigCanvas.current.clear();
    setSignature('');
  };
  const trimPendantTravaux = () => {
    const trimmedDataURL = canvasToPngDataURL(sigCanvasPendantTravaux.current.getTrimmedCanvas());
    setSignaturePendantTravaux(trimmedDataURL);
    formik.setFieldValue('SignaturePendantTravaux', trimmedDataURL);
    setOpenSignaturePendantTravaux(false);
  };
  const clearPendantTravaux = () => {
    sigCanvasPendantTravaux.current.clear();
    setSignaturePendantTravaux('');
  };
  const trimApresTravaux = () => {
    const trimmedDataURL = canvasToPngDataURL(sigCanvasApresTravaux.current.getTrimmedCanvas());
    setSignatureApresTravaux(trimmedDataURL);
    formik.setFieldValue('SignatureApresTravaux', trimmedDataURL);
    setOpenSignatureApresTravaux(false);
  };
  const clearApresTravaux = () => {
    sigCanvasApresTravaux.current.clear();
    setOpenSignatureApresTravaux('');
  };
  const trimRespTravaux = () => {
    const trimmedDataURL = canvasToPngDataURL(sigCanvasRespTravaux.current.getTrimmedCanvas());
    setSignatureRespTravaux(trimmedDataURL);
    formik.setFieldValue('SignatureApresTravaux', trimmedDataURL);
    setOpenSignatureRespTravaux(false);
  };
  const clearRespTravaux = () => {
    sigCanvasRespTravaux.current.clear();
    setOpenSignatureRespTravaux('');
  };
  const trimSurvTravaux = () => {
    const trimmedDataURL = canvasToPngDataURL(sigCanvasSurvTravaux.current.getTrimmedCanvas());
    setSignatureSurvTravaux(trimmedDataURL);
    formik.setFieldValue('SignatureSurvTravaux', trimmedDataURL);
    setOpenSignatureSurvTravaux(false);
  };
  const clearSurvTravaux = () => {
    sigCanvasSurvTravaux.current.clear();
    setOpenSignatureSurvTravaux('');
  };
  const trimRespInter = () => {
    const trimmedDataURL = canvasToPngDataURL(sigCanvasRespInter.current.getTrimmedCanvas());
    setSignatureRespInter(trimmedDataURL);
    formik.setFieldValue('SignatureRespInter', trimmedDataURL);
    setOpenSignatureRespInter(false);
  };
  const clearRespInter = () => {
    sigCanvasRespInter.current.clear();
    setOpenSignatureRespInter('');
  };
  const trimAutre = () => {
    const trimmedDataURL = canvasToPngDataURL(sigCanvasAutre.current.getTrimmedCanvas());
    setSignatureAutre(trimmedDataURL);
    formik.setFieldValue('SignatureRespInter', trimmedDataURL);
    setOpenSignatureAutre(false);
  };
  const clearAutre = () => {
    sigCanvasAutre.current.clear();
    setOpenSignatureAutre('');
  };
  const Enregistrer = () => {
    formik.values.enregistrer = false;
    setEnregistre(false);
  };
  const Sauvegarder = () => {
    formik.values.enregistrer = true;
    setEnregistre(true);
  };
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 768px)').matches,
  );

  useEffect(() => {
    window
      .matchMedia('(min-width: 768px)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  return (
    <Layout
      title="Permis Feu"
      layout="permis feu"
      queryError={
        postPermisFeu?.error
        || getPermitFire?.error
        || updatePermisFeuMutation?.error
      }
    >
      <header className="header printHidden">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('documentary.return')}</span>
          </button>
        </div>
      </header>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className="form shadow-sm mb-20">
            <div className="form_group">
              <Input
                id="namedocument"
                name="namedocument"
                type="text"
                label={t('permis_feu.nom_document')}
                value={formik.values.namedocument}
                onChange={formik.handleChange}
              />
              {formik?.errors?.namedocument && formik?.touched?.namedocument ? (
                <div className="error">
                  {t(formik?.errors?.namedocument)}
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.between}>
            <h2 className="title">
              {t('permis_feu.ordretravail')}
            </h2>
            <h2 className="title">
              {t('permis_feu.1/8')}
            </h2>
          </div>
          <div className="form shadow-sm mb-20">
            <div className="form_group">
              <Input
                id="name"
                name="name"
                type="text"
                label={t('permis_feu.user')}
                placeholder={t('permis_feu.fullname')}
                value={formik.values.name}
                onChange={(e) => formik.setFieldValue('name', e.target.value.toUpperCase())}
              />
              {formik?.errors?.name && formik?.touched?.name ? (
                <div className="error">
                  {t(formik?.errors?.name)}
                </div>
              ) : null}
            </div>
            <div className="form_group">
              <Input
                id="fonction"
                name="fonction"
                type="text"
                label={t('permis_feu.fonction')}
                placeholder={t('permis_feu.votrefonction')}
                value={formik.values.fonction}
                onChange={formik.handleChange}
              />
              {formik?.errors?.fonction && formik?.touched?.fonction ? (
                <div className="error">
                  {t(formik?.errors?.fonction)}
                </div>
              ) : null}
            </div>
            <div className="form_group">
              <Input
                id="mesdt"
                name="mesdt"
                type="text"
                label={t('permis_feu.sdt')}
                placeholder={t('permis_feu.fullname')}
                value={formik.values.mesdt}
                onChange={(e) => formik.setFieldValue('mesdt', e.target.value.toUpperCase())}
              />
              {formik?.errors?.mesdt && formik?.touched?.mesdt ? (
                <div className="error">
                  {t(formik?.errors?.mesdt)}
                </div>
              ) : null}
            </div>
            <div className="form_group">
              <Input
                id="yousdt"
                name="yousdt"
                type="text"
                label={t('permis_feu.fonction')}
                placeholder={t('permis_feu.surveillantfonction')}
                value={formik.values.yousdt}
                onChange={formik.handleChange}
              />
              {formik?.errors?.yousdt && formik?.touched?.yousdt ? (
                <div className="error">
                  {t(formik?.errors?.yousdt)}
                </div>
              ) : null}
            </div>
            <h2 className="title-info">
              {t('permis_feu.consignes_particulieres')}
            </h2>
            <div>
              {' '}
            </div>
            <div className="form_group">
              <Checkbox
                id="plp"
                name="plp"
                label={t('permis_feu.plandeprevention')}
                value={formik.values.plp === true ? true : false}
                checked={formik.values.plp === true}
                onChange={(e) => formik.setFieldValue('plp', e.target.checked ? true : false)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.plp && formik.errors.plp ? (
                <div className="form_error">{formik.errors.plp}</div>
              ) : null}
              <div>
                {' '}
              </div>
              <Checkbox
                label={t('permis_feu.consignedesecu')}
                id="cds"
                name="cds"
                type="checkbox"
                value={formik.values.cds === true ? true : false}
                checked={formik.values.cds === true}
                onChange={(e) => formik.setFieldValue('cds', e.target.checked ? true : false)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.cds && formik.errors.cds ? (
                <div className="form_error">{formik.errors.cds}</div>
              ) : null}
              <div>
                {' '}
              </div>
              <Checkbox
                label={t('permis_feu.autres')}
                id="autres"
                name="autres"
                type="checkbox"
                value={formik.values.autres === true ? true : false}
                checked={formik.values.autres === true}
                onChange={(e) => formik.setFieldValue('autres', e.target.checked ? true : false)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.autres && formik.errors.autres ? (
                <div className="form_error">{formik.errors.autres}</div>
              ) : null}
            </div>
            {formik.values.autres === true ? (
              <>
                <div>
                  {' '}
                </div>
                <div className="from_group">
                  <Input
                    id="autres_consignes"
                    name="autres_consignes"
                    type="text"
                    labelHidden
                    placeholder={t('permis_feu.apreciser')}
                    value={formik.values.autres_consignes}
                    onChange={formik.handleChange}
                    required={formik.values.autres === true && Enregistre === true}
                  />
                  {formik.touched.autres_consignes && formik.errors.autres_consignes ? (
                    <div className="form_error">{formik.errors.autres_consignes}</div>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
          <div className="breakpage">
            {' '}
          </div>
          <div className={styles.between}>
            <h2 className="title">
              {t('permis_feu.intervention')}
            </h2>
            <h2 className="title">
              {t('permis_feu.2/8')}
            </h2>
          </div>
          <div className=" form shadow-sm mb-20">
            <div className="form_group">
              <Input
                id="localisationtravaux"
                name="localisationtravaux"
                type="text"
                label={t('permis_feu.localisationtravaux')}
                placeholder={t('permis_feu.indiquerlocalisationtravaux')}
                value={formik.values.localisationtravaux}
                onChange={formik.handleChange}
                required={Enregistre === true}
              />
              {formik?.errors?.localisationtravaux && formik?.touched?.localisationtravaux ? (
                <div className="error">
                  {t(formik?.errors?.localisationtravaux)}
                </div>
              ) : null}
            </div>
            <div className="from_group">
              {' '}
            </div>
            <div className="form_group">
              <h5>
                {t('permis_feu.debuttravaux')}
              </h5>
              <Input
                id="datedebut"
                name="datedebut"
                type="date"
                label={t('permis_feu.date')}
                placeholder={t('permis_feu.datedebut')}
                value={formik.values.datedebut}
                onChange={formik.handleChange}
                required={Enregistre === true}
              />
              {formik?.errors?.datedebut && formik?.touched?.datedebut ? (
                <div className="error">
                  {t(formik?.errors?.datedebut)}
                </div>
              ) : null}
              <Input
                id="heuredebut"
                name="heuredebut"
                type="time"
                label={t('permis_feu.heure')}
                placeholder={t('permis_feu.heuredebut')}
                value={formik.values.heuredebut}
                onChange={formik.handleChange}
                required={Enregistre === true}
              />
              {formik?.errors?.heuredebut && formik?.touched?.heuredebut ? (
                <div className="error">
                  {t(formik?.errors?.heuredebut)}
                </div>
              ) : null}
            </div>
            <div className="form_group">
              <h5>
                {t('permis_feu.fintravaux')}
              </h5>
              <Input
                id="datefin"
                name="datefin"
                type="date"
                label={t('permis_feu.date')}
                placeholder={t('permis_feu.datefin')}
                value={formik.values.datefin}
                onChange={formik.handleChange}
              />
              {formik?.errors?.datefin && formik?.touched?.datefin ? (
                <div className="error">
                  {t(formik?.errors?.datefin)}
                </div>
              ) : null}
              <Input
                id="heurefin"
                name="heurefin"
                type="time"
                label={t('permis_feu.heure')}
                placeholder={t('permis_feu.heurefin')}
                value={formik.values.heurefin}
                onChange={formik.handleChange}
              />
              {formik?.errors?.heurefin && formik?.touched?.heurefin ? (
                <div className="error">
                  {t(formik?.errors?.heurefin)}
                </div>
              ) : null}
            </div>
          </div>
          <div className="breakpage">
            {' '}
          </div>
          <div className={styles.between}>
            <h2 className="title">
              {t('permis_feu.ee_in')}
            </h2>
            <h2 className="title">
              {t('permis_feu.3/8')}
            </h2>
          </div>
          <div className="form shadow-sm mb-20">
            <h3 className="title-info">
              {t('permis_feu.raee')}
            </h3>
            <div className="from_group">
              {' '}
            </div>
            <div className="form_group">
              <Checkbox
                label={t('permis_feu.seigcoche')}
                id="seigcoche"
                name="seigcoche"
                type="checkbox"
                onChange={(e) => formik.setFieldValue('seigcoche', e.target.checked ? true : false)}
                onBlur={formik.handleBlur}
                checked={formik.values.seigcoche === true}
                value={formik.values.seigcoche === true ? true : false}
              />
              {formik.touched.seigcoche && formik.errors.seigcoche ? (
                <div className="form_error">{formik.errors.seigcoche}</div>
              ) : null}
            </div>
            <div className="from_group">
              {' '}
            </div>
            <div className="form_group">
              <Input
                disabled={formik.values.seigcoche === true}
                id="raisonsocial"
                name="raisonsocial"
                type="text"
                label={t('permis_feu.raisonsocial')}
                placeholder={t('permis_feu.raisonsocial')}
                value={formik.values.raisonsocial}
                onChange={formik.handleChange}
              />
              {formik?.errors?.raisonsocial && formik?.touched?.raisonsocial ? (
                <div className="error">
                  {t(formik?.errors?.raisonsocial)}
                </div>
              ) : null}
            </div>
            <div className="form_group">
              <Input
                disabled={formik.values.seigcoche === true}
                id="adresse"
                name="adresse"
                type="text"
                label={t('permis_feu.adresse')}
                placeholder={t('permis_feu.adresse')}
                value={formik.values.adresse}
                onChange={formik.handleChange}
              />
              {formik?.errors?.adresse && formik?.touched?.adresse ? (
                <div className="error">
                  {t(formik?.errors?.adresse)}
                </div>
              ) : null}
            </div>
            <div className="form_group">
              <Input
                disabled={formik.values.seigcoche === true}
                id="codepostalville"
                name="codepostalville"
                type="text"
                label={t('permis_feu.codepostalville')}
                placeholder={t('permis_feu.codepostalville')}
                value={formik.values.codepostalville}
                onChange={formik.handleChange}
              />
              {formik?.errors?.codepostalville && formik?.touched?.codepostalville ? (
                <div className="error">
                  {t(formik?.errors?.codepostalville)}
                </div>
              ) : null}
            </div>
            <div className="form_group">
              <Input
                disabled={formik.values.seigcoche === true}
                id="ville"
                name="ville"
                type="text"
                label={t('permis_feu.ville')}
                placeholder={t('permis_feu.ville')}
                value={formik.values.ville}
                onChange={formik.handleChange}
              />
              {formik?.errors?.ville && formik?.touched?.ville ? (
                <div className="error">
                  {t(formik?.errors?.ville)}
                </div>
              ) : null}
            </div>
            <div className="form_group">
              <Input
                disabled={formik.values.seigcoche === true}
                id="tel"
                name="tel"
                type="text"
                label={t('permis_feu.tel')}
                value={formik.values.tel}
                onChange={formik.handleChange}
                required={formik.values.seigcoche === false && Enregistre === true}
              />
              {formik?.errors?.tel && formik?.touched?.tel ? (
                <div className="error">
                  {t(formik?.errors?.tel)}
                </div>
              ) : null}
            </div>
            <div className="form_group">
              <Input
                disabled={formik.values.seigcoche === true}
                id="email"
                name="email"
                type="text"
                label={t('permis_feu.email')}
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik?.errors?.email && formik?.touched?.email ? (
                <div className="error">
                  {t(formik?.errors?.email)}
                </div>
              ) : null}
            </div>
            <div className="form_group">
              <Input
                disabled={formik.values.seigcoche === true}
                id="representepar"
                name="representepar"
                type="text"
                label={t('permis_feu.representepar')}
                placeholder={t('permis_feu.nomrepresentant')}
                value={formik.values.representepar}
                onChange={(e) => formik.setFieldValue('representepar', e.target.value.toUpperCase())}
              />
              {formik?.errors?.representepar && formik?.touched?.representepar ? (
                <div className="error">
                  {t(formik?.errors?.representepar)}
                </div>
              ) : null}
            </div>
            <div className={styles.hidden}>
              {' '}
            </div>
          </div>
          <div className="breakpage">
            {' '}
          </div>
          <div className={styles.between}>
            <h2 className="title">
              {t('permis_feu.travaux_risques')}
            </h2>
            <h2 className="title">
              {t('permis_feu.4/8')}
            </h2>
          </div>
          <div className="form shadow-sm mb-20">
            <h3 className="title-info">
              {t('permis_feu.operateur_list')}
            </h3>
            {OpeArray.length > 0 && (
              <>
                <div>
                  {' '}
                </div>
                {OpeArray?.map((res) => (
                  <>
                    <div className="form_group">
                      <Input
                        name={`ope_n[${res}]`}
                        id={`ope_n[${res}]`}
                        type="text"
                        label={t(`permis_feu.operateur_n${res + 1}`)}
                        placeholder={t('permis_feu.fullname')}
                        value={formik.values.ope_n[`${res}`]}
                        onChange={(e) => formik.setFieldValue(`ope_n[${res}]`, e.target.value.toUpperCase())}
                        required={Enregistre === true}
                      />
                      {formik.errors.ope_n && formik.touched.ope_n ? (
                        <div className="error">
                          {t(formik.errors.ope_n)}
                        </div>
                      ) : null}
                    </div>
                    {res !== 0 ? (
                      <section className={cn([styles.form_buttons, 'form_button'])}>
                        <div className={cn([styles.form_delete, 'form_button'])}>
                          <Button
                            type="button"
                            label={<FontAwesomeIcon icon={faMinus} />}
                            onClick={() => deleteObservation()}
                          />
                        </div>
                      </section>
                    ) : (
                      <div className="form_group">
                        {' '}
                      </div>
                    )}
                  </>
                ))}
              </>
            )}
            {OpeCount !== 2 ? (
              <>
                <Button
                  type="button"
                  className={styles.button_operateur}
                  label={t('permis_feu.ajout_operateur')}
                  onClick={() => AddOperateur()}
                />
                <div className="from_group">
                  {' '}
                </div>
              </>
            ) : null }
            <div className="form_group">
              <h3 className="title-info">
                {t('permis_feu.typetravaux')}
              </h3>
              <Checkbox
                id="soudage"
                name="soudage"
                label={t('permis_feu.soudage')}
                value={formik.values.soudage === true ? true : false}
                checked={formik.values.soudage === true}
                onChange={(e) => formik.setFieldValue('soudage', e.target.checked ? true : false)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.soudage && formik.errors.soudage ? (
                <div className="form_error">{formik.errors.soudage}</div>
              ) : null}
              <div>
                {' '}
              </div>
              <Checkbox
                id="tronconnage"
                name="tronconnage"
                label={t('permis_feu.tronconnage')}
                value={formik.values.tronconnage === true ? true : false}
                checked={formik.values.tronconnage}
                onChange={(e) => formik.setFieldValue('tronconnage', e.target.checked ? true : false)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.tronconnage && formik.errors.tronconnage ? (
                <div className="form_error">{formik.errors.tronconnage}</div>
              ) : null}
              <div>
                {' '}
              </div>
              <Checkbox
                id="decoupage"
                name="decoupage"
                label={t('permis_feu.decoupage')}
                value={formik.values.decoupage === true ? true : false}
                checked={formik.values.decoupage === true}
                onChange={(e) => formik.setFieldValue('decoupage', e.target.checked ? true : false)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.decoupage && formik.errors.decoupage ? (
                <div className="form_error">{formik.errors.decoupage}</div>
              ) : null}
              <div>
                {' '}
              </div>
              <Checkbox
                id="meulage"
                name="meulage"
                label={t('permis_feu.meulage')}
                value={formik.values.meulage === true ? true : false}
                checked={formik.values.meulage === true}
                onChange={(e) => formik.setFieldValue('meulage', e.target.checked ? true : false)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.meulage && formik.errors.meulage ? (
                <div className="form_error">{formik.errors.meulage}</div>
              ) : null}
              <div>
                {' '}
              </div>
              <Checkbox
                id="goudronnage"
                name="goudronnage"
                label={t('permis_feu.goudronnage')}
                value={formik.values.goudronnage === true ? true : false}
                checked={formik.values.goudronnage === true}
                onChange={(e) => formik.setFieldValue('goudronnage', e.target.checked ? true : false)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.goudronnage && formik.errors.goudronnage ? (
                <div className="form_error">{formik.errors.goudronnage}</div>
              ) : null}
              <div>
                {' '}
              </div>
              <Checkbox
                id="autres_procedes"
                name="autres_procedes"
                label={t('permis_feu.autres_procedes')}
                value={formik.values.autres_procedes === true ? true : false}
                checked={formik.values.autres_procedes === true}
                onChange={(e) => AutresProcRein(e)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.autres_procedes && formik.errors.autres_procedes ? (
                <div className="form_error">{formik.errors.autres_procedes}</div>
              ) : null}
              {formik.values.autres_procedes === true ? (
                <div>
                  {APArray?.map((res) => (
                    <div className={styles.flex}>
                      <Input
                        id={`autres_procedes_n[${res}]`}
                        name={`autres_procedes_n[${res}]`}
                        type="text"
                        labelHidden
                        placeholder={t('permis_feu.type_travaux')}
                        value={formik.values.autres_procedes_n[`${res}`]}
                        onChange={formik.handleChange}
                      />
                      {APCount < res + 1 && APCount < 2 ? (
                        <Button
                          type="button"
                          className={styles.button_grid}
                          label={t('permis_feu.ajout_operateur')}
                          onClick={() => AddAutresProc()}
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <h3 className="title-info">
                {t('permis_feu.materielutilise')}
              </h3>
              <Checkbox
                id="posteasouder"
                name="posteasouder"
                label={t('permis_feu.posteasouder')}
                value={formik.values.posteasouder === true ? true : false}
                checked={formik.values.posteasouder === true}
                onChange={(e) => formik.setFieldValue('posteasouder', e.target.checked ? true : false)}
                onBlur={formik.handleBlur}
                required={formik.values.chalumeau === false
                  && formik.values.laser === false
                  && formik.values.tronconneuse
                  && formik.values.autres_equipement === false
                  && Enregistre === true}
              />
              {formik.touched.posteasouder && formik.errors.posteasouder ? (
                <div className="form_error">{formik.errors.posteasouder}</div>
              ) : null}
              <div>
                {' '}
              </div>
              <Checkbox
                id="chalumeau"
                name="chalumeau"
                label={t('permis_feu.chalumeau')}
                value={formik.values.chalumeau === true ? true : false}
                checked={formik.values.chalumeau === true}
                onChange={(e) => formik.setFieldValue('chalumeau', e.target.checked ? true : false)}
                onBlur={formik.handleBlur}
                required={formik.values.posteasouder === false
                  && formik.values.laser === false
                  && formik.values.tronconneuse
                  && formik.values.autres_equipement === false
                  && Enregistre === true}
              />
              {formik.touched.chalumeau && formik.errors.chalumeau ? (
                <div className="form_error">{formik.errors.chalumeau}</div>
              ) : null}
              <div>
                {' '}
              </div>
              <Checkbox
                id="laser"
                name="laser"
                label={t('permis_feu.laser')}
                value={formik.values.laser === true ? true : false}
                checked={formik.values.laser === true}
                onChange={(e) => formik.setFieldValue('laser', e.target.checked ? true : false)}
                onBlur={formik.handleBlur}
                required={formik.values.chalumeau === false
                  && formik.values.posteasouder === false
                  && formik.values.tronconneuse
                  && formik.values.autres_equipement === false
                  && Enregistre === true}
              />
              {formik.touched.laser && formik.errors.laser ? (
                <div className="form_error">{formik.errors.laser}</div>
              ) : null}
              <div>
                {' '}
              </div>
              <Checkbox
                id="tronconneuse"
                name="tronconneuse"
                label={t('permis_feu.tronconneuse')}
                value={formik.values.tronconneuse === true ? true : false}
                checked={formik.values.tronconneuse === true}
                onChange={(e) => formik.setFieldValue('tronconneuse', e.target.checked ? true : false)}
                onBlur={formik.handleBlur}
                required={formik.values.chalumeau === false
                  && formik.values.laser === false
                  && formik.values.posteasouder
                  && formik.values.autres_equipement === false
                  && Enregistre === true}
              />
              {formik.touched.tronconneuse && formik.errors.tronconneuse ? (
                <div className="form_error">{formik.errors.tronconneuse}</div>
              ) : null}
              <div>
                {' '}
              </div>
              <Checkbox
                id="ter"
                name="autres_equipement"
                label={t('permis_feu.autres_equipement')}
                value={formik.values.autres_equipement === true ? true : false}
                checked={formik.values.autres_equipement === true}
                onChange={(e) => AutresEquiRein(e)}
                onBlur={formik.handleBlur}
                required={formik.values.chalumeau === false
                  && formik.values.laser === false
                  && formik.values.tronconneuse
                  && formik.values.posteasouder === false
                  && Enregistre === true}
              />
              {formik.touched.autres_equipement && formik.errors.autres_equipement ? (
                <div className="form_error">{formik.errors.autres_equipement}</div>
              ) : null}
              {formik.values.autres_equipement === true ? (
                <div>
                  {AEArray?.map((res) => (
                    <div className={styles.flex}>
                      <Input
                        id={`autres_equipement_n[${res}]`}
                        name={`autres_equipement_n[${res}]`}
                        type="text"
                        labelHidden
                        placeholder={t('permis_feu.materiel_utilise')}
                        value={formik.values.autres_equipement_n[`${res}`]}
                        onChange={formik.handleChange}
                      />
                      {AECount < res + 1 && AECount < 2 ? (
                        <Button
                          type="button"
                          className={styles.button_grid}
                          label={t('permis_feu.ajout_operateur')}
                          onClick={() => AddAutresEqui()}
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null }
            </div>
            <div className="form_group--fullwidth">
              <Textarea
                id="desc_travaux"
                name="desc_travaux"
                label={t('permis_feu.desc_travaux')}
                value={formik.values.desc_travaux}
                onChange={formik.handleChange}
                cols="10"
                rows="5"
              />
              {formik?.errors?.desc_travaux && formik?.touched?.desc_travaux ? (
                <div className="error">
                  {t(formik?.errors?.desc_travaux)}
                </div>
              ) : null}
            </div>
            <h3 className="title-info">
              {t('permis_feu.risqueparticulier')}
            </h3>
            <div className="from_group">
              {' '}
            </div>
            <div className="form_group">
              <Input
                id="risque_structure"
                name="risque_structure"
                type="text"
                label={t('permis_feu.risque_structure')}
                placeholder={t('permis_feu.decr_risques')}
                value={formik.values.risque_structure}
                onChange={formik.handleChange}
              />
              {formik?.errors?.risque_structure && formik?.touched?.risque_structure ? (
                <div className="error">
                  {t(formik?.errors?.risque_structure)}
                </div>
              ) : null}
            </div>
            <div className="form_group">
              <Input
                id="risque_environnement"
                name="risque_environnement"
                type="text"
                label={t('permis_feu.risque_environnement')}
                placeholder={t('permis_feu.decr_risques')}
                value={formik.values.risque_environnement}
                onChange={formik.handleChange}
              />
              {formik?.errors?.risque_environnement && formik?.touched?.risque_environnement ? (
                <div className="error">
                  {t(formik?.errors?.risque_environnement)}
                </div>
              ) : null}
            </div>
            <div className="form_group">
              <Input
                id="risque_activitesite"
                name="risque_activitesite"
                type="text"
                label={t('permis_feu.risque_activitesite')}
                placeholder={t('permis_feu.decr_risques')}
                value={formik.values.risque_activitesite}
                onChange={formik.handleChange}
              />
              {formik?.errors?.risque_activitesite && formik?.touched?.risque_activitesite ? (
                <div className="error">
                  {t(formik?.errors?.risque_activitesite)}
                </div>
              ) : null}
            </div>
          </div>
          <div className="breakpage">
            {' '}
          </div>
          <div className={styles.between}>
            <h2 className="title">
              {t('permis_feu.miseensecurite')}
            </h2>
            <h2 className="title">
              {t('permis_feu.5/8')}
            </h2>
          </div>
          <div className={cn(['shadow-sm mb-20', styles.div_miseensecu])}>
            <div className={cn(['from_groups', styles.big_div])}>
              <div className={styles.one_div_radio}>
                {' '}
              </div>
              <div className={styles.two_div_radio}>
                {t('permis_feu.afaire')}
              </div>
              <div className={styles.three_div_radio}>
                {t('permis_feu.qui')}
              </div>
              <div className={styles.four_div_radio}>
                {t('permis_feu.faitle')}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.deplacement_eloignement')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="deplacement_eloignement_yes"
                    name="deplacement_eloignement"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('deplacement_eloignement', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.deplacement_eloignement === 1}
                    value={1}
                    required={formik.values.deplacement_eloignement !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="deplacement_eloignement_no"
                    name="deplacement_eloignement"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('deplacement_eloignement', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.deplacement_eloignement === 0}
                    value={0}
                    required={formik.values.deplacement_eloignement !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.deplacement_eloignement && formik.touched.deplacement_eloignement ? (
                  <div className="error">
                    {t(formik.errors.deplacement_eloignement)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="deplacement_eloignement_qui"
                  name="deplacement_eloignement_qui"
                  type="text"
                  labelHidden
                  value={formik.values.deplacement_eloignement_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.deplacement_eloignement === 2
                    || formik.values.deplacement_eloignement === 0}
                />
                {formik?.errors?.deplacement_eloignement_qui && formik?.touched?.deplacement_eloignement_qui ? (
                  <div className="error">
                    {t(formik?.errors?.deplacement_eloignement_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="deplacement_eloignement_fait"
                  name="deplacement_eloignement_fait"
                  type="date"
                  labelHidden
                  placeholder={t('permis_feu.entrerdate')}
                  value={formik.values.deplacement_eloignement_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.deplacement_eloignement === 2
                    || formik.values.deplacement_eloignement === 0}
                />
                {formik?.errors?.deplacement_eloignement_fait && formik?.touched?.deplacement_eloignement_fait ? (
                  <div className="error">
                    {t(formik?.errors?.deplacement_eloignement_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.delimitation_separation')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="delimitation_separation_yes"
                    name="delimitation_separation"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('delimitation_separation', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.delimitation_separation === 1}
                    value={1}
                    required={formik.values.delimitation_separation !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="delimitation_separation_no"
                    name="delimitation_separation"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('delimitation_separation', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.delimitation_separation === 0}
                    value={0}
                    required={formik.values.delimitation_separation !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.delimitation_separation && formik.touched.delimitation_separation ? (
                  <div className="error">
                    {t(formik.errors.delimitation_separation)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="delimitation_separation_qui"
                  name="delimitation_separation_qui"
                  type="text"
                  labelHidden
                  value={formik.values.delimitation_separation_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.delimitation_separation === 2
                    || formik.values.delimitation_separation === 0}
                />
                {formik?.errors?.delimitation_separation_qui && formik?.touched?.delimitation_separation_qui ? (
                  <div className="error">
                    {t(formik?.errors?.delimitation_separation_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="delimitation_separation_fait"
                  name="delimitation_separation_fait"
                  type="date"
                  labelHidden
                  value={formik.values.delimitation_separation_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.delimitation_separation === 2
                    || formik.values.delimitation_separation === 0}
                />
                {formik?.errors?.delimitation_separation_fait
                  && formik?.touched?.delimitation_separation_fait ? (
                    <div className="error">
                      {t(formik?.errors?.delimitation_separation_fait)}
                    </div>
                  ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.protect_element')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="protect_element_yes"
                    name="protect_element"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('protect_element', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.protect_element === 1}
                    value={1}
                    required={formik.values.protect_element !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="protect_element_no"
                    name="protect_element"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('protect_element', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.protect_element === 0}
                    value={0}
                    required={formik.values.protect_element !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.protect_element && formik.touched.protect_element ? (
                  <div className="error">
                    {t(formik.errors.protect_element)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="protect_element_qui"
                  name="protect_element_qui"
                  type="text"
                  labelHidden
                  value={formik.values.protect_element_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.protect_element === 2
                    || formik.values.protect_element === 0}
                />
                {formik?.errors?.protect_element_qui && formik?.touched?.protect_element_qui ? (
                  <div className="error">
                    {t(formik?.errors?.protect_element_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="protect_element_fait"
                  name="protect_element_fait"
                  type="date"
                  labelHidden
                  value={formik.values.protect_element_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.protect_element === 2
                    || formik.values.protect_element === 0}
                />
                {formik?.errors?.protect_element_fait && formik?.touched?.protect_element_fait ? (
                  <div className="error">
                    {t(formik?.errors?.protect_element_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.consignation')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="consignation_yes"
                    name="consignation"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('consignation', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.consignation === 1}
                    value={1}
                    required={formik.values.consignation !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="consignation_no"
                    name="consignation"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('consignation', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.consignation === 0}
                    value={0}
                    required={formik.values.consignation !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.consignation && formik.touched.consignation ? (
                  <div className="error">
                    {t(formik.errors.consignation)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="consignation_qui"
                  name="consignation_qui"
                  type="text"
                  labelHidden
                  value={formik.values.consignation_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.consignation === 2
                    || formik.values.consignation === 0}
                />
                {formik?.errors?.consignation_qui && formik?.touched?.consignation_qui ? (
                  <div className="error">
                    {t(formik?.errors?.consignation_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="consignation_fait"
                  name="consignation_fait"
                  type="date"
                  labelHidden
                  value={formik.values.consignation_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.consignation === 2
                    || formik.values.consignation === 0}
                />
                {formik?.errors?.consignation_fait && formik?.touched?.consignation_fait ? (
                  <div className="error">
                    {t(formik?.errors?.consignation_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.vidange')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="vidange_yes"
                    name="vidange"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('vidange', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.vidange === 1}
                    value={1}
                    required={formik.values.vidange !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="vidange_no"
                    name="vidange"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('vidange', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.vidange === 0}
                    value={0}
                    required={formik.values.vidange !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.vidange && formik.touched.vidange ? (
                  <div className="error">
                    {t(formik.errors.vidange)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="vidange_qui"
                  name="vidange_qui"
                  type="text"
                  labelHidden
                  value={formik.values.vidange_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.vidange === 2
                    || formik.values.vidange === 0}
                />
                {formik?.errors?.vidange_qui && formik?.touched?.vidange_qui ? (
                  <div className="error">
                    {t(formik?.errors?.vidange_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="vidange_fait"
                  name="vidange_fait"
                  type="date"
                  labelHidden
                  value={formik.values.vidange_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.vidange === 2
                    || formik.values.vidange === 0}
                />
                {formik?.errors?.vidange_fait && formik?.touched?.vidange_fait ? (
                  <div className="error">
                    {t(formik?.errors?.vidange_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.degazage')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="degazage_yes"
                    name="degazage"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('degazage', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.degazage === 1}
                    value={1}
                    required={formik.values.degazage !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="degazage_no"
                    name="degazage"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('degazage', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.degazage === 0}
                    value={0}
                    required={formik.values.degazage !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.degazage && formik.touched.degazage ? (
                  <div className="error">
                    {t(formik.errors.degazage)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="degazage_qui"
                  name="degazage_qui"
                  type="text"
                  labelHidden
                  value={formik.values.degazage_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.degazage === 2
                    || formik.values.degazage === 0}
                />
                {formik?.errors?.degazage_qui && formik?.touched?.degazage_qui ? (
                  <div className="error">
                    {t(formik?.errors?.degazage_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="degazage_fait"
                  name="degazage_fait"
                  type="date"
                  labelHidden
                  value={formik.values.degazage_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.degazage === 2
                    || formik.values.degazage === 0}
                />
                {formik?.errors?.degazage_fait && formik?.touched?.degazage_fait ? (
                  <div className="error">
                    {t(formik?.errors?.degazage_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.remplissage')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="remplissage_yes"
                    name="remplissage"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('remplissage', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.remplissage === 1}
                    value={1}
                    required={formik.values.remplissage !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="remplissage_no"
                    name="remplissage"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('remplissage', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.remplissage === 0}
                    value={0}
                    required={formik.values.remplissage !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.remplissage && formik.touched.remplissage ? (
                  <div className="error">
                    {t(formik.errors.remplissage)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="remplissage_qui"
                  name="remplissage_qui"
                  type="text"
                  labelHidden
                  value={formik.values.remplissage_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.remplissage === 2
                    || formik.values.remplissage === 0}
                />
                {formik?.errors?.remplissage_qui && formik?.touched?.remplissage_qui ? (
                  <div className="error">
                    {t(formik?.errors?.remplissage_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="remplissage_fait"
                  name="remplissage_fait"
                  type="date"
                  labelHidden
                  value={formik.values.remplissage_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.remplissage === 2
                    || formik.values.remplissage === 0}
                />
                {formik?.errors?.remplissage_fait && formik?.touched?.remplissage_fait ? (
                  <div className="error">
                    {t(formik?.errors?.remplissage_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.isolation_tuyauterie')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="isolation_tuyauterie_yes"
                    name="isolation_tuyauterie"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('isolation_tuyauterie', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.isolation_tuyauterie === 1}
                    value={1}
                    required={formik.values.isolation_tuyauterie !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="isolation_tuyauterie_no"
                    name="isolation_tuyauterie"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('isolation_tuyauterie', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.isolation_tuyauterie === 0}
                    value={0}
                    required={formik.values.isolation_tuyauterie !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.isolation_tuyauterie && formik.touched.isolation_tuyauterie ? (
                  <div className="error">
                    {t(formik.errors.isolation_tuyauterie)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="isolation_tuyauterie_qui"
                  name="isolation_tuyauterie_qui"
                  type="text"
                  labelHidden
                  value={formik.values.isolation_tuyauterie_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.isolation_tuyauterie === 2
                    || formik.values.isolation_tuyauterie === 0}
                />
                {formik?.errors?.isolation_tuyauterie_qui && formik?.touched?.isolation_tuyauterie_qui ? (
                  <div className="error">
                    {t(formik?.errors?.isolation_tuyauterie_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="isolation_tuyauterie_fait"
                  name="isolation_tuyauterie_fait"
                  type="date"
                  labelHidden
                  value={formik.values.isolation_tuyauterie_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.isolation_tuyauterie === 2
                    || formik.values.isolation_tuyauterie === 0}
                />
                {formik?.errors?.isolation_tuyauterie_fait && formik?.touched?.isolation_tuyauterie_fait ? (
                  <div className="error">
                    {t(formik?.errors?.isolation_tuyauterie_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.demontage')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="demontage_yes"
                    name="demontage"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('demontage', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.demontage === 1}
                    value={1}
                    required={formik.values.demontage !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="demontage_no"
                    name="demontage"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('demontage', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.demontage === 0}
                    value={0}
                    required={formik.values.demontage !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.demontage && formik.touched.demontage ? (
                  <div className="error">
                    {t(formik.errors.demontage)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="demontage_qui"
                  name="demontage_qui"
                  type="text"
                  labelHidden
                  value={formik.values.demontage_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.demontage === 2
                    || formik.values.demontage === 0}
                />
                {formik?.errors?.demontage_qui && formik?.touched?.demontage_qui ? (
                  <div className="error">
                    {t(formik?.errors?.demontage_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="demontage_fait"
                  name="demontage_fait"
                  type="date"
                  labelHidden
                  value={formik.values.demontage_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.demontage === 2
                    || formik.values.demontage === 0}
                />
                {formik?.errors?.demontage_fait && formik?.touched?.demontage_fait ? (
                  <div className="error">
                    {t(formik?.errors?.demontage_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.colmatage')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="colmatage_yes"
                    name="colmatage"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('colmatage', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.colmatage === 1}
                    value={1}
                    required={formik.values.colmatage !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="colmatage_no"
                    name="colmatage"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('colmatage', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.colmatage === 0}
                    value={0}
                    required={formik.values.colmatage !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.colmatage && formik.touched.colmatage ? (
                  <div className="error">
                    {t(formik.errors.colmatage)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="colmatage_qui"
                  name="colmatage_qui"
                  type="text"
                  labelHidden
                  value={formik.values.colmatage_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.colmatage === 2
                    || formik.values.colmatage === 0}
                />
                {formik?.errors?.colmatage_qui && formik?.touched?.colmatage_qui ? (
                  <div className="error">
                    {t(formik?.errors?.colmatage_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="colmatage_fait"
                  name="colmatage_fait"
                  type="date"
                  labelHidden
                  value={formik.values.colmatage_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.colmatage === 2
                    || formik.values.colmatage === 0}
                />
                {formik?.errors?.colmatage_fait && formik?.touched?.colmatage_fait ? (
                  <div className="error">
                    {t(formik?.errors?.colmatage_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.fermeture')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="fermeture_yes"
                    name="fermeture"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('fermeture', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.fermeture === 1}
                    value={1}
                    required={formik.values.fermeture !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="fermeture_no"
                    name="fermeture"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('fermeture', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.fermeture === 0}
                    value={0}
                    required={formik.values.fermeture !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.fermeture && formik.touched.fermeture ? (
                  <div className="error">
                    {t(formik.errors.fermeture)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="fermeture_qui"
                  name="fermeture_qui"
                  type="text"
                  labelHidden
                  value={formik.values.fermeture_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.fermeture === 2
                    || formik.values.fermeture === 0}
                />
                {formik?.errors?.fermeture_qui && formik?.touched?.fermeture_qui ? (
                  <div className="error">
                    {t(formik?.errors?.fermeture_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="fermeture_fait"
                  name="fermeture_fait"
                  type="date"
                  labelHidden
                  value={formik.values.fermeture_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.fermeture === 2
                    || formik.values.fermeture === 0}
                />
                {formik?.errors?.fermeture_fait && formik?.touched?.fermeture_fait ? (
                  <div className="error">
                    {t(formik?.errors?.fermeture_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.isolation_auto_incendie')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="isolation_auto_incendie_yes"
                    name="isolation_auto_incendie"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('isolation_auto_incendie', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.isolation_auto_incendie === 1}
                    value={1}
                    required={formik.values.isolation_auto_incendie !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="isolation_auto_incendie_no"
                    name="isolation_auto_incendie"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('isolation_auto_incendie', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.isolation_auto_incendie === 0}
                    value={0}
                    required={formik.values.isolation_auto_incendie !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.isolation_auto_incendie && formik.touched.isolation_auto_incendie ? (
                  <div className="error">
                    {t(formik.errors.isolation_auto_incendie)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="isolation_auto_incendie_qui"
                  name="isolation_auto_incendie_qui"
                  type="text"
                  labelHidden
                  value={formik.values.isolation_auto_incendie_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.isolation_auto_incendie === 2
                    || formik.values.isolation_auto_incendie === 0}
                />
                {formik?.errors?.isolation_auto_incendie_qui && formik?.touched?.isolation_auto_incendie_qui ? (
                  <div className="error">
                    {t(formik?.errors?.isolation_auto_incendie_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="isolation_auto_incendie_fait"
                  name="isolation_auto_incendie_fait"
                  type="date"
                  labelHidden
                  value={formik.values.isolation_auto_incendie_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.isolation_auto_incendie === 2
                    || formik.values.isolation_auto_incendie === 0}
                />
                {formik?.errors?.isolation_auto_incendie_fait && formik?.touched?.isolation_auto_incendie_fait ? (
                  <div className="error">
                    {t(formik?.errors?.isolation_auto_incendie_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.isolation_systeme_extinction')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="isolation_systeme_extinction_yes"
                    name="isolation_systeme_extinction"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('isolation_systeme_extinction', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.isolation_systeme_extinction === 1}
                    value={1}
                    required={formik.values.isolation_systeme_extinction !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="isolation_systeme_extinction_no"
                    name="isolation_systeme_extinction"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('isolation_systeme_extinction', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.isolation_systeme_extinction === 0}
                    value={0}
                    required={formik.values.isolation_systeme_extinction !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.isolation_systeme_extinction
                  && formik.touched.isolation_systeme_extinction ? (
                    <div className="error">
                      {t(formik.errors.isolation_systeme_extinction)}
                    </div>
                  ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="isolation_systeme_extinction_qui"
                  name="isolation_systeme_extinction_qui"
                  type="text"
                  labelHidden
                  value={formik.values.isolation_systeme_extinction_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.isolation_systeme_extinction === 2
                    || formik.values.isolation_systeme_extinction === 0}
                />
                {formik?.errors?.isolation_systeme_extinction_qui
                  && formik?.touched?.isolation_systeme_extinction_qui ? (
                    <div className="error">
                      {t(formik?.errors?.isolation_systeme_extinction_qui)}
                    </div>
                  ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="isolation_systeme_extinction_fait"
                  name="isolation_systeme_extinction_fait"
                  type="date"
                  labelHidden
                  value={formik.values.isolation_systeme_extinction_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.isolation_systeme_extinction === 2
                    || formik.values.isolation_systeme_extinction === 0}
                />
                {formik?.errors?.isolation_systeme_extinction_fait
                  && formik?.touched?.isolation_systeme_extinction_fait ? (
                    <div className="error">
                      {t(formik?.errors?.isolation_systeme_extinction_fait)}
                    </div>
                  ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={styles.one_div_radio}>
                <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.autre')}</div>
                {formik.values.autre_ms === 1 ? (
                  <>
                    <Input
                      id="autre_ms_text"
                      name="autre_ms_text"
                      type="text"
                      labelHidden
                      placeholder={t('permis_feu.apreciser')}
                      value={formik.values.autre_ms_text}
                      onChange={formik.handleChange}
                      required={formik.values.autre_ms === 1 && Enregistre === true}
                    />
                    {formik?.errors?.autre_ms_text
                      && formik?.touched?.autre_ms_text ? (
                        <div className="error">
                          {t(formik?.errors?.autre_ms_text)}
                        </div>
                      ) : null}
                  </>
                ) : null}
              </div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="autre_ms_yes"
                    name="autre_ms"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('autre_ms', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.autre_ms === 1}
                    value={1}
                  />
                </div>
                <div>
                  <Radio
                    id="autre_ms_no"
                    name="autre_ms"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('autre_ms', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.autre_ms === 0}
                    value={0}
                  />
                </div>
                {formik?.errors?.autre_ms
                  && formik?.touched?.autre_ms ? (
                    <div className="error">
                      {t(formik?.errors?.autre_ms)}
                    </div>
                  ) : null}
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="autre_ms_qui"
                  name="autre_ms_qui"
                  type="text"
                  labelHidden
                  value={formik.values.autre_ms_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.autre_ms !== 1}
                />
                {formik?.errors?.autre_ms_qui
                  && formik?.touched?.autre_ms_qui ? (
                    <div className="error">
                      {t(formik?.errors?.autre_ms_qui)}
                    </div>
                  ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="autre_ms_fait"
                  name="autre_ms_fait"
                  type="date"
                  labelHidden
                  value={formik.values.autre_ms_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.autre_ms !== 1}
                />
                {formik?.errors?.autre_ms_fait
                  && formik?.touched?.autre_ms_fait ? (
                    <div className="error">
                      {t(formik?.errors?.autre_ms_fait)}
                    </div>
                  ) : null}
              </div>
            </div>
          </div>
          <div className="breakpage">
            {' '}
          </div>
          <div className={styles.between}>
            <h2 className="title">
              {t('permis_feu.moyenprevention')}
            </h2>
            <h2 className="title">
              {t('permis_feu.6/8')}
            </h2>
          </div>
          <div className={cn(['shadow-sm mb-20', styles.div_miseensecu])}>
            <div className={cn(['from_groups', styles.big_div])}>
              <div className={styles.one_div_radio}>
                {' '}
              </div>
              <div className={styles.two_div_radio}>
                {t('permis_feu.afaire')}
              </div>
              <div className={styles.three_div_radio}>
                {t('permis_feu.qui')}
              </div>
              <div className={styles.four_div_radio}>
                {t('permis_feu.faitle')}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <h3 className="title">
                {t('permis_feu.protection_abords')}
              </h3>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.ecrans_panneaux')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="ecrans_panneaux_yes"
                    name="ecrans_panneaux"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('ecrans_panneaux', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.ecrans_panneaux === 1}
                    value={1}
                    required={formik.values.ecrans_panneaux !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="ecrans_panneaux_no"
                    name="ecrans_panneaux"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('ecrans_panneaux', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.ecrans_panneaux === 0}
                    value={0}
                    required={formik.values.ecrans_panneaux !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.ecrans_panneaux && formik.touched.ecrans_panneaux ? (
                  <div className="error">
                    {t(formik.errors.ecrans_panneaux)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="ecrans_panneaux_qui"
                  name="ecrans_panneaux_qui"
                  type="text"
                  labelHidden
                  value={formik.values.ecrans_panneaux_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.ecrans_panneaux === 2
                    || formik.values.ecrans_panneaux === 0}
                />
                {formik?.errors?.ecrans_panneaux_qui && formik?.touched?.ecrans_panneaux_qui ? (
                  <div className="error">
                    {t(formik?.errors?.ecrans_panneaux_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="ecrans_panneaux_fait"
                  name="ecrans_panneaux_fait"
                  type="date"
                  labelHidden
                  placeholder={t('permis_feu.entrerdate')}
                  value={formik.values.ecrans_panneaux_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.ecrans_panneaux === 2
                    || formik.values.ecrans_panneaux === 0}
                />
                {formik?.errors?.ecrans_panneaux_fait && formik?.touched?.ecrans_panneaux_fait ? (
                  <div className="error">
                    {t(formik?.errors?.ecrans_panneaux_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.baches_ignifugees')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="baches_ignifugees_yes"
                    name="baches_ignifugees"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('baches_ignifugees', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.baches_ignifugees === 1}
                    value={1}
                    required={formik.values.baches_ignifugees !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="baches_ignifugees_no"
                    name="baches_ignifugees"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('baches_ignifugees', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.baches_ignifugees === 0}
                    value={0}
                    required={formik.values.baches_ignifugees !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.baches_ignifugees && formik.touched.baches_ignifugees ? (
                  <div className="error">
                    {t(formik.errors.baches_ignifugees)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="baches_ignifugees_qui"
                  name="baches_ignifugees_qui"
                  type="text"
                  labelHidden
                  value={formik.values.baches_ignifugees_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.baches_ignifugees === 2
                    || formik.values.baches_ignifugees === 0}
                />
                {formik?.errors?.baches_ignifugees_qui && formik?.touched?.baches_ignifugees_qui ? (
                  <div className="error">
                    {t(formik?.errors?.baches_ignifugees_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="baches_ignifugees_fait"
                  name="baches_ignifugees_fait"
                  type="date"
                  labelHidden
                  placeholder={t('permis_feu.entrerdate')}
                  value={formik.values.baches_ignifugees_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.baches_ignifugees === 2
                    || formik.values.baches_ignifugees === 0}
                />
                {formik?.errors?.baches_ignifugees_fait && formik?.touched?.baches_ignifugees_fait ? (
                  <div className="error">
                    {t(formik?.errors?.baches_ignifugees_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.eau_arrosage')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="eau_arrosage_yes"
                    name="eau_arrosage"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('eau_arrosage', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.eau_arrosage === 1}
                    value={1}
                    required={formik.values.eau_arrosage !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="eau_arrosage_no"
                    name="eau_arrosage"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('eau_arrosage', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.eau_arrosage === 0}
                    value={0}
                    required={formik.values.eau_arrosage !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.eau_arrosage && formik.touched.eau_arrosage ? (
                  <div className="error">
                    {t(formik.errors.eau_arrosage)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="eau_arrosage_qui"
                  name="eau_arrosage_qui"
                  type="text"
                  labelHidden
                  value={formik.values.eau_arrosage_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.eau_arrosage === 2
                    || formik.values.eau_arrosage === 0}
                />
                {formik?.errors?.eau_arrosage_qui && formik?.touched?.eau_arrosage_qui ? (
                  <div className="error">
                    {t(formik?.errors?.eau_arrosage_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="eau_arrosage_fait"
                  name="eau_arrosage_fait"
                  type="date"
                  labelHidden
                  placeholder={t('permis_feu.entrerdate')}
                  value={formik.values.eau_arrosage_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.eau_arrosage === 2
                    || formik.values.eau_arrosage === 0}
                />
                {formik?.errors?.eau_arrosage_fait && formik?.touched?.eau_arrosage_fait ? (
                  <div className="error">
                    {t(formik?.errors?.eau_arrosage_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.sable')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="sable_yes"
                    name="sable"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('sable', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.sable === 1}
                    value={1}
                    required={formik.values.sable !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="sable_no"
                    name="ecrans_pansableneaux"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('sable', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.sable === 0}
                    value={0}
                    required={formik.values.sable !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.sable && formik.touched.sable ? (
                  <div className="error">
                    {t(formik.errors.sable)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="sable_qui"
                  name="sable_qui"
                  type="text"
                  labelHidden
                  value={formik.values.sable_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.sable === 2
                    || formik.values.sable === 0}
                />
                {formik?.errors?.sable_qui && formik?.touched?.sable_qui ? (
                  <div className="error">
                    {t(formik?.errors?.sable_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="sable_fait"
                  name="sable_fait"
                  type="date"
                  labelHidden
                  placeholder={t('permis_feu.entrerdate')}
                  value={formik.values.sable_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.sable === 2
                    || formik.values.sable === 0}
                />
                {formik?.errors?.sable_fait && formik?.touched?.sable_fait ? (
                  <div className="error">
                    {t(formik?.errors?.sable_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.absorbant')}</div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="absorbant_yes"
                    name="absorbant"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('absorbant', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.absorbant === 1}
                    value={1}
                    required={formik.values.absorbant !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="absorbant_no"
                    name="absorbant"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('absorbant', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.absorbant === 0}
                    value={0}
                    required={formik.values.absorbant !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.absorbant && formik.touched.absorbant ? (
                  <div className="error">
                    {t(formik.errors.absorbant)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="absorbant_qui"
                  name="absorbant_qui"
                  type="text"
                  labelHidden
                  value={formik.values.absorbant_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.absorbant === 2
                    || formik.values.absorbant === 0}
                />
                {formik?.errors?.absorbant_qui && formik?.touched?.absorbant_qui ? (
                  <div className="error">
                    {t(formik?.errors?.absorbant_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="absorbant_fait"
                  name="absorbant_fait"
                  type="date"
                  labelHidden
                  placeholder={t('permis_feu.entrerdate')}
                  value={formik.values.absorbant_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.absorbant === 2
                    || formik.values.absorbant === 0}
                />
                {formik?.errors?.absorbant_fait && formik?.touched?.absorbant_fait ? (
                  <div className="error">
                    {t(formik?.errors?.absorbant_fait)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={styles.one_div_radio}>
                <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.autre')}</div>
                {formik.values.autre === 1 ? (
                  <Input
                    id="autre_text"
                    name="autre_text"
                    type="text"
                    labelHidden
                    placeholder={t('permis_feu.apreciser')}
                    value={formik.values.autre_text}
                    onChange={formik.handleChange}
                    required={formik.values.autre === 1 && Enregistre === true}
                  />
                ) : null}
                {formik?.errors?.autre_text && formik?.touched?.autre_text ? (
                  <div className="error">
                    {t(formik?.errors?.autre_text)}
                  </div>
                ) : null}
              </div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="autre_yes"
                    name="autre"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('autre', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.autre === 1}
                    value={1}
                  />
                </div>
                <div>
                  <Radio
                    id="autre_no"
                    name="autre"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('autre', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.autre === 0}
                    value={0}
                  />
                </div>
                {formik?.errors?.autre && formik?.touched?.autre ? (
                  <div className="error">
                    {t(formik?.errors?.autre)}
                  </div>
                ) : null}
              </div>
              <div className={styles.three_div_radio}>
                <Input
                  id="autre_qui"
                  name="autre_qui"
                  type="text"
                  labelHidden
                  value={formik.values.autre_qui}
                  onChange={formik.handleChange}
                  disabled={formik.values.autre !== 1}
                />
                {formik?.errors?.autre_qui && formik?.touched?.autre_qui ? (
                  <div className="error">
                    {t(formik?.errors?.autre_qui)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_radio}>
                <Input
                  id="autre_fait"
                  name="autre_fait"
                  type="date"
                  labelHidden
                  value={formik.values.autre_fait}
                  onChange={formik.handleChange}
                  disabled={formik.values.autre !== 1}
                />
                {formik?.errors?.autre_qui && formik?.touched?.autre_qui ? (
                  <div className="error">
                    {t(formik?.errors?.autre_qui)}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={styles.one_div_radio}>
                <h3 className="title">
                  {t('permis_feu.moyens_lutte_incendie')}
                </h3>
              </div>
              <div className={styles.two_div_radio}>
                <div>
                  <Radio
                    id="moyens_lutte_incendie_yes"
                    name="moyens_lutte_incendie"
                    label={t('contract.contractModifyes')}
                    onChange={(event) => formik
                      .setFieldValue('moyens_lutte_incendie', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.moyens_lutte_incendie === 1}
                    value={1}
                    required={formik.values.moyens_lutte_incendie !== 2 && Enregistre === true}
                  />
                </div>
                <div>
                  <Radio
                    id="moyens_lutte_incendie_no"
                    name="moyens_lutte_incendie"
                    label={t('contract.contractModifNo')}
                    onChange={(event) => formik
                      .setFieldValue('moyens_lutte_incendie', parseInt(event.target.value, 10))}
                    onBlur={formik.handleBlur}
                    checked={formik.values.moyens_lutte_incendie === 0}
                    value={0}
                    required={formik.values.moyens_lutte_incendie !== 2 && Enregistre === true}
                  />
                </div>
                {formik.errors.moyens_lutte_incendie && formik.touched.moyens_lutte_incendie ? (
                  <div className="error">
                    {t(formik.errors.moyens_lutte_incendie)}
                  </div>
                ) : null }
              </div>
              <div className={styles.three_div_radio}>
                {' '}
              </div>
              <div className={styles.four_div_radio}>
                {' '}
              </div>
            </div>
            {formik.values.moyens_lutte_incendie === 1 ? (
              <>
                <div className={cn(['from_groups', styles.div_radio])}>
                  <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.nombre_extincteur')}</div>
                  <div className={styles.two_div_radio}>
                    <Input
                      id="nombre_extincteur"
                      name="nombre_extincteur"
                      type="number"
                      labelHidden
                      value={formik.values.nombre_extincteur}
                      onChange={formik.handleChange}
                      min="0"
                      required={formik.values.moyens_lutte_incendie === 1 && Enregistre === true}
                    />
                    {formik.errors.nombre_extincteur && formik.touched.nombre_extincteur ? (
                      <div className="error">
                        {t(formik.errors.nombre_extincteur)}
                      </div>
                    ) : null }
                  </div>
                  <div className={styles.three_div_radio}>
                    <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.type_extincteur')}</div>
                  </div>
                  <div className={styles.four_div_radio}>
                    <Input
                      id="nombre_extincteur_type"
                      name="nombre_extincteur_type"
                      type="text"
                      labelHidden
                      value={formik.values.nombre_extincteur_type}
                      onChange={formik.handleChange}
                      required={formik.values.nombre_extincteur > 0 && Enregistre === true}
                      disabled={formik.values.nombre_extincteur === 0}
                    />
                    {formik?.errors?.nombre_extincteur_type && formik?.touched?.nombre_extincteur_type ? (
                      <div className="error">
                        {t(formik?.errors?.nombre_extincteur_type)}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={cn(['from_groups', styles.div_radio])}>
                  <div className={cn(['label', styles.one_div_radio])}>{t('permis_feu.autre_info')}</div>
                  <div className={styles.two_div_radio}>
                    <div>
                      <Radio
                        id="autre_info_yes"
                        name="autre_info"
                        label={t('contract.contractModifyes')}
                        onChange={(event) => formik
                          .setFieldValue('autre_info', parseInt(event.target.value, 10))}
                        onBlur={formik.handleBlur}
                        checked={formik.values.autre_info === 1}
                        value={1}
                      />
                    </div>
                    <div>
                      <Radio
                        id="autre_info_no"
                        name="autre_info"
                        label={t('contract.contractModifNo')}
                        onChange={(event) => formik
                          .setFieldValue('autre_info', parseInt(event.target.value, 10))}
                        onBlur={formik.handleBlur}
                        checked={formik.values.autre_info === 0}
                        value={0}
                      />
                    </div>
                    {formik.errors.autre_info && formik.touched.autre_info ? (
                      <div className="error">
                        {t(formik.errors.autre_info)}
                      </div>
                    ) : null }
                  </div>
                  <div className={styles.three_div_radio}>
                    <Input
                      id="autre_info_text"
                      name="autre_info_text"
                      type="text"
                      labelHidden
                      placeholder={t('permis_feu.text_preciser')}
                      value={formik.values.autre_info_text}
                      onChange={formik.handleChange}
                      disabled={formik.values.autre_info !== 1}
                      required={formik.values.autre_info === 1 && Enregistre === true}
                    />
                    {formik.errors.autre_info_text && formik.touched.autre_info_text ? (
                      <div className="error">
                        {t(formik.errors.autre_info_text)}
                      </div>
                    ) : null }
                  </div>
                  <div className={styles.four_div_radio}>
                    <Input
                      id="autre_info_fait"
                      name="autre_info_fait"
                      type="date"
                      labelHidden
                      value={formik.values.autre_info_fait}
                      onChange={formik.handleChange}
                      disabled={formik.values.autre_info !== 1}
                    />
                    {formik?.errors?.autre_info_fait && formik?.touched?.autre_info_fait ? (
                      <div className="error">
                        {t(formik?.errors?.autre_info_fait)}
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className="breakpage">
            {' '}
          </div>
          <div className={styles.between}>
            <h2 className="title">
              {t('permis_feu.surveillance_consignes')}
            </h2>
            <h2 className="title">
              {t('permis_feu.7/8')}
            </h2>
          </div>
          <div className="form shadow-sm mb-20">
            <h2 className="title-info">
              {t('permis_feu.surveillance_securite')}
            </h2>
            <div>
              {' '}
            </div>
            <div className="form_group">
              <Input
                id="pendant_travaux"
                name="pendant_travaux"
                type="text"
                label={t('permis_feu.pendant_travaux')}
                placeholder={t('permis_feu.fullname')}
                value={formik.values.pendant_travaux}
                onChange={(e) => formik.setFieldValue('pendant_travaux', e.target.value.toUpperCase())}
              />
              {formik?.errors?.pendant_travaux && formik?.touched?.pendant_travaux ? (
                <div className="error">
                  {t(formik?.errors?.pendant_travaux)}
                </div>
              ) : null}
            </div>
            <div className="form_group">
              {SignaturePendantTravaux ? (
                <>
                  <button
                    type="button"
                    className={cn([styles.button, styles.marginSign])}
                    onClick={() => setOpenSignaturePendantTravaux(true)}
                  >
                    <img
                      src={SignaturePendantTravaux}
                      alt="signature"
                      className={styles.signature}
                    />
                    <FontAwesomeIcon icon={faFileSignature} className={styles.FontAwesomeIcon} transform="grow-15" />
                  </button>
                  {formik.errors.SignaturePendantTravaux && formik.touched.SignaturePendantTravaux ? (
                    <div className="error">
                      {t(formik.errors.SignaturePendantTravaux)}
                    </div>
                  ) : null }
                </>
              ) : (
                <button
                  type="button"
                  className={cn(['form_submit', styles.button, styles.marginSign])}
                  onClick={() => setOpenSignaturePendantTravaux(true)}
                >
                  {SignaturePendantTravaux ? t('dairy.change_signature') : t('dairy.add_signature')}
                  <FontAwesomeIcon icon={faFileSignature} className={styles.FontAwesomeIcon} transform="grow-15" />
                </button>
              )}
            </div>
            <div className="form_group">
              <Input
                id="apres_travaux"
                name="apres_travaux"
                type="text"
                label={t('permis_feu.apres_travaux')}
                placeholder={t('permis_feu.fullname')}
                value={formik.values.apres_travaux}
                onChange={(e) => formik.setFieldValue('apres_travaux', e.target.value.toUpperCase())}
              />
              {formik?.errors?.apres_travaux && formik?.touched?.apres_travaux ? (
                <div className="error">
                  {t(formik?.errors?.apres_travaux)}
                </div>
              ) : null}
              <div className={styles.flex}>
                <div className={styles.column}>
                  <Input
                    id="apartirde"
                    name="apartirde"
                    type="time"
                    label={t('permis_feu.apartirde')}
                    placeholder={t('permis_feu.fullname')}
                    value={formik.values.apartirde}
                    onChange={formik.handleChange}
                  />
                  {formik?.errors?.apartirde && formik?.touched?.apartirde ? (
                    <div className="error">
                      {t(formik?.errors?.apartirde)}
                    </div>
                  ) : null}
                </div>
                <div className={styles.column}>
                  <Input
                    id="jusqua"
                    name="jusqua"
                    type="time"
                    label={t('permis_feu.jusqua')}
                    placeholder={t('permis_feu.fullname')}
                    value={formik.values.jusqua}
                    onChange={formik.handleChange}
                  />
                  {formik?.errors?.jusqua && formik?.touched?.jusqua ? (
                    <div className="error">
                      {t(formik?.errors?.jusqua)}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form_group">
              {SignatureApresTravaux ? (
                <>
                  <button
                    type="button"
                    className={cn([styles.button, styles.marginSign])}
                    onClick={() => setOpenSignatureApresTravaux(true)}
                  >
                    <img
                      src={SignatureApresTravaux}
                      alt="SignatureApresTravaux"
                      className={styles.signature}
                    />
                    <FontAwesomeIcon icon={faFileSignature} className={styles.FontAwesomeIcon} transform="grow-15" />
                  </button>
                  {formik.errors.SignatureApresTravaux && formik.touched.SignatureApresTravaux ? (
                    <div className="error">
                      {t(formik.errors.SignatureApresTravaux)}
                    </div>
                  ) : null }
                </>
              ) : (
                <button
                  type="button"
                  className={cn(['form_submit', styles.button, styles.marginSign])}
                  onClick={() => setOpenSignatureApresTravaux(true)}
                >
                  {SignatureApresTravaux ? t('dairy.change_signature') : t('dairy.add_signature')}
                  <FontAwesomeIcon icon={faFileSignature} className={styles.FontAwesomeIcon} transform="grow-15" />
                </button>
              )}
            </div>
            <div className="form_group">
              <h2 className="title-info">
                {t('permis_feu.consigne_alerte')}
              </h2>
            </div>
            <div className="form_group">
              {' '}
            </div>
            <div className="form_group">
              <Textarea
                id="preciser_consignes"
                name="preciser_consignes"
                label={t('permis_feu.preciser_consignes')}
                value={formik.values.preciser_consignes}
                onChange={formik.handleChange}
                cols="10"
                rows="5"
              />
              {formik?.errors?.preciser_consignes && formik?.touched?.preciser_consignes ? (
                <div className="error">
                  {t(formik?.errors?.preciser_consignes)}
                </div>
              ) : null}
            </div>
            <div className="form_group">
              <div>{t('permis_feu.numero_urgence')}</div>
              <Input
                id="personne_contacter_nom"
                name="personne_contacter_nom"
                type="text"
                label={t('permis_feu.personne_conctater_incendie')}
                placeholder={t('permis_feu.fullname')}
                value={formik.values.personne_contacter_nom}
                onChange={(e) => formik.setFieldValue('personne_contacter_nom', e.target.value.toUpperCase())}
              />
              {formik?.errors?.personne_contacter_nom && formik?.touched?.personne_contacter_nom ? (
                <div className="error">
                  {t(formik?.errors?.personne_contacter_nom)}
                </div>
              ) : null}
              <div className={styles.margin_top}>
                {' '}
              </div>
              <Input
                id="personne_contacter_tel"
                name="personne_contacter_tel"
                type="text"
                labelHidden
                placeholder={t('permis_feu.num_tel')}
                value={formik.values.personne_contacter_tel}
                onChange={formik.handleChange}
              />
              {formik?.errors?.personne_contacter_tel && formik?.touched?.personne_contacter_tel ? (
                <div className="error">
                  {t(formik?.errors?.personne_contacter_tel)}
                </div>
              ) : null}
              <div className={cn(['label'])}>
                <div className={styles.flex}>
                  <p>
                    {t('permis_feu.sapeur_pompier')}
                    <span className={styles.pompier}>{t('permis_feu.18')}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="breakpage">
            {' '}
          </div>
          <div className={styles.between}>
            <h2 className="title">
              {t('permis_feu.signatures')}
            </h2>
            <h2 className="title">
              {t('permis_feu.8/8')}
            </h2>
          </div>
          <div className={cn(['shadow-sm mb-20', styles.div_miseensecu])}>
            <h2 className="title-info">
              {t('permis_feu.personne_concerne')}
            </h2>
            <div>
              {' '}
            </div>
            <div className={cn(['from_groups', styles.big_div])}>
              <div className={styles.one_div_signature}>
                {' '}
              </div>
              <div className={styles.two_div_signature}>
                {t('permis_feu.nom')}
              </div>
              <div className={styles.three_div_signature}>
                {t('permis_feu.qualite')}
              </div>
              <div className={styles.four_div_signature}>
                {t('permis_feu.signature')}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_signature])}>
                {matches ? (
                  t('permis_feu.resp_travaux')
                ) : (
                  t('permis_feu.resp_travaux_rac')
                )}
              </div>
              <div className={styles.two_div_signature}>
                <Input
                  id="resp_travaux_nom"
                  name="resp_travaux_nom"
                  type="text"
                  labelHidden
                  value={formik.values.resp_travaux_nom}
                  onChange={(e) => formik.setFieldValue('resp_travaux_nom', e.target.value.toUpperCase())}
                />
                {formik?.errors?.resp_travaux_nom && formik?.touched?.resp_travaux_nom ? (
                  <div className="error">
                    {t(formik?.errors?.resp_travaux_nom)}
                  </div>
                ) : null}
              </div>
              <div className={styles.three_div_signature}>
                <Input
                  id="resp_travaux_qualite"
                  name="resp_travaux_qualite"
                  type="text"
                  labelHidden
                  value={formik.values.resp_travaux_qualite}
                  onChange={formik.handleChange}
                />
                {formik?.errors?.resp_travaux_qualite && formik?.touched?.resp_travaux_qualite ? (
                  <div className="error">
                    {t(formik?.errors?.resp_travaux_qualite)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_signature}>
                {SignatureRespTravaux ? (
                  <>
                    <button
                      type="button"
                      className={styles.button}
                      onClick={() => setOpenSignatureRespTravaux(true)}
                    >
                      <img
                        src={SignatureRespTravaux}
                        alt="signature"
                        className={styles.signature}
                      />
                      {matches ? (
                        <FontAwesomeIcon
                          icon={faFileSignature}
                          className={styles.FontAwesomeIcon}
                          transform="grow-15"
                        />
                      ) : null}
                    </button>
                    {formik.errors.SignatureRespTravaux && formik.touched.SignatureRespTravaux ? (
                      <div className="error">
                        {t(formik.errors.SignatureRespTravaux)}
                      </div>
                    ) : null }
                  </>
                ) : (
                  <button
                    type="button"
                    className={cn(['form_submit', styles.button])}
                    onClick={() => setOpenSignatureRespTravaux(true)}
                  >
                    {matches ? (
                      <>
                        {SignatureRespTravaux ? t('dairy.change_signature') : t('dairy.add_signature')}
                        <FontAwesomeIcon
                          icon={faFileSignature}
                          className={styles.FontAwesomeIcon}
                          transform="grow-15"
                        />
                      </>
                    ) : (
                      <FontAwesomeIcon
                        icon={faFileSignature}
                        className={styles.FontAwesomeIcon}
                        transform="grow-15"
                      />
                    )}
                  </button>
                )}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_signature])}>
                {t('permis_feu.surveillant_travaux')}
              </div>
              <div className={styles.two_div_signature}>
                <Input
                  id="surveillant_travaux_nom"
                  name="surveillant_travaux_nom"
                  type="text"
                  labelHidden
                  value={formik.values.surveillant_travaux_nom}
                  onChange={(e) => formik.setFieldValue('surveillant_travaux_nom', e.target.value.toUpperCase())}
                />
                {formik?.errors?.surveillant_travaux_nom && formik?.touched?.surveillant_travaux_nom ? (
                  <div className="error">
                    {t(formik?.errors?.surveillant_travaux_nom)}
                  </div>
                ) : null}
              </div>
              <div className={styles.three_div_signature}>
                <Input
                  id="surveillant_travaux_qualite"
                  name="surveillant_travaux_qualite"
                  type="text"
                  labelHidden
                  value={formik.values.surveillant_travaux_qualite}
                  onChange={formik.handleChange}
                />
                {formik?.errors?.surveillant_travaux_qualite && formik?.touched?.surveillant_travaux_qualite ? (
                  <div className="error">
                    {t(formik?.errors?.surveillant_travaux_qualite)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_signature}>
                {SignatureSurvTravaux ? (
                  <>
                    <button
                      type="button"
                      className={styles.button}
                      onClick={() => setOpenSignatureSurvTravaux(true)}
                    >
                      <img
                        src={SignatureSurvTravaux}
                        alt="signature"
                        className={styles.signature}
                      />
                      {matches ? (
                        <FontAwesomeIcon
                          icon={faFileSignature}
                          className={styles.FontAwesomeIcon}
                          transform="grow-15"
                        />
                      ) : null}
                    </button>
                    {formik.errors.SignatureSurvTravaux && formik.touched.SignatureSurvTravaux ? (
                      <div className="error">
                        {t(formik.errors.SignatureSurvTravaux)}
                      </div>
                    ) : null }
                  </>
                ) : (
                  <button
                    type="button"
                    className={cn(['form_submit', styles.button])}
                    onClick={() => setOpenSignatureSurvTravaux(true)}
                  >
                    {matches ? (
                      <>
                        {SignatureRespTravaux ? t('dairy.change_signature') : t('dairy.add_signature')}
                        <FontAwesomeIcon
                          icon={faFileSignature}
                          className={styles.FontAwesomeIcon}
                          transform="grow-15"
                        />
                      </>
                    ) : (
                      <FontAwesomeIcon
                        icon={faFileSignature}
                        className={styles.FontAwesomeIcon}
                        transform="grow-15"
                      />
                    )}
                  </button>
                )}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_signature])}>
                {matches ? (
                  t('permis_feu.resp_intervention')
                ) : (
                  t('permis_feu.resp_inter_rac')
                )}
              </div>
              <div className={styles.two_div_signature}>
                <Input
                  id="resp_intervention_nom"
                  name="resp_intervention_nom"
                  type="text"
                  labelHidden
                  value={formik.values.resp_intervention_nom}
                  onChange={(e) => formik.setFieldValue('resp_intervention_nom', e.target.value.toUpperCase())}
                />
                {formik?.errors?.resp_intervention_nom && formik?.touched?.resp_intervention_nom ? (
                  <div className="error">
                    {t(formik?.errors?.resp_intervention_nom)}
                  </div>
                ) : null}
              </div>
              <div className={styles.three_div_signature}>
                <Input
                  id="resp_intervention_qualite"
                  name="resp_intervention_qualite"
                  type="text"
                  labelHidden
                  value={formik.values.resp_intervention_qualite}
                  onChange={formik.handleChange}
                />
                {formik?.errors?.resp_intervention_qualite && formik?.touched?.resp_intervention_qualite ? (
                  <div className="error">
                    {t(formik?.errors?.resp_intervention_qualite)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_signature}>
                {SignatureRespInter ? (
                  <>
                    <button
                      type="button"
                      className={styles.button}
                      onClick={() => setOpenSignatureRespInter(true)}
                    >
                      <img
                        src={SignatureRespInter}
                        alt="signature"
                        className={styles.signature}
                      />
                      {matches ? (
                        <FontAwesomeIcon
                          icon={faFileSignature}
                          className={styles.FontAwesomeIcon}
                          transform="grow-15"
                        />
                      ) : null}
                    </button>
                    {formik.errors.SignatureRespInter && formik.touched.SignatureRespInter ? (
                      <div className="error">
                        {t(formik.errors.SignatureRespInter)}
                      </div>
                    ) : null }
                  </>
                ) : (
                  <button
                    type="button"
                    className={cn(['form_submit', styles.button])}
                    onClick={() => setOpenSignatureRespInter(true)}
                  >
                    {matches ? (
                      <>
                        {SignatureRespTravaux ? t('dairy.change_signature') : t('dairy.add_signature')}
                        <FontAwesomeIcon
                          icon={faFileSignature}
                          className={styles.FontAwesomeIcon}
                          transform="grow-15"
                        />
                      </>
                    ) : (
                      <FontAwesomeIcon
                        icon={faFileSignature}
                        className={styles.FontAwesomeIcon}
                        transform="grow-15"
                      />
                    )}
                  </button>
                )}
              </div>
            </div>
            <div className={cn(['from_groups', styles.div_radio])}>
              <div className={cn(['label', styles.one_div_signature_autre, styles.flex])}>
                <p>{t('permis_feu.autre')}</p>
                {formik?.errors?.autre_signature_nom && formik?.touched?.autre_signature_nom ? (
                  <div className="error">
                    {t(formik?.errors?.autre_signature_nom)}
                  </div>
                ) : null}
              </div>
              <div className={styles.two_div_signature_autre}>
                <Input
                  id="autre_signature"
                  name="autre_signature"
                  type="text"
                  labelHidden
                  placeholder={t('permis_feu.apreciser')}
                  value={formik.values.autre_signature}
                  onChange={formik.handleChange}
                />
                {formik?.errors?.autre_signature && formik?.touched?.autre_signature ? (
                  <div className="error">
                    {t(formik?.errors?.autre_signature)}
                  </div>
                ) : null}
              </div>
              <div className={styles.three_div_signature_autre}>
                <Input
                  id="autre_signature_nom"
                  name="autre_signature_nom"
                  type="text"
                  labelHidden
                  value={formik.values.autre_signature_nom}
                  onChange={(e) => formik.setFieldValue('autre_signature_nom', e.target.value.toUpperCase())}
                />
                {formik?.errors?.autre_signature_nom && formik?.touched?.autre_signature_nom ? (
                  <div className="error">
                    {t(formik?.errors?.autre_signature_nom)}
                  </div>
                ) : null}
              </div>
              <div className={styles.four_div_signature_autre}>
                <Input
                  id="autre_signature_qualite"
                  name="autre_signature_qualite"
                  type="text"
                  labelHidden
                  value={formik.values.autre_signature_qualite}
                  onChange={formik.handleChange}
                />
                {formik?.errors?.autre_signature_qualite && formik?.touched?.autre_signature_qualite ? (
                  <div className="error">
                    {t(formik?.errors?.autre_signature_qualite)}
                  </div>
                ) : null}
              </div>
              <div className={styles.five_div_signature_autre}>
                {SignatureAutre ? (
                  <>
                    <button
                      type="button"
                      className={styles.button}
                      onClick={() => setOpenSignatureAutre(true)}
                    >
                      <img
                        src={SignatureAutre}
                        alt="signature"
                        className={styles.signature}
                      />
                      {matches ? (
                        <FontAwesomeIcon
                          icon={faFileSignature}
                          className={styles.FontAwesomeIcon}
                          transform="grow-15"
                        />
                      ) : null}
                    </button>
                    {formik.errors.SignatureAutre && formik.touched.SignatureAutre ? (
                      <div className="error">
                        {t(formik.errors.SignatureAutre)}
                      </div>
                    ) : null }
                  </>
                ) : (
                  <button
                    type="button"
                    className={cn(['form_submit', styles.button])}
                    onClick={() => setOpenSignatureAutre(true)}
                  >
                    {matches ? (
                      <>
                        {SignatureRespTravaux ? t('dairy.change_signature') : t('dairy.add_signature')}
                        <FontAwesomeIcon
                          icon={faFileSignature}
                          className={styles.FontAwesomeIcon}
                          transform="grow-15"
                        />
                      </>
                    ) : (
                      <FontAwesomeIcon
                        icon={faFileSignature}
                        className={styles.FontAwesomeIcon}
                        transform="grow-15"
                      />
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className={cn(['form shadow-sm', styles.marginBottom])}>
            <div className="form_group">
              <Input
                id="permisfeu_delivrer"
                name="permisfeu_delivrer"
                type="date"
                label={t('permis_feu.permisfeu_delivrer')}
                min={formatDate(timeElapsed)}
                value={formik.values.permisfeu_delivrer}
                onChange={formik.handleChange}
              />
              {formik?.errors?.permisfeu_delivrer && formik?.touched?.permisfeu_delivrer ? (
                <div className="error">
                  {t(formik?.errors?.permisfeu_delivrer)}
                </div>
              ) : null}
            </div>
            <div className="form_group">
              <div className="label">
                {t('permis_feu.signature')}
              </div>
              <br />
              {signature ? (
                <>
                  <button
                    type="button"
                    className={styles.button}
                    onClick={() => setOpenModal(true)}
                  >
                    <img
                      src={signature}
                      alt="signature"
                      className={styles.signature}
                    />
                    <FontAwesomeIcon icon={faFileSignature} className={styles.FontAwesomeIcon} transform="grow-15" />
                  </button>
                  {formik.errors.signature && formik.touched.signature ? (
                    <div className="error">
                      {t(formik.errors.signature)}
                    </div>
                  ) : null }
                </>
              ) : (
                <button
                  type="button"
                  className={cn(['form_submit', styles.button])}
                  onClick={() => setOpenModal(true)}
                >
                  {signature ? t('dairy.change_signature') : t('dairy.add_signature')}
                  <FontAwesomeIcon icon={faFileSignature} className={styles.FontAwesomeIcon} transform="grow-15" />
                </button>
              )}
            </div>
          </div>
          <div className={cn(['shadow-sm', 'printHidden', styles.footer])}>
            <div className={styles.div_footer}>
              <button
                type="button"
                className={cn(['form_submit', styles.buttonFinal])}
                onClick={() => { window.print(); }}
              >
                {t('permis_feu.imprimer')}
              </button>
              <button
                type="submit"
                className={cn(['form_submit', styles.buttonFinal])}
                onClick={() => { Enregistrer(); }}
              >
                {t('permis_feu.enregistrer')}
              </button>
              <button
                type="submit"
                className={cn(['form_submit', styles.buttonFinal])}
                onClick={() => { Sauvegarder(); }}
              >
                {t('permis_feu.sauvegarder')}
              </button>
            </div>
          </div>
        </form>
        <Modal
          isOpen={openModal}
          onRequestClose={() => setOpenModal(false)}
          style={customModalStyle}
        >
          <button onClick={() => setOpenModal(false)} type="button" className={styles.close}>
            <span aria-hidden="true">&times;</span>
          </button>
          <p className="modal_paragraph">
            { signature ? t('dairy.change_signature') : t('dairy.add_signature')}
          </p>
          <div className={cn(['modal_actions', styles.signatureContainer])}>
            <CanvasSignature
              canvasProps={{
                className: styles.canvas,
              }}
              name="signature"
              trim={() => trim()}
              clear={() => clear()}
              setSignature={setSignature}
              signature={signature}
              sigCanvas={sigCanvas}
            />
          </div>
        </Modal>
        <Modal
          isOpen={openSignaturePendantTravaux}
          onRequestClose={() => setOpenSignaturePendantTravaux(false)}
          style={customModalStyle}
        >
          <button onClick={() => setOpenSignaturePendantTravaux(false)} type="button" className={styles.close}>
            <span aria-hidden="true">&times;</span>
          </button>
          <p className="modal_paragraph">
            { SignaturePendantTravaux ? t('dairy.change_signature') : t('dairy.add_signature')}
          </p>
          <div className={cn(['modal_actions', styles.signatureContainer])}>
            <CanvasSignature
              canvasProps={{
                className: styles.canvas,
              }}
              name="SignaturePendantTravaux"
              trim={() => trimPendantTravaux()}
              clear={() => clearPendantTravaux()}
              setSignature={setSignaturePendantTravaux}
              signature={SignaturePendantTravaux}
              sigCanvas={sigCanvasPendantTravaux}
            />
          </div>
        </Modal>
        <Modal
          isOpen={openSignatureApresTravaux}
          onRequestClose={() => setOpenSignatureApresTravaux(false)}
          style={customModalStyle}
        >
          <button onClick={() => setOpenSignatureApresTravaux(false)} type="button" className={styles.close}>
            <span aria-hidden="true">&times;</span>
          </button>
          <p className="modal_paragraph">
            { SignatureApresTravaux ? t('dairy.change_signature') : t('dairy.add_signature')}
          </p>
          <div className={cn(['modal_actions', styles.signatureContainer])}>
            <CanvasSignature
              canvasProps={{
                className: styles.canvas,
              }}
              name="SignaturePendantTravaux"
              trim={() => trimApresTravaux()}
              clear={() => clearApresTravaux()}
              setSignature={setSignatureApresTravaux}
              signature={SignatureApresTravaux}
              sigCanvas={sigCanvasApresTravaux}
            />
          </div>
        </Modal>
        <Modal
          isOpen={openSignatureRespTravaux}
          onRequestClose={() => setOpenSignatureRespTravaux(false)}
          style={customModalStyle}
        >
          <button onClick={() => setOpenSignatureRespTravaux(false)} type="button" className={styles.close}>
            <span aria-hidden="true">&times;</span>
          </button>
          <p className="modal_paragraph">
            { SignatureRespTravaux ? t('dairy.change_signature') : t('dairy.add_signature')}
          </p>
          <div className={cn(['modal_actions', styles.signatureContainer])}>
            <CanvasSignature
              canvasProps={{
                className: styles.canvas,
              }}
              name="SignatureRespTravaux"
              trim={() => trimRespTravaux()}
              clear={() => clearRespTravaux()}
              setSignature={setSignatureRespTravaux}
              signature={SignatureRespTravaux}
              sigCanvas={sigCanvasRespTravaux}
            />
          </div>
        </Modal>
        <Modal
          isOpen={openSignatureSurvTravaux}
          onRequestClose={() => setOpenSignatureSurvTravaux(false)}
          style={customModalStyle}
        >
          <button onClick={() => setOpenSignatureSurvTravaux(false)} type="button" className={styles.close}>
            <span aria-hidden="true">&times;</span>
          </button>
          <p className="modal_paragraph">
            { SignatureSurvTravaux ? t('dairy.change_signature') : t('dairy.add_signature')}
          </p>
          <div className={cn(['modal_actions', styles.signatureContainer])}>
            <CanvasSignature
              canvasProps={{
                className: styles.canvas,
              }}
              name="SignatureSurvTravaux"
              trim={() => trimSurvTravaux()}
              clear={() => clearSurvTravaux()}
              setSignature={setSignatureSurvTravaux}
              signature={SignatureSurvTravaux}
              sigCanvas={sigCanvasSurvTravaux}
            />
          </div>
        </Modal>
        <Modal
          isOpen={openSignatureRespInter}
          onRequestClose={() => setOpenSignatureRespInter(false)}
          style={customModalStyle}
        >
          <button onClick={() => setOpenSignatureRespInter(false)} type="button" className={styles.close}>
            <span aria-hidden="true">&times;</span>
          </button>
          <p className="modal_paragraph">
            { SignatureRespInter ? t('dairy.change_signature') : t('dairy.add_signature')}
          </p>
          <div className={cn(['modal_actions', styles.signatureContainer])}>
            <CanvasSignature
              canvasProps={{
                className: styles.canvas,
              }}
              name="SignatureRespInter"
              trim={() => trimRespInter()}
              clear={() => clearRespInter()}
              setSignature={setSignatureRespInter}
              signature={SignatureRespInter}
              sigCanvas={sigCanvasRespInter}
            />
          </div>
        </Modal>
        <Modal
          isOpen={openSignatureAutre}
          onRequestClose={() => setOpenSignatureAutre(false)}
          style={customModalStyle}
        >
          <button onClick={() => setOpenSignatureAutre(false)} type="button" className={styles.close}>
            <span aria-hidden="true">&times;</span>
          </button>
          <p className="modal_paragraph">
            { SignatureAutre ? t('dairy.change_signature') : t('dairy.add_signature')}
          </p>
          <div className={cn(['modal_actions', styles.signatureContainer])}>
            <CanvasSignature
              canvasProps={{
                className: styles.canvas,
              }}
              name="SignatureRespInter"
              trim={() => trimAutre()}
              clear={() => clearAutre()}
              setSignature={setSignatureAutre}
              signature={SignatureAutre}
              sigCanvas={sigCanvasAutre}
            />
          </div>
        </Modal>
      </div>
    </Layout>
  );
}

export default PermisFeu;
