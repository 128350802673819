/* eslint-disable max-len */
/* eslint no-unsafe-optional-chaining: "error" */
import React from 'react';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  useFormik,
} from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from 'react-query';
import styles from './InternalLifting.module.css';
import { getObservation } from '../../services/registre';
import Layout from '../../components/template/Layout';
import Button from '../../components/atoms/Button/Button';
import cn from '../../utils/cn';
import Textarea from '../../components/atoms/Textarea/Textarea';
import useAppContext from '../../store/useAppContext';

function InternalLifting() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const UrlParams = useParams();
  const [info, setInfo] = React.useState([]);
  const [context] = useAppContext();

  const getObservationQuery = useQuery(['getObservation'], () => getObservation({
    id: UrlParams.id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: () => {
      setInfo(getObservationQuery?.data?.data);
    },
  });

  const validationSchema = Yup.object({
    action: Yup.string().required('share.message_required'),
  });
  const initialValues = {
    action: '',
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      navigate(`/signature/${UrlParams.id}`, { state: values });
    },
  });
  return (
    <Layout
      title="InternalLifting"
      layout="table"
      queryError={
        getObservationQuery?.error
      }
    >
      <header className="header">
        <div className={cn(['row', 'row'])}>
          <h1 className="title">{t('Lifted.title')}</h1>
        </div>
        <span className="title-info">
          {info?.equipement}
          -
          {info?.numeroReport}
        </span>
      </header>
      <div className="title">
        <div>
          <div>
            <form
              onSubmit={formik.handleSubmit}
              className="form shadow"
            >
              <div className="form_group--fullwidth">
                <Textarea
                  name="action"
                  label={t('Lifted.message')}
                  placeholder={t('Lifted.message')}
                  id={styles.message}
                  value={formik.values.action}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows="10"
                  cols="20"
                  required
                />
                {formik.errors.action && formik.touched.action ? (
                  <div className="error">
                    {t(formik.errors.action)}
                  </div>
                ) : null }
              </div>
              <div className="form_footer">
                <div className="form_infos">
                  <small>{t('addInterventions.mandatory_fields')}</small>
                </div>
                <Button
                  type="submit"
                  className="form_submit"
                  label={t('Lifted.signée')}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default InternalLifting;
