/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable max-len */
/* eslint-disable prefer-template */
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  faChevronLeft, faFileArrowDown, faChevronRight, faPlus, faTrashCan, faSortUp, faSortDown, faFileCsv, faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { useQuery, useMutation } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../../components/atoms/Button/Button';
import Search from '../../components/molecules/Search/Search';
import Layout from '../../components/template/Layout';
import styles from './analyses.module.css';
import './analyse.css';
import cn from '../../utils/cn';
import useAppContext from '../../store/useAppContext';
import Pagination from '../../components/molecules/Pagination/Pagination';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import { deleteAnalyse, getAnalysesList, downloadAnalyse } from '../../services/analyses';
import { downloadFileCsv } from '../../utils/downloadFile';
import Permissions from '../../components/molecules/Permissions/Permissions';

function Analyse() {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [info, setInfo] = useState([]);
  const [sort, setSort] = useState('asc');
  const [search, setSearch] = useState('');
  const [context] = useAppContext();
  const [activeIndex, setActiveIndex] = useState(null);
  const [analyseToDelete, setAnalyseToDelete] = useState({
    id: null,
  });
  const [dowloadInfo, setDowloadInfo] = useState([]);
  const [datanull, setDatanull] = useState(false);
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });

  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const AnalysisList = useQuery(
    ['analyses', page, search],
    () => getAnalysesList({
      page: 0,
      search,
      establishmentId: context?.choiceEstablishment?.id,
      bookletId: context?.choiceBooklet,
    }),
    {
      onSuccess: (response) => {
        setData(response?.data?.analyses);
        setInfo(response);
        setDatanull(response?.data?.total === 0);
        setDowloadInfo(response?.data?.analyses);
      },
    },
  );

  const handleModal = (selectedBuildingTemplateToDelete) => {
    setOpenModal(true);
    setAnalyseToDelete(selectedBuildingTemplateToDelete);
  };

  const deleteAnalysMutate = useMutation(deleteAnalyse, {
    onSuccess: () => {
      toast.success(t('analyses.delete_success'));
      setOpenModal(false);
      AnalysisList.refetch();
    },
  });

  const dowloaddoc = (id, name) => {
    window.open(`http://api-ecarnet.dev.zol.fr/analyses/download-document/${id}/${name}`, '_blank');
  };

  const downloadAnalysMutate = useMutation(downloadAnalyse, {
    onSuccess: (response) => {
      dowloaddoc(response.data);
    },
    onError: () => {
      toast.error(t('global.error_500'));
    },
  });
  const handleDelete = () => {
    setOpenModal(false);
    deleteAnalysMutate.mutate(analyseToDelete);
  };
  const couleur = (isPneumophila, quantity) => {
    if (quantity <= '10') {
      return styles.green;
    } else if (quantity < '1000' && isPneumophila === true) {
      return styles.orange;
    } else if (quantity >= '1000' && isPneumophila === true) {
      return styles.red;
    } else {
      return styles.orange;
    }
  };
  const couleurUCF = (quantity) => {
    if (quantity <= '10') {
      return styles.green;
    } else {
      return styles.blue;
    }
  };
  const displayPneumophila = (isPneumophila, quantity) => {
    if (quantity <= 10) {
      return 'non detectées';
    } else if (isPneumophila === true) {
      return 'Pneumophila';
    } else {
      return 'Non Pneumophila';
    }
  };

  const calculeRange = (pages, size) => {
    const start = (pages - 1) * size;
    const end = start + size;
    if (end > data?.length) {
      return { start, end };
    }
    return { start, end: end - 1 };
  };
  const { start, end } = calculeRange(page, 10);
  const dataPage = data?.slice(start, end);

  const sortarray = (arra, fiel) => {
    const array = arra.slice(0, arra.length);
    if (fiel === 'type') {
      if (sort === 'asc') {
        const sorted = array.sort((a, b) => a.type.localeCompare(b.type));
        setData(sorted);
        setSort('desc');
      }
      if (sort === 'desc') {
        const sorted = array.sort((a, b) => b.type.localeCompare(a.type));
        setData(sorted);
        setSort('asc');
      }
      if (sort === '') {
        const sorted = array.sort((a, b) => a.type.localeCompare(b.type));
        setData(sorted);
        setSort('desc');
      }
    }
    if (fiel === 'date') {
      if (sort === 'asc') {
        const sorted = array
          .sort((a, b) => a?.samplingDate.localeCompare(b?.samplingDate));
        setData(sorted);
        setSort('desc');
      }
      if (sort === 'desc') {
        const sorted = array
          .sort((a, b) => b?.samplingDate.localeCompare(a?.samplingDate));
        setData(sorted);
        setSort('asc');
      }
      if (sort === '') {
        setData(dataPage);
        setSort('desc');
      }
    }
  };
  const handleSearch = (e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  };
  const hearder = [
    {
      key: 'id',
      label: t('analyses.id'),
    },
    { key: 'type', label: t('analyses.type') },
    {
      key: 'date',
      label: t('analyses.date'),
    },
    {
      key: 'name',
      label: t('analyses.analysePoint'),
    },
    {
      key: 'quantity',
      label: t('analyses.quantity'),
    },
    {
      key: 'potability',
      label: t('analyses.potability'),
    },
    {
      key: 'pneumophila',
      label: t('analyses.pneumophila'),
    },
  ];

  const dataToExport = dowloadInfo?.map((analyse) => (
    analyse?.analysisPoints?.map((point) => ({
      id: analyse.id,
      type: t(`analyses.${analyse.type}`),
      date: new Date(analyse?.samplingDate).toLocaleDateString(),
      ...(analyse.type === 'legionella'
        ? {
          quantity: point.quantity,
          potability: '',
          pneumophila: displayPneumophila(point.isPneumophila, point.quantity),
        }
        : {
          potability:
            point.valid !== null && point.valid !== true ? 'conforme' : 'non conforme',
          quantity: '',
          pneumophila: '',
        }),
      name: point.name,
      length: analyse?.analysisPoints?.length,
    }))
  ));

  const handleDowload = () => {
    downloadFileCsv(dataToExport, hearder, `${t('analyses.title')}`);
  };
  const handleAccordionClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
      return;
    }
    setActiveIndex(index);
  };

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  const isPneumophila = (analyse) => {
    if (analyse.type === 'legionella') {
      if (analyse.pneumophila !== true && analyse.analysisPoints?.length > 0) {
        return cn([styles.cell, styles.green]);
      } else if (analyse.analysisPoints?.length > 0) {
        return cn([styles.cell, styles.red]);
      }
    }
    return styles.cell;
  };

  return (
    <>
      <Layout
        title={t('analyses.title')}
        layout="table"
        queryError={
          AnalysisList?.error
          || downloadAnalysMutate?.error
          || deleteAnalysMutate?.error
        }
      >
        <header className={styles.headers}>
          <div className={cn([styles.mb20, styles.rows])}>
            <button type="button" className={styles.links} onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className={styles.rows}>
            <h1 className={styles.titles}>
              {t('analyses.title')}
            </h1>
            {Permissions({ permission: 'ANALYSIS_CREATE' }) !== undefined
              ? (
                <Link className={styles.adds} to="/addanalyse">
                  <FontAwesomeIcon icon={faPlus} />
                  <span className={styles.add_labels}>
                    {t('analyses.add')}
                  </span>
                </Link>
              ) : null }
          </div>
          <div className={styles.rows}>
            <button
              className={styles.adds}
              type="button"
              onClick={handleDowload}
            >
              <FontAwesomeIcon icon={faFileCsv} />
              {' '}
              {t('reports.download')}
            </button>
            <Search className={styles.mb20} onChange={handleSearch} />
          </div>
        </header>
        <div>
          <div className="row">
            <div className={cn([styles.wrapper, styles.shadowSm])}>
              <table className={styles.table}>
                <thead className={styles.tableHeader}>
                  <tr>
                    <th
                      className={cn([styles.cell, styles.cellHeader])}
                    >
                      {t('analyses.type')}
                      {' '}
                      {sort === 'asc' ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortarray(data, 'type')}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortarray(data, 'type')}
                        />
                      )}
                    </th>
                    <th className={cn([styles.cell, styles.cellHeader])}>
                      {t('analyses.date')}
                      {' '}
                      {sort === 'asc' ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortarray(data, 'date')}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortarray(data, 'date')}
                        />
                      )}
                    </th>
                    <th className={cn([styles.cell, styles.cellHeaderdoc])}>{t('analyses.document')}</th>
                    <th className={cn([styles.cellAccordion, styles.cellHeader])}>{t('analyses.point')}</th>
                    <th className={cn([styles.cell, styles.cellHeader])}>{}</th>
                  </tr>
                </thead>
                <tbody className={styles.tableBody}>
                  {AnalysisList?.isLoading && (
                    <div className="loader" />
                  )}
                  {!AnalysisList.isLoading && datanull ? (
                    <div>
                      <p>{t('global.no_results_found')}</p>
                    </div>
                  ) : null}
                  {dataPage?.map((analyse) => (
                    <tr key={analyse.id}>
                      <td className={isPneumophila(analyse)}>{t(`analyses.${analyse.type}`)}</td>
                      <td className={styles.cell}>{new Date(analyse?.samplingDate).toLocaleDateString()}</td>
                      <td className={styles.celldoc}>
                        {Permissions({ permission: 'ANALYSIS_READ' }) !== undefined
                          || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
                          ? (
                            <Button
                              label={(
                                <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                              )}
                              type="button"
                              className="action edit"
                              title={t('trainings.download')}
                              onClick={() => dowloaddoc(analyse.id, analyse.reportFileName)}
                            />
                          ) : null }
                      </td>
                      <td className={styles.cellaccordion}>
                        <div>
                          {analyse.type === 'legionella' && analyse.analysisPoints?.length > 0 ? (
                            <div className={cn([styles.accordions, 'accordion'])} id="accordionExample">
                              <div className="accordion-item">
                                <h2 className="accordion-header" id={`heading${analyse.id}`}>
                                  <button
                                    className={cn([styles.accordion_button, 'accordion-button'])}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${analyse.id}`}
                                    aria-expanded="false"
                                    aria-controls={`collapse${analyse.id}`}
                                    onClick={() => handleAccordionClick(analyse.id)}
                                  >
                                    {`DÉTAIL (${analyse.analysisPoints?.length}) `}
                                    <FontAwesomeIcon icon={faCircleInfo} size="lg" />
                                  </button>
                                </h2>
                                <tbody className={styles.tableBodyPneumophila}>
                                  {analyse.analysisPoints?.map((point) => (
                                    <div
                                      id={`collapse${analyse.id}`}
                                      className={cn([styles.accordion, `accordion-collapse collapse ${activeIndex === analyse.id ? 'show' : ''}`])}
                                      aria-labelledby={`heading${analyse.id}`}
                                      data-bs-parent="#accordionExample"
                                    >
                                      <th className={styles.name}>{point.name}</th>
                                      <td className={cn([couleurUCF(point.quantity)])}>
                                        <span>
                                          {point.quantity < 10 ? (
                                            <span>
                                              &lt; 10 UCF/L
                                            </span>
                                          ) : (
                                            <span>
                                              {`${point.quantity} UCF/L`}
                                            </span>
                                          )}
                                        </span>
                                      </td>
                                      <td
                                        className={cn([couleur(point.isPneumophila, point.quantity)])}
                                      >
                                        {displayPneumophila(point.isPneumophila, point.quantity)}
                                      </td>
                                    </div>
                                  ))}
                                </tbody>
                              </div>
                            </div>
                          ) : analyse.analysisPoints?.length !== 0 ? (
                            <div className={cn([styles.accordions, 'accordion'])} id="accordionExample">
                              <div className="accordion-item">
                                <h2 className="accordion-header" id={`heading${analyse.id}`}>
                                  <button
                                    className={cn([styles.accordion_button, 'accordion-button'])}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${analyse.id}`}
                                    aria-expanded="false"
                                    aria-controls={`collapse${analyse.id}`}
                                    onClick={() => handleAccordionClick(analyse.id)}
                                  >
                                    {`DÉTAIL (${analyse.analysisPoints?.length}) `}
                                    <FontAwesomeIcon icon={faCircleInfo} size="lg" />
                                  </button>
                                </h2>
                                <tbody className={styles.tableBodyConforme}>
                                  {analyse.analysisPoints?.map((point) => (
                                    <tr
                                      id={`collapse${analyse.id}`}
                                      className={cn([styles.accordion, `accordion-collapse collapse ${activeIndex === analyse.id ? 'show' : ''}`])}
                                      aria-labelledby={`heading${analyse.id}`}
                                      data-bs-parent="#accordionExample"
                                      point={point.id}
                                    >
                                      <th className={styles.name}>{point.name}</th>
                                      <td className={styles.conforme}>
                                        <span>
                                          {point.valid !== null && point.valid === true ? (
                                            <div className={styles.green}>
                                              conforme
                                            </div>
                                          ) : (
                                            <div className={styles.red}>
                                              non conforme
                                            </div>
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <p>{t('global.no_results_found')}</p>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className={cn([styles.cell, styles.center])}>
                        {Permissions({ permission: 'ANALYSIS_UPDATE' }) !== undefined
                          ? (
                            <button
                              type="button"
                              className="action suppr"
                              title={t('analyses.suppr')}
                              onClick={() => handleModal(analyse.id)}
                            >
                              <FontAwesomeIcon icon={faTrashCan} />
                            </button>
                          ) : null }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.pagination}>
              <Pagination
                className={styles.pagination}
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={data?.length < 10 ? 0 : info?.data?.count || 0}
                currentPage={page}
                pageSize={10}
                numberElementsDisplayed={data?.length < 10 ? 0 : 10}
              />
            </div>
          </div>
          <footer className="footer">
            <button type="button" className="link" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>retour au tableau de bord</span>
            </button>
          </footer>
        </div>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">
          {t('global.modal_content')}
        </p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </>
  );
}
export default Analyse;
