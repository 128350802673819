/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
// components
import Picto from '../../../components/atoms/Picto/Picto';
// services
import { countEstablishment } from '../../../services/structures';
import { countUser } from '../../../services/users';
import useAppContext from '../../../store/useAppContext';
import Card from '../../../components/atoms/Card/Card';
import logoSanitaire from '../../../assets/img/logos/e-carnet-sanitaire-logo.png';
import logoSecurite from '../../../assets/img/logos/e-carnet-securite-logo.png';
import logoVehicule from '../../../assets/img/logos/e-carnet-vehicule-logo.png';
import bckgSecurite from '../../../assets/img/background_securite.jpg';
import bckgSanitaire from '../../../assets/img/background_sanitaire.jpg';
import bckgVehicule from '../../../assets/img/background_vehicule.png';
// styles
import styles from './AdminDashboardGrid.module.css';
// utils & misc
import cn from '../../../utils/cn';

export const choicesBooklet = [
  {
    id: 2,
    value: 2,
    key: 'securite',
    label: 'Sécurité',
    title: 'add_building_template.booklet_security',
    backgroundImg: bckgSecurite,
    logoChoice: logoSecurite,
  },
  {
    id: 1,
    value: 1,
    key: 'sanitaire',
    label: 'Sanitaire',
    title: 'add_building_template.booklet_sanitary',
    backgroundImg: bckgSanitaire,
    logoChoice: logoSanitaire,
  },
  {
    id: 3,
    value: 3,
    key: 'vehicule',
    label: 'Vehicule',
    title: 'add_building_template.booklet_vehicule',
    backgroundImg: bckgVehicule,
    logoChoice: logoVehicule,
  },
];

function AdminDashboardGrid() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [context, dispatch] = useAppContext();

  // API Calls
  const getCountEstablishmentsQuery = useQuery(['count-establishments'], () => countEstablishment({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));
  const getCountUsersQuery = useQuery(['count-users'], () => countUser({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  // choice booklet
  useEffect(() => {
    dispatch({ type: 'CHOICE_BOOKLET', payload: null });
    if (context?.choiceEstablishment !== null) {
      dispatch({ type: 'CHOICE_ESTABLISHMENT', payload: null });
    }
  }, []);

  const handleChoiceBooklet = (id) => {
    dispatch({ type: 'CHOICE_BOOKLET', payload: id });
    navigate('/choix-etablissement');
  };

  const navigateTo = (id) => {
    let urlNavigate = '';
    if (id === 1) {
      urlNavigate = '/sanitaire/choix-etablissement';
    }
    if (id === 2) {
      urlNavigate = '/securite/choix-etablissement';
    }
    if (id === 3) {
      urlNavigate = '/vehicule/choix-etablissement';
    }
    return urlNavigate;
  };

  return (
    <>
      <section className={styles.adminGrid}>
        {getCountEstablishmentsQuery.isLoading || getCountUsersQuery.isLoading ? (
          <div className="loader" />
        ) : (
          <>
            <Card className={styles.card} onClick={() => navigate('/etablissements')}>
              <div>
                <p className={cn([styles.title, styles.titleNumber])}>{t('navigation.establishments')}</p>
                <p className={cn([styles.title, styles.number])}>{getCountEstablishmentsQuery?.data?.data?.establishmentCount}</p>
              </div>
              <div className={styles.pictoCard}>
                <Picto name="etablissementsRoundedBlue" width="90" height="90" />
              </div>
            </Card>
            <Card className={styles.card} onClick={() => navigate('/users')}>
              <div>
                <p className={cn([styles.title, styles.titleNumber])}>{t('navigation.users')}</p>
                <p className={cn([styles.title, styles.number])}>{getCountUsersQuery?.data?.data?.userCount}</p>
              </div>
              <div className={styles.pictoCard}>
                <Picto name="users" width="90" height="90" />
              </div>
            </Card>
            <Card className={styles.card} onClick={() => navigate('/somewhere')} disabled>
              <div>
                <p className={cn([styles.title, styles.titleNumber])}>{t('global.search_motor')}</p>
              </div>
              <div>
                <FontAwesomeIcon className={styles.fileDownload} icon={faFileArrowDown} />
              </div>
            </Card>
            <Card className={styles.card} onClick={() => navigate('/notificationlist/admin')}>
              <div>
                <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.send_grouped')}</p>
              </div>
              <div className={styles.pictoCard}>
                <Picto name="contact" width="90" height="90" />
              </div>
            </Card>
            <Card className={styles.card} onClick={() => navigate('/depot-documents')}>
              <div>
                <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.document_deposit')}</p>
              </div>
              <div className={styles.pictoCard}>
                <Picto name="documentaire" width="90" height="90" />
              </div>
            </Card>
            <Card className={styles.card} onClick={() => navigate('/indicateur')}>
              <div>
                <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.indicator')}</p>
              </div>
              <div className={styles.pictoCard}>
                <Picto name="synthese" width="90" height="90" />
              </div>
            </Card>
            <Card className={styles.card} onClick={() => navigate('/contacts')}>
              <div>
                <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.messages')}</p>
              </div>
              <div className={styles.pictoCard}>
                <Picto name="contact" width="90" height="90" />
              </div>
            </Card>
            <Card className={styles.card} onClick={() => navigate('/vosvehicules')}>
              <div>
                <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.voiture')}</p>
              </div>
              <div className={styles.pictoCard}>
                <Picto name="vehiculebleu" width="90" height="90" />
              </div>
            </Card>
          </>
        )}
      </section>
      <br />
      <header className={cn(['header', styles.headerChoice, 'shadow-md'])}>
        <h1 className="title">
          {t('choice_booklet.title_page')}
        </h1>
      </header>
      <section className={styles.sectionChoice}>
        {choicesBooklet.map((choice) => (
          <Link
            onClick={() => handleChoiceBooklet(choice.id)}
            className={cn(['shadow-md', styles.choiceWrapper])}
            key={choice.key}
            to={navigateTo(choice.id)}
          >
            <div className={styles.backgroundBooklet} style={{ backgroundImage: `url(${choice.backgroundImg})` }} />
            <div className={styles.choiceBooklet}>
              <img src={choice.logoChoice} alt="" />
            </div>
          </Link>
        ))}
      </section>
    </>
  );
}

export default AdminDashboardGrid;
