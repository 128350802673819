import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ControlType from '../../template/ControlType/ControlType';
import BalneoForm from '../InputTypes/Balneo/Balneo';
import Button from '../../atoms/Button/Button';
import styles from './balneos.module.css';
import cn from '../../../utils/cn';

function Balneo({ list, submit }) {
  const { t } = useTranslation();
  const [button, setButton] = useState(false);

  const columns = [
    {
      label: '',
      width: 160,
    },
    {
      label: '',
      width: 300,
    },
  ];

  const initialList = list.map((input) => ({ ...input, isDirty: false }));
  const [inputsList, setInputsList] = useState(initialList);

  const handleChange = (input) => {
    const resultsChanged = inputsList.map((inputState) => {
      if (inputState.id === input.id) return ({ ...input, isDirty: true });
      return inputState;
    });
    setInputsList(resultsChanged);
  };

  const handleValuesSubmit = () => {
    const valuesToSubmit = inputsList
      .filter((input) => input.isDirty)
      .map((input) => ({
        inputId: input?.id,
        date: input?.date,
        time: input?.time,
        temperature: input?.temperature,
        chlorineFree: input?.chloreLibre,
        chlorineTotal: input?.chloreTotal,
        chroleCombine: input?.chroleCombine,
        ph: input?.ph,
        waterTroubleType: input?.transparence,
        numberOfSwimmers: input?.nombreBaigneur,
        curativeDisinfection: input?.desinfectionCurative,
        indexValue: input?.releveCompteur,
        apportCalcule: input?.apportCalcule,
        comment: input?.commentaire,
        alertComment: input?.alertComment,
        alert: input?.alert,
        alertHelp: input?.alertHelp,
        alertCommentTemp: input?.alertCommentTemp,
        alertTemp: input?.alertTemp,
        alertHelpTemp: input?.alertHelpTemp,
      }));
    submit(valuesToSubmit);
    setInputsList(initialList);
  };
  return (
    <ControlType columns={columns} onSubmit={handleValuesSubmit}>
      {!button ? (
        <Button
          type="button"
          className={cn(['form_submit', styles.buttons])}
          label={`${t('inputs.balneo')} (${inputsList.length})`}
          onClick={() => setButton(true)}
        />
      ) : (
        inputsList.map((input) => (
          <>
            <BalneoForm
              key={`input-${input.id}`}
              label={input.label}
              value={input}
              onChange={handleChange}
            />
            {/* <div className={styles.footer}>
              <Button
                type="submit"
                className={styles.button}
                label={t('inputs.balneosave')}
                onClick={() => handleValuesSubmit(input)}
              />
            </div> */}
          </>
        ))
      )}
    </ControlType>
  );
}
Balneo.propTypes = {
  list: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
};

export default Balneo;
