/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  faChevronLeft,
  faChevronRight,
  faEdit,
  faTrashCan,
  faPlus,
  faFileCsv,
  faFileArrowDown,
} from '@fortawesome/free-solid-svg-icons';
// import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Search from '../../components/molecules/Search/Search';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import Pagination from '../../components/molecules/Pagination';
import styles from './ContractsVehicule.module.css';
import {
  deleteContractVehicle,
  getContractDocumentVehicle,
  getContractsVehicleList,
} from '../../services/contracts';
import { getBuildingsList } from '../../services/structures';
// import { downloadFileCsv } from '../../utils/downloadFile';
import useAppContext from '../../store/useAppContext';
import Toggle from '../../components/atoms/Toggle/Toggle';

/* eslint-disable react/prop-types */

function ContractsVehicule() {
  // const [supplierToDelete, setsupplierToDelete] = useState('');
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [context] = useAppContext();
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState('');
  const [vehiculelist, setVehiculelist] = useState('');
  const [completed, setCompleted] = useState(undefined);
  const [checked, setChecked] = useState(false);
  const [actif, setActif] = useState([]);
  const [inactif, setInactif] = useState([]);
  const [buildingId, setBuildingId] = useState();
  const [vehicleId] = useState(urlParams.id);

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const TriEnable = (info) => {
    const tableau = [];
    const tableau2 = [];
    info?.map((data) => {
      if (data.enabled === true) {
        tableau.push(data);
      } else {
        tableau2.push(data);
      }
      return data;
    });
    setActif(tableau);
    setInactif(tableau2);
  };

  const getBuildingsQuery = useQuery('buildings', () => getBuildingsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      if (data?.data?.buildings?.length > 0) setBuildingId(data?.data?.buildings[0]?.id);
    },
  });

  const VehiculeQueryList = useQuery(['contractlistvehicle', page, search, buildingId], () => getContractsVehicleList({
    buildingId,
    bookletId: context?.choiceBooklet,
    search,
    vehicleId,
    page: 0,
  }), {
    onSuccess: (data) => {
      setVehiculelist(data?.data?.contractsVehicle);
      TriEnable(data?.data?.contractsVehicle);
    },
  });

  const deleteContrac = useMutation(deleteContractVehicle, {
    onSuccess: () => {
      toast.success(t('contract.delete_contract'));
      setOpenModal(false);
      VehiculeQueryList.refetch();
    },
    onError: () => {
      toast.error(t('contract.deleteContractError'));
    },
  });
  const handleDelete = (id) => {
    setOpenModal(false);
    deleteContrac.mutate(id);
  };

  const dowloaddoc = (id, data) => {
    window.open(`http://api-ecarnet.dev.zol.fr/vehiclesContracts/download-document/${id}/${data}`, '_blank');
  };

  const getContractDocuments = useMutation(getContractDocumentVehicle, {
    onSuccess: (data) => {
      dowloaddoc(data.data);
    },
  });

  function addYears(date, years) {
    const d = new Date(date);
    d.setFullYear(d.getFullYear() + years);
    return d;
  }
  // const result2 = addYears(date, 5);

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('contract.loc')}`,
        accessor: 'societyName',
        Cell: ({ row }) => {
          if (row.original.leasingCompany === null) {
            return '-';
          }
          return (
            <div className={styles.actions}>
              {row.original.leasingCompany}
            </div>
          );
        },
      },
      {
        Header: `${t('vehicule.brand')}`,
        accessor: 'brand',
        Cell: ({ row }) => (
          <div className={styles.actions}>
            {row.original.vehicle[0].brand}
          </div>
        ),
      },
      {
        Header: `${t('vehicule.model')}`,
        accessor: 'model',
        Cell: ({ row }) => (
          <div className={styles.actions}>
            {row.original.vehicle[0].model}
          </div>
        ),
      },
      {
        Header: `${t('vehicule.immatform')}`,
        accessor: 'licensePlate',
        Cell: ({ row }) => (
          <div className={styles.actions}>
            {row.original.vehicle[0].licensePlate}
          </div>
        ),
      },
      {
        Header: `${t('contract.dateStart')}`,
        accessor: 'startdate',
        Cell: ({ row }) => {
          if (row.original.firstRegistrationDate === null) {
            return '-';
          }
          return (
            <div className={styles.actions}>
              {new Date(row.original.effectiveDate).toLocaleDateString()}
            </div>
          );
        },
      },
      {
        Header: `${t('contract.dateEnd')}`,
        accessor: 'enddate',
        Cell: ({ row }) => {
          if (row.original.firstRegistrationDate === null) {
            return '-';
          }
          return (
            <div className={styles.actions}>
              {addYears(row.original.endDate, 4).toLocaleDateString()}
            </div>
          );
        },
      },
      {
        Header: `${t('contract.km_inclus')}`,
        accessor: 'KMinclus',
        Cell: ({ row }) => {
          if (row.original.firstRegistrationDate === null) {
            return '-';
          }
          return (
            <div className={styles.actions}>
              {row.original.milesIncluded}
            </div>
          );
        },
      },
      {
        Header: `${t('contract.consommation')}`,
        accessor: 'consommation',
        Cell: ({ row }) => (
          <div style={{ color: 'green' }}>
            {row.original.milesDelta}
          </div>
        ),
      },
      {
        Header: `${t('contract.documentFileName')}`,
        accessor: 'documentFileName',
        Cell: ({ row }) => {
          if (row.original.documentFileName === null) {
            return '-';
          }
          return (
            <div>
              <Button
                label={(
                  <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                )}
                type="button"
                className="action edit"
                title={t('contract.download')}
                onClick={() => dowloaddoc(row.original.id, row.original.documentFileName)}
              />
            </div>
          );
        },
      },
      {
        Header: `${t('contract.actions')}`,
        accessor: 'id',
        Cell: ({ row }) => {
          if (row.original.framework === true) {
            return ' ';
          }
          return (
            <div className={styles.button}>
              <Button
                label={<FontAwesomeIcon icon={faEdit} />}
                type="button"
                className="action edit"
                title={t('contract.edit')}
                onClick={() => navigate(`/contractsVehicule/edit/${row.original.id}`)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button
                type="button"
                className="action suppr"
                label={<FontAwesomeIcon icon={faTrashCan} />}
                title={t('contract.suppr')}
                onClick={() => handleDelete(row.original.id)}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const handleChange = () => {
    if (completed === undefined) {
      setCompleted(false);
      setChecked(true);
    }
    if (completed === false) {
      setCompleted(undefined);
      setChecked(false);
    }
  };

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  return (
    <>
      <Layout
        title={t('contract.title')}
        layout="table"
        table={columns}
        queryError={
          VehiculeQueryList.error
          || deleteContrac.error
          || getContractDocuments.error
          // || TypeVehicule.error
          // || SearchTypeVehicule.error
        }
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('contract.title')}</h1>
            <Link className="add" to="/contractsVehicule/add">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">
                {t('contract.add')}
              </span>
            </Link>
          </div>
          <div className="row">
            <button
              className="add"
              type="button"
              // onClick={handleDowload}
            >
              <FontAwesomeIcon icon={faFileCsv} />
              {' '}
              {t('reports.download')}
            </button>
            <Search
              className="row mb-20"
              onChange={handleSearch}
            />
          </div>
          <div className={styles.toggle}>
            <Toggle
              id="to-complete"
              label={t('contract.contract_inactif')}
              checked={checked}
              value={completed}
              onChange={handleChange}
            />
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={vehiculelist?.isLoading}
            data={checked === false ? actif : inactif || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={checked === false ? actif.length : inactif.lenght || 0}
                currentPage={page}
                haveToPaginate
                pageSize={10}
              />
          )}
          />
        </div>
        <footer>
          <div className="row mt-20">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyle}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            // onClick={() => handleDelete(supplierToDelete)}
          />
        </div>
      </Modal>
    </>
  );
}
export default ContractsVehicule;
