/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import styles from './Input.module.css';
import cn from '../../../utils/cn';

function Input({
  id,
  type,
  name,
  label,
  labelHidden,
  placeholder,
  isMulti,
  value,
  onChange,
  onBlur,
  disabled,
  required,
  step,
  defaultValue,
  ...props
}) {
  // const [inputValue, setInputValue] = useState('');

  // useEffect(() => {
  //   if (type === 'date' && !value && !defaultValue) {
  //     const today = new Date().toISOString().split('T')[0];
  //     setInputValue(today);
  //   } else {
  //     setInputValue(value);
  //   }
  // }, [type, value]);

  return (
    <>
      <label className={cn([styles.label, labelHidden ? 'visually-hidden' : ''])} htmlFor={id}>
        {label}
        {' '}
        {required ? '*' : ''}
      </label>
      <input
        className={styles.input}
        id={id}
        type={type}
        name={name}
        placeholder={placeholder || null}
        value={value}
        onChange={onChange}
        autoComplete="off"
        multiple={isMulti}
        onBlur={onBlur && onBlur}
        disabled={disabled || ''}
        required={required || null}
        step={step}
        {...props}
      />
    </>
  );
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelHidden: PropTypes.bool,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onBlur: PropTypes.func,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function emptyFunc() {}
Input.defaultProps = {
  onChange: noop,
  labelHidden: false,
  label: '',
  placeholder: undefined,
  value: undefined,
  onBlur: emptyFunc,
  step: null,
  isMulti: false,
  required: false,
  disabled: false,
  defaultValue: false,
};

export default Input;
