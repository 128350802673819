/* eslint-disable react/prop-types */

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import {
  faEdit, faTrashCan, faPlus, faEye,
} from '@fortawesome/free-solid-svg-icons';

// Components
import Table from '../../../components/molecules/Table/Table';

// services
import { deleteBuilding, getBuildingsList } from '../../../services/structures';

// styles
import styles from './BuildingsList.module.css';
import useAppContext from '../../../store/useAppContext';

// TO DO : REFACTO TO MAKE IT GENERIC TO ALL OTHERS MODALS
const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function BuildingsList({ establishmentId, isViewing }) {
  const { t } = useTranslation();
  const [context] = useAppContext();

  const [openModal, setOpenModal] = useState(false);
  const [buildingToDelete, setBuildingToDelete] = useState({
    id: null,
  });

  // API Calls
  const getBuildingsListQuery = useQuery(
    ['buildings'],
    () => getBuildingsList({
      establishmentId,
      bookletId: context?.choiceBooklet,
    }),
  );

  const deleteEstablishmentQuery = useMutation(deleteBuilding, {
    onSuccess:
      () => {
        getBuildingsListQuery.refetch();
      },
  });

  const handleModalToDelete = (item) => {
    setOpenModal(true);
    setBuildingToDelete(item);
  };

  const handleDelete = () => {
    setOpenModal(false);
    deleteEstablishmentQuery.mutate(buildingToDelete.id);
  };

  const columns = useMemo(
    () => [
      {
        Header: `${t('buildings.id')}`,
        accessor: 'id',
      },
      {
        Header: `${t('buildings.name')}`,
        accessor: 'name',
        Cell: ({ row: { original: { name } } }) => (
          <span className="capitalize">
            {name.toUpperCase()}
          </span>
        ),
      },
      {
        Header: `${t('buildings.booklet')}`,
        accessor: 'bookletId',
        Cell: ({ row: { original: { bookletId } } }) => (
          `${bookletId === 1 ? t('buildings.sanitaire') : ''} 
          ${bookletId === 2 ? t('buildings.securite') : ''}
          ${bookletId === 3 ? t('buildings.vehicule') : ''}`
        ),
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            <Link
              className="action"
              to={`/etablissements/${establishmentId}/batiments/view/${id}`}
              title={t('buildings.view')}
            >
              <FontAwesomeIcon icon={faEye} />
            </Link>
            <Link
              className="action"
              to={`/etablissements/${establishmentId}/batiments/edit/${id}`}
              title={t('buildings.edit')}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <button
              type="button"
              className="action"
              title={t('buildings.suppr')}
              onClick={() => handleModalToDelete({ id })}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <div className={styles.wrapper}>
      <div>
        <header>
          <div className="row">
            <h2 className="title">
              {t('buildings.titlepage')}
            </h2>
            {!isViewing ? (
              <Link className="add" to={`/etablissements/${establishmentId}/batiments/add`}>
                <FontAwesomeIcon icon={faPlus} />
                <span className="add_label">{t('buildings.add')}</span>
              </Link>
            ) : null}
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={getBuildingsListQuery.isLoading}
            data={getBuildingsListQuery?.data?.data?.buildings || []}
            noResultsLabel={t('buildings.no_results')}
          />
        </div>
      </div>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <button
            type="button"
            className="modal_button"
            onClick={() => setOpenModal(false)}
          >
            {t('global.modal_dismiss')}
          </button>
          <button
            type="button"
            className="modal_button modal_button--success"
            onClick={handleDelete}
          >
            {t('global.modal_validate')}
          </button>
        </div>
      </Modal>
    </div>
  );
}

BuildingsList.propTypes = {
  establishmentId: PropTypes.number.isRequired,
  isViewing: PropTypes.bool,
};

BuildingsList.defaultProps = {
  isViewing: false,
};

export default BuildingsList;
