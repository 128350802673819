/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';

// hooks
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// Components
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import InputsTypeSwitcher from '../../components/molecules/InputType/InputsTypeSwitcher';

// services
import { getInput, putInput } from '../../services/inputs';
import { getUser } from '../../services/users';
import { getIntervention } from '../../services/interventions';
// styles
import styles from './EditHistoricInput.module.css';

// utils
import { formatDateToInput, formatDateToUser } from '../../utils/dates';
import cn from '../../utils/cn';
import useAppContext from '../../store/useAppContext';

function EditHistoricInput() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [input, setInput] = useState({});
  const [user, setUser] = useState([]);
  // const [updateinfo, setUpdateinfo] = useState([]);
  const [context] = useAppContext();

  const goBackUrl = (message) => {
    navigate(-1);
    toast.success(message);
  };
  const formik = useFormik({
    initialValues: {
      inputId: input?.id,
      interventionId: input?.interventionId,
      date_creation_input: formatDateToUser(input?.inputDate),
      date_edition_input: input?.updateDate,
      // intervenant_creation: input?.completedById,
      // intervenant_edition: `${updateinfo?.lastName} ${updateinfo?.firstName}`,
    },
    validationSchema: undefined,
  });

  const getInputQuery = useQuery('input', () => getInput(urlParams?.id), {
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    enabled: !!urlParams?.id,
    onSuccess: (data) => {
      setInput(data?.data);
      formik.setValues(data?.data);
    },
  });

  const getUsers = useQuery('user', () => getUser(input?.completedById), {
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    enabled: !!input?.completedById,
    onSuccess: (data) => {
      setUser(data?.data);
    },
  });

  // const getUsersUpdate = useQuery('user', () => getUser(input?.updatedById), {
  //   bookletId: context?.choiceBooklet,
  //   establishmentId: context?.choiceEstablishment?.id,
  //   enabled: !!input?.updatedById,
  //   onSuccess: (data) => {
  //     setUpdateinfo(data?.data);
  //   },
  // });

  const getInterventionQuery = useQuery('intervention', () => getIntervention(input?.interventionId), {
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    enabled: !!input?.interventionId,
  });

  const postInputsMutation = useMutation(putInput, {
    onSuccess: () => {
      goBackUrl(t('edit_inputs_historic.saved'));
    },
    onError: () => {
      toast.error(t('edit_inputs_historic.error'));
    },
  });

  const objectForInputSwitcher = input ? {
    inputType: input?.inputType,
    inputsTypeList: [input],
  } : null;

  const handleSubmit = (valueToSubmit) => {
    const valueFormated = {
      ...objectForInputSwitcher.inputsTypeList[0],
      ...valueToSubmit[0],
      id: urlParams?.id,
      inputDate: formatDateToInput(formik.values.inputDate),
      updateDate: formatDateToInput(formik.values.updateDate),
    };
    postInputsMutation.mutate(valueFormated);
  };

  return (
    <Layout
      title={t('inputs_historic.title_page')}
      layout="table"
      queryError={
        getInputQuery?.error
        || getUsers?.error
        || getInterventionQuery?.error
        || postInputsMutation?.error
      }
    >
      <header>
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('edit_inputs_historic.back_to_inputs')}</span>
          </button>
        </div>
        <h1 className="title">
          {t('edit_inputs_historic.title_edit_page')}
        </h1>
      </header>
      {getInputQuery.isLoading && getUsers.isLoading && getInterventionQuery.isLoading && input?.id ? (
        <div className="loader" />
      ) : (
        <section className={cn(['form shadow-sm'])}>
          <div className="form_group--fullwidth">
            <Input
              id="inputId"
              type="text"
              name="inputId"
              label={t('edit_inputs_historic.inputName')}
              value={input?.title || '-'}
              disabled
            />
          </div>
          <div className={cn(['form_group', styles.interventionId])}>
            <Input
              id="interventionId"
              type="text"
              name="interventionId"
              label={t('edit_inputs_historic.interventionId')}
              value={input?.interventionId || '-'}
              disabled
            />
          </div>
          <div className="form_group">
            <Input
              id="intervener_in_creation"
              type="text"
              name="intervener_in_creation"
              label={t('edit_inputs_historic.intervener_in_creation')}
              value={input?.completedById !== null ? `${user?.lastName} ${user?.firstName}` : '-'}
              disabled
            />
          </div>
          <div className="form_group">
            <Input
              id="date_creation_input"
              type="date"
              name="date_edition_input"
              label={t('edit_inputs_historic.date_creation_input')}
              value={formatDateToInput(formik.values.inputDate)}
              onChange={(event) => formik.setFieldValue('inputDate', event.target.value)}
              min="2000-01-01"
              max="2099-12-31"
              required
            />
          </div>
          <div className="form_group">
            {' '}
          </div>
          <div className="form_group">
            <Input
              id="date_edition_input"
              type="date"
              name="date_edition_input"
              label={t('edit_inputs_historic.date_edition_input')}
              value={formatDateToInput(formik.values.updateDate) || '-'}
              disabled
            />
          </div>
          <div className={styles.formFullWidth}>
            <InputsTypeSwitcher inputControl={objectForInputSwitcher} submit={handleSubmit} />
          </div>
        </section>
      )}
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('edit_inputs_historic.back_to_inputs')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default EditHistoricInput;
