import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import Input from '../../../atoms/Input/Input';
import Textarea from '../../../atoms/Textarea/Textarea';
import Radio from '../../../atoms/Radio/Radio';
import Button from '../../../atoms/Button/Button';

import styles from './Balneo.module.css';
import cn from '../../../../utils/cn';

function BalneoForm({ value, onChange, id }) {
  const { t } = useTranslation();
  const [pop, setPop] = useState();
  const [popTemp, setPopTemp] = useState();
  const [remarque, setRemarque] = useState('');
  const [remarqueTemp, setRemarqueTemp] = useState('');
  const [remarqueFinal, setRemarqueFinal] = useState('');
  const [remarqueFinalTemp, setRemarqueFinalTemp] = useState('');
  const EffectPop = useRef();
  const EffectPopTemp = useRef();
  const ColorRemarque = useRef();
  const ColorRemarqueTemp = useRef();
  const [RemarqueNull, setRemarqueNull] = useState();
  const [RemarqueNullTemp, setRemarqueNullTemp] = useState();
  const [titlePop, setTitlePop] = useState();
  const [titlePopTemp, setTitlePopTemp] = useState();
  const [Nb, setNb] = useState();
  const [NbTemp, setNbTemp] = useState();

  const PopUp = (nb, min) => {
    if (min !== null) {
      setPop(true);
      setRemarqueNull();
      const title = `Attention, le relevé saisi [${nb}] est inférieur à l’ancien [${min}]`;
      setTitlePop(title);
      setNb(nb);
    }
  };
  const PopEffect = () => {
    EffectPop.current.style.transform = 'scale(1.01)';
    setTimeout(() => { EffectPop.current.style.transform = 'scale(1)'; }, 75);
  };
  const ChangeValue = () => {
    setRemarqueFinal('');
    setPop(false);
    setRemarque('');
    document.getElementById(`releve-compteur-${id}`).focus();
  };
  const PopValid = (params) => {
    if (remarque !== '') {
      setRemarqueFinal(remarque);
      setPop(false);
    } else {
      EffectPop.current.style.transform = 'scale(1.01)';
      setTimeout(() => { EffectPop.current.style.transform = 'scale(1)'; }, 75);
      setRemarqueNull(true);
    }
    if (params === 'valid') {
      ColorRemarque.current.style.color = 'green';
    } else if (params === 'aide') {
      ColorRemarque.current.style.color = 'orange';
    }
  };

  const PopUpTemp = (nb, min, max) => {
    setPopTemp(true);
    setRemarqueNullTemp();
    const title = `La température saisie [${nb}] n'est pas dans la plage de valeurs correctes [entre ${min} et ${max}]`;
    setTitlePopTemp(title);
    setNbTemp(nb);
  };
  const PopEffectTemp = () => {
    EffectPopTemp.current.style.transform = 'scale(1.01)';
    setTimeout(() => { EffectPopTemp.current.style.transform = 'scale(1)'; }, 75);
  };
  const ChangeValueTemp = () => {
    setRemarqueFinalTemp();
    setPopTemp(false);
    setRemarqueTemp('');
    document.getElementById(`temperature-${id}`).focus();
  };
  const PopValidTemp = (params) => {
    if (remarqueTemp !== '') {
      setRemarqueFinalTemp(remarqueTemp);
      setPopTemp(false);
    } else {
      EffectPopTemp.current.style.transform = 'scale(1.01)';
      setTimeout(() => { EffectPopTemp.current.style.transform = 'scale(1)'; }, 75);
      setRemarqueNullTemp(true);
    }
    if (params === 'valid') {
      ColorRemarqueTemp.current.style.color = 'green';
    } else if (params === 'aide') {
      ColorRemarqueTemp.current.style.color = 'orange';
    }
  };

  return (
    <div>
      <div className={styles.inputfrom} style={{ borderBottom: remarqueFinal && 'none' }}>
        {/* <div className="inputForm_control inputForm_label">{value.title}</div> */}
        <div className={styles.title}>{value.title}</div>
        <br />
        <div className={styles.fomlines}>
          <div className={styles.form}>
            <div className={cn(['inputForm_control inputForm_number', styles.baneoInput])}>
              <div className="inputForm_control inputForm_label">{t('balneo.date')}</div>
              <Input
                type="date"
                labelHidden
                value={value.date}
                onChange={(e) => onChange({ ...value, date: e.target.value })}
                min="2000-01-01"
                max="2099-12-31"
              />
            </div>
            <div className={cn(['inputForm_control inputForm_number', styles.baneoInput])}>
              <div className="inputForm_control inputForm_label">{t('balneo.time')}</div>
              <Input
                type="time"
                labelHidden
                value={value.time}
                onChange={(e) => onChange({ ...value, time: e.target.value })}
                min="0"
                max="100"
              />
            </div>
            <div className={cn(['inputForm_control inputForm_number', styles.baneoInput])}>
              <div className="inputForm_control inputForm_label">{t('balneo.temperature')}</div>
              <Input
                id={`temperature-${id}`}
                type="number"
                labelHidden
                step={0.1}
                value={value.temperature}
                onChange={(e) => onChange({ ...value, temperature: parseInt(e.target.value, 10) })}
                onBlur={(e) => {
                  if ((e.target.value < value.paramLowLimit || e.target.value > value.paramHighLimit)
                  && e.target.value !== '' && value.paramLowLimit !== null && value.paramHighLimit !== null) {
                    PopUpTemp(e.target.value, value.paramLowLimit, value.paramHighLimit);
                  }
                }}
                min="0"
                max="100"
              />
              {!remarqueFinalTemp ? (
                <div className={styles.remarque} style={{ display: value.alertComment ? 'flex' : 'none' }}>
                  <div className="inputForm_control inputForm_label">
                    {'Commentaire de l\'alerte'}
                  </div>
                  <div className={styles.remarque} style={{ borderBottom: '0' }}>
                    <p style={{ color: '#28337e' }}>{value.alertComment}</p>
                  </div>
                </div>
              ) : null }
              <div style={{ display: remarqueFinalTemp ? '' : 'none' }}>
                <div className={styles.remarque}>
                  <p ref={ColorRemarqueTemp}>{remarqueFinalTemp}</p>
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => { PopUpTemp(NbTemp, value.paramLowLimit, value.paramHighLimit); }}
                    className={styles.icon}
                  />
                </div>
              </div>
            </div>
            <div className={cn(['inputForm_control inputForm_number', styles.baneoInput])}>
              <div className="inputForm_control inputForm_label">{t('balneo.ChloreLibre')}</div>
              <Input
                type="number"
                labelHidden
                step={0.01}
                value={value.chloreLibre}
                onChange={(e) => onChange({ ...value, chloreLibre: parseFloat(e.target.value) })}
                min="0"
                max="100"
              />
            </div>
            <div className={cn(['inputForm_control inputForm_number', styles.baneoInput])}>
              <div className="inputForm_control inputForm_label">{t('balneo.ChloreTotal')}</div>
              <Input
                type="number"
                labelHidden
                step={0.01}
                value={value.chloreTotal}
                onChange={(e) => onChange({ ...value, chloreTotal: parseFloat(e.target.value) })}
                min="0"
                max="100"
              />
            </div>
            <div className={cn(['inputForm_control inputForm_number', styles.baneoInput])}>
              <div className="inputForm_control inputForm_label">{t('balneo.ChloreCombine')}</div>
              <Input
                type="number"
                labelHidden
                value={(value.chloreTotal - value.chloreLibre).toFixed(2)}
                disabled
                step={0.01}
                min="0"
                max="100"
              />
            </div>
            <div className={cn(['inputForm_control inputForm_number', styles.baneoInput])}>
              <div className="inputForm_control inputForm_label">{t('balneo.Ph')}</div>
              <Input
                type="number"
                labelHidden
                value={value.ph}
                onChange={(e) => onChange({ ...value, ph: parseInt(e.target.value, 10) })}
                min="0"
                max="100"
              />
            </div>
            <div className={cn(['inputForm_control inputForm_number', styles.baneoInput])}>
              <div className="inputForm_control inputForm_label">{t('balneo.Transparence')}</div>
              <Radio
                id="claire"
                name="claire"
                checked={value.transparence === 'clearWater'}
                value="clearWater"
                onChange={(e) => onChange({ ...value, transparence: e.target.value })}
                label={t('balneo.clair')}
              />
              <Radio
                id="trouble"
                name="trouble"
                checked={value.transparence === 'troubledWater'}
                value="troubledWater"
                onChange={(e) => onChange({ ...value, transparence: e.target.value })}
                label={t('balneo.trouble')}
              />
              <Radio
                id="jaunâtre"
                name="jaunâtre"
                checked={value.transparence === 'yellowishWater'}
                value="yellowishWater"
                onChange={(e) => onChange({ ...value, transparence: e.target.value })}
                label={t('balneo.jaunatre')}
              />
            </div>
            <div className={cn(['inputForm_control inputForm_number', styles.baneoInput])}>
              <div className="inputForm_control inputForm_label">{t('balneo.NmbBaigneurs')}</div>
              <Input
                type="number"
                labelHidden
                value={value.nombreBaigneur}
                onChange={(e) => onChange({ ...value, nombreBaigneur: parseInt(e.target.value, 10) })}
                min="0"
                max="100"
              />
            </div>
            <div className={cn(['inputForm_control inputForm_number', styles.baneoInput])}>
              <div className="inputForm_control inputForm_label">{t('balneo.desinfectionCurative')}</div>
              <Radio
                id="avecVidange"
                name="avecVidange"
                checked={value.desinfectionCurative === true}
                value={value.desinfectionCurative}
                onChange={() => onChange({ ...value, desinfectionCurative: true })}
                label={t('balneo.avecVidange')}
              />
              <Radio
                id="sansVidange"
                name="sansVidange"
                checked={value.desinfectionCurative === false}
                value={value.desinfectionCurative}
                onChange={() => onChange({ ...value, desinfectionCurative: false })}
                label={t('balneo.sansVidange')}
              />
            </div>
            <div className={cn(['inputForm_control inputForm_number', styles.baneoInput])}>
              <div className="inputForm_control inputForm_label">{t('balneo.ReleveCompteur')}</div>
              <Input
                id={`releve-compteur-${id}`}
                type="number"
                labelHidden
                value={value.releveCompteur}
                onChange={(e) => onChange({ ...value, releveCompteur: parseInt(e.target.value, 10) })}
                min="0"
                onBlur={(e) => {
                  if (e.target.value < value.mileageIndex && e.target.value !== '') {
                    PopUp(e.target.value, value.mileageIndex);
                  }
                }}
              />
              {!remarqueFinal ? (
                <div className={styles.remarque} style={{ display: value.alertComment ? 'flex' : 'none' }}>
                  <div className="inputForm_control inputForm_label">
                    {'Commentaire de l\'alerte'}
                  </div>
                  <div className={styles.remarque} style={{ borderBottom: '0' }}>
                    <p style={{ color: '#28337e' }}>{value.alertComment}</p>
                  </div>
                </div>
              ) : null }
              <div style={{ display: remarqueFinal ? '' : 'none' }}>
                <div className={styles.remarque}>
                  <p ref={ColorRemarque}>{remarqueFinal}</p>
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => { PopUp(Nb, value.mileageIndex); }}
                    className={styles.icon}
                  />
                </div>
              </div>
            </div>
            <div className={cn(['inputForm_control inputForm_number', styles.baneoInput])}>
              <div className="inputForm_control inputForm_label">{t('balneo.ApportCalculer')}</div>
              <Input
                type="number"
                labelHidden
                value={value.apportCalcule}
                disabled
                onChange={(e) => onChange({ ...value, apportCalcule: e.target.value })}
                min="0"
                max="100"
              />
            </div>
          </div>
          <div className={cn(['inputForm_control inputForm_textarea', styles.textarea])}>
            <div className="inputForm_control inputForm_label">{t('balneo.commentaire')}</div>
            <Textarea
              labelHidden
              label={t('balneo.commentaire')}
              name={`commentaire-${value.id}`}
              id={`commentaire-${value.id}`}
              value={value.commentaire}
              onChange={(e) => onChange({ ...value, commentaire: e.target.value })}
            />
          </div>
        </div>
      </div>
      <div className={styles.pop_up} style={{ display: pop ? '' : 'none' }} ref={EffectPop}>
        <h3 className={styles.title_popUp}>{titlePop}</h3>
        <div className={styles.textarea_popUp}>
          <Textarea
            style={RemarqueNull && {
              border: 'red solid 1px',
            }}
            id="remarque"
            name="remarque"
            label={t('alert.remarque')}
            placeholder={RemarqueNull ? 'Veuillez indiquer une remarque..' : ''}
            value={remarque}
            onChange={(e) => { setRemarque(e.target.value); }}
          />
        </div>
        <div className={styles.div_button}>
          <Button
            type="button"
            label={t('alert.valider')}
            onClick={() => {
              PopValid('valid');
              onChange({
                ...value, alert: true, alertComment: remarque, alertHelp: false,
              });
            }}
            className={cn([styles.btn_popUp, styles.btn_blue])}
          />
          <Button
            type="button"
            label={t('alert.aide')}
            onClick={() => {
              PopValid('aide');
              onChange({
                ...value, alert: true, alertComment: remarque, alertHelp: true,
              });
            }}
            className={cn([styles.btn_popUp, styles.btn_orange])}
          />
          <Button
            type="button"
            label={t('alert.change_value')}
            onClick={() => { ChangeValue(); }}
            className={cn([styles.btn_popUp, styles.btn_red])}
          />
        </div>
      </div>
      <div className={styles.pop_up} style={{ display: popTemp ? '' : 'none' }} ref={EffectPopTemp}>
        <h3 className={styles.title_popUp}>{titlePopTemp}</h3>
        <div className={styles.textarea_popUp}>
          <Textarea
            style={RemarqueNullTemp && {
              border: 'red solid 1px',
            }}
            id="remarqueTemp"
            name="remarqueTemp"
            label={t('alert.remarque')}
            placeholder={RemarqueNullTemp ? 'Veuillez indiquer une remarque..' : ''}
            value={remarqueTemp}
            onChange={(e) => { setRemarqueTemp(e.target.value); }}
          />
        </div>
        <div className={styles.div_button}>
          <Button
            type="button"
            label={t('alert.valider')}
            onClick={() => {
              PopValidTemp('valid');
              onChange({
                ...value, alertTemp: false, alertCommentTemp: remarque, alertHelpTemp: false,
              });
            }}
            className={cn([styles.btn_popUp, styles.btn_blue])}
          />
          <Button
            type="button"
            label={t('alert.aide')}
            onClick={() => {
              PopValidTemp('aide');
              onChange({
                ...value, alertTemp: true, alertCommentTemp: remarque, alertHelpTemp: true,
              });
            }}
            className={cn([styles.btn_popUp, styles.btn_orange])}
          />
          <Button
            type="button"
            label={t('alert.change_value')}
            onClick={() => { ChangeValueTemp(); }}
            className={cn([styles.btn_popUp, styles.btn_red])}
          />
        </div>
      </div>
      <div
        className={styles.back_popUp}
        style={{ display: pop ? '' : 'none' }}
        role="button"
        onClick={() => { PopEffect(); }}
        onKeyUp={() => {}}
        tabIndex={0}
      >
        {' '}
      </div>
      <div
        className={styles.back_popUp}
        style={{ display: popTemp ? '' : 'none' }}
        role="button"
        onClick={() => { PopEffectTemp(); }}
        onKeyUp={() => {}}
        tabIndex={0}
      >
        {' '}
      </div>
    </div>
  );
}

BalneoForm.propTypes = {
  //   title: PropTypes.string.isRequired,
  //   label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default BalneoForm;
