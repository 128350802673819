/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faEdit, faTrashCan, faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { debounce } from 'lodash';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Pagination from '../../components/molecules/Pagination';
import Search from '../../components/molecules/Search/Search';
// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
// Services
import { getBuildingsTemplatesList, deleteBuildingTemplate } from '../../services/structures';
// Utils
import { BOOKLET_NAME } from '../../utils/constant';
// styles
import styles from './BuildingTemplates.module.css';
import useAppContext from '../../store/useAppContext';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function BuildingTemplates() {
  // Hooks
  const { t } = useTranslation();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  // States
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [buildingTemplateToDelete, setBuildingTemplateToDelete] = useState({
    id: null,
  });
  const [search, setSearch] = useState('');
  const [context] = useAppContext();

  // API Calls
  const getBuildingsTemplatesListQuery = useQuery(
    ['buildings-templates', page, search],
    () => getBuildingsTemplatesList({
      page: 0,
      search,
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
    }),
  );
  const deleteBuildingTemplateMutation = useMutation(deleteBuildingTemplate, {
    onSuccess:
      () => {
        getBuildingsTemplatesListQuery.refetch();
      },
  });

  const handleModal = (selectedBuildingTemplateToDelete) => {
    setOpenModal(true);
    setBuildingTemplateToDelete(selectedBuildingTemplateToDelete);
  };

  const handleDelete = () => {
    setOpenModal(false);
    deleteBuildingTemplateMutation.mutate(buildingTemplateToDelete.id);
  };

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('buildings_templates.id')}`,
        accessor: 'id',
      },
      {
        Header: `${t('buildings_templates.label')}`,
        accessor: 'name',
      },
      {
        Header: `${t('buildings_templates.booklet')}`,
        accessor: 'bookletId',
        Cell: ({ row: { original: { bookletId } } }) => (
          `${bookletId === 1 ? BOOKLET_NAME.SANITARY : BOOKLET_NAME.SECURITY}`
        ),
      },
      {
        Header: `${t('buildings_templates.equipments')}`,
        accessor: 'countEquipments',
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            <Link
              className="action"
              to={`/batiments-modeles/edit/${id}`}
              title={t('buildings_templates.edit')}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <button
              type="button"
              className="action suppr"
              title={t('buildings_templates.suppr')}
              onClick={() => handleModal({ id })}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Layout
        title={t('buildings_templates.title')}
        queryError={
          getBuildingsTemplatesListQuery?.error
          || deleteBuildingTemplateMutation?.error
        }
        layout="table"
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate('/dashboard')}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('buildings_templates.title')}</h1>
            <Link className="add" to="/batiments-modeles/add">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('buildings_templates.add')}</span>
            </Link>
          </div>
          <div className="row mb-20">
            <Search onChange={handleSearch} />
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={getBuildingsTemplatesListQuery.isLoading}
            data={getBuildingsTemplatesListQuery?.data?.data?.buildingTemplates || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getBuildingsTemplatesListQuery?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
              />
            )}
          />
        </div>
        <footer>
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate('/dashboard')}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button suppr"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </>
  );
}

export default BuildingTemplates;
