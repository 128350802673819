import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faChevronLeft, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useFormik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import {
  createSociety,
} from '../../services/societies';
import styles from './AddNewSociety.module.css';
import useAppContext from '../../store/useAppContext';
import Input from '../../components/atoms/Input/Input';
import Layout from '../../components/template/Layout';
import Select from '../../components/atoms/Select/Select';
import Button from '../../components/atoms/Button/Button';

function NewSociety() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const [context] = useAppContext();
  const [societynameerror, setSocietynameerror] = useState('');
  const [societyaddresserror, setSocietyaddresserror] = useState('');
  const [societycityerror, setSocietycityerror] = useState('');
  const [Societytypeerror, setSocietytypeerror] = useState('');
  const [SocietyIDerror, setSocietyIDerror] = useState('');

  const initialValues = {
    name: '',
    type: '',
    address: '',
    city: '',
    postalCode: '',
    structureId: context.choiceEstablishment.id,
    bookletId: context.choiceBooklet,
  };
  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const goBackUrl = (message) => {
    navigate(-1);
    toast.success(message);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('global.required_field')),
    type: Yup.string().required(t('global.required_field')),
    address: Yup.string().required(t('global.required_field')),
    city: Yup.string().required(t('global.required_field')),
    postalCode: Yup.string()
      .required()
      .matches(/^[0-9]+$/, (t('prestataire.validePostalCode')))
      .min(5, (t('prestataire.validePostalCode')))
      .max(5, (t('prestataire.validePostalCode'))),
  });

  const putSociety = useMutation(createSociety, {
    onSuccess: () => {
      goBackUrl(t('prestataire.success'));
    },
    onError: (error) => {
      if (error.status === 409) {
        setOpenModal(true);
        setSocietynameerror(error.data.name);
        setSocietyaddresserror(error.data.address);
        setSocietycityerror(error.data.city);
        setSocietytypeerror(error.data.type);
        setSocietyIDerror(error.data.id);
      }
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      putSociety.mutate(formValues);
    },
  });
  const optionSelect = [
    { value: 'organization', label: 'Organisme agréé' },
    { value: 'technician', label: 'Technicien compétent' },
  ];
  const forced = (value) => {
    putSociety.mutate(value ? { ...value, forceCreate: true } : initialValues);
    setOpenModal(false);
  };

  const AlreadyExist = (value) => {
    navigate(`/society/add/${context.choiceEstablishment.id}`, {
      state: {
        name: value.name,
        type: value.type,
        address: value.address,
        city: value.city,
        postalCode: value.postalCode,
        structureId: context.choiceEstablishment.id,
        bookletId: context.choiceBooklet,
        id: SocietyIDerror,
      },
    });
    setOpenModal(false);
  };
  return (
    <>
      <Layout
        title={t('prestataire.add_prestataire')}
        icon="fas fa-plus"
        queryError={putSociety?.error}
      >
        <header className="header">
          <div className="row mb-20">
            <button
              type="button"
              className="link"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('prestataire.back_add')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('prestataire.add_prestataire')}</h1>
          </div>
        </header>
        <div>
          <div>
            <div className={styles.warning}>
              Attention,
              les informations que vous allez saisir serviront à l&apos;ensemble des utilisateurs de e-carnet.
            </div>
            <form className="form shadow-sm" onSubmit={formik.handleSubmit}>
              <div className="form_group">
                <Select
                  id="type"
                  label="Type de société"
                  name="type"
                  type="select"
                  options={optionSelect}
                  onChange={(option) => {
                    formik.setFieldValue('type', option.value);
                  }}
                  value={optionSelect.find((option) => option.value === formik.values.type)}
                  valueInput={formik.values.type}
                  required
                />
              </div>
              <div className="form_group">
                {formik.errors.type && formik.touched.type ? (
                  <div className="error">
                    {formik.errors.type}
                  </div>
                ) : null}
              </div>
              <div className="form_group">
                <Input
                  id="name"
                  label="Nom de la société"
                  name="name"
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
              </div>
              {formik.errors.name && formik.touched.name ? (
                <div className="error">
                  {formik.errors.name}
                </div>
              ) : null}
              <div className="form_group">
                <Input
                  id="address"
                  label="adresse"
                  name="address"
                  type="text"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.errors.address && formik.touched.address ? (
                  <div className="error">
                    {formik.errors.address}
                  </div>
                ) : null}
              </div>
              <div className="form_group">
                <Input
                  id="postalCode"
                  label="Code postal"
                  name="postalCode"
                  type="text"
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.errors.postalCode && formik.touched.postalCode ? (
                  <div className="error">
                    {formik.errors.postalCode}
                  </div>
                ) : null}
              </div>
              <div className="form_group">
                <Input
                  id="city"
                  label="Ville"
                  name="city"
                  type="text"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.errors.city && formik.touched.city ? (
                  <div className="error">
                    {formik.errors.city}
                  </div>
                ) : null}
              </div>
              <div className="form_footer">
                <div className="form_infos">
                  <small>{t('addInterventions.mandatory_fields')}</small>
                </div>
                <div className={styles.form_button}>
                  <Button
                    type="submit"
                    className="form_submit"
                    label={t('prestataire.save')}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <footer className="footer">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('prestataire.back_add')}</span>
          </button>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <header className="header">
          <button
            type="button"
            className="link"
            onClick={() => setOpenModal(false)}
          >
            <FontAwesomeIcon icon={faCircleXmark} />
          </button>
        </header>
        <p className="modal_paragraph">
          Le prestataire que vous souhaitez créer existe déjà :
        </p>
        <p>
          Nom du prestataire :
          <p>
            {societynameerror}
          </p>
          {t('prestataire.type')}
          :
          <p>
            {Societytypeerror ? t(`prestataire.${Societytypeerror}`) : null}
          </p>
          {t('prestataire.address')}
          :
          <p>
            {societyaddresserror}
          </p>
          {t('prestataire.city')}
          :
          <p>
            {societycityerror}
          </p>
        </p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('prestataire.prestataire_existe_deja')}
            onClick={() => AlreadyExist(formik.values)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('prestataire.Forcer')}
            onClick={() => forced(formik.values)}
          />
        </div>
      </Modal>
    </>
  );
}

export default NewSociety;
