/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { getEstablishmentsList } from '../../../services/structures';
import Select from '../Select/Select';
import useAppContext from '../../../store/useAppContext';

function SelectMultiStructure({
  Check, onChange, required, loading,
}) {
  const { t } = useTranslation();
  const [selectedStructureIds, setSelectedStructureIds] = useState([]);
  const [context] = useAppContext();

  const establishmentsQuery = useQuery(['establishmentsList'], () => getEstablishmentsList({
    parentid: 799,
    establishmentId: context?.choiceEstablishment?.id,
    bookletId: context?.choiceBooklet,
  }));

  const mapForSelectBuilding = (arrayToMap) => (
    arrayToMap?.length
      ? sortBy(arrayToMap, ['name']).map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))
      : []
  );

  return (
    <Select
      options={mapForSelectBuilding(establishmentsQuery.data?.data?.establishments)}
      label={t('global.structure')}
      onChange={onChange}
      isMulti
      required={required}
      placeholder={t('global.choose')}
      isLoading={loading}
    />
  );
}

SelectMultiStructure.propTypes = {
  Check: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

SelectMultiStructure.defaultProps = {
  Check: false,
};

export default SelectMultiStructure;
