import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import {
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom';

// components
import Layout from '../../components/template/Layout';
import ControlType from '../../components/template/ControlType/ControlType';
import SwitchComment from '../../components/molecules/InputTypes/SwitchComment/SwitchComment';

// services
import { getLightningEquipments, postLightingEquipment, editLightingEquipment } from '../../services/inputs';

// styles
import styles from './inputSafetyLightingEquipment.module.css';
import ProgressBar from '../../components/atoms/ProgressBar/ProgressBar';
import useAppContext from '../../store/useAppContext';

function InputSafetyLightingEquipment() {
  const { inputId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [context] = useAppContext();
  const params = new URLSearchParams(location.search);
  const edit = params.get('edit');

  const [switchCommentValues, setSwitchCommentValues] = useState(null);
  const lightingEquipmentsQuery = useQuery('lightningEquipments', () => getLightningEquipments({
    inputId,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      const initializeValues = data?.data?.safetyLightingEquipmentInputs?.map((input) => ({
        id: input.id,
        isDirty: false,
        blockNumber: input.blockNumber,
        location: input.location,
        function: input.function,
        checked: input.checked,
        comment: input.comment || null,
      }));
      setSwitchCommentValues(initializeValues);
    },
  });

  const columns = [
    {
      label: t('inputSafetyLightingEquipment.id'),
      class: 'no-margin flex-shrink-0',
      width: 100,
    },
    {
      label: t('inputSafetyLightingEquipment.name'),
      width: 360,
    },
    {
      label: t('inputSafetyLightingEquipment.type'),
      width: 240,
    },
    {
      label: t('inputSafetyLightingEquipment.state'),
      width: 120,
    },
    {
      label: t('inputSafetyLightingEquipment.comment'),
      width: 280,
    },
  ];

  const handleswitchCommentValues = (newValue) => {
    const newSwitchCommentValues = switchCommentValues.map((radioNumberTimesValue) => {
      if (radioNumberTimesValue.id === newValue.id) return { ...newValue, isDirty: true };
      return radioNumberTimesValue;
    });
    setSwitchCommentValues(newSwitchCommentValues);
  };

  const postLightingEquipmentQuery = useMutation(postLightingEquipment, {
    onSuccess: () => {
      lightingEquipmentsQuery.refetch();
      toast.success(t('inputSafetyLightingEquipment.added'));
      navigate('/saisies');
    },
  });

  const editLightingEquipmentQuery = useMutation(editLightingEquipment, {
    onSuccess: () => {
      lightingEquipmentsQuery.refetch();
      toast.success(t('inputSafetyLightingEquipment.added'));
      navigate(-1);
    },
  });

  const handleValuesSubmit = () => {
    const ofComplet = lightingEquipmentsQuery?.data?.data?.numberOfCompleted;
    const toComplet = lightingEquipmentsQuery?.data?.data?.totalToComplete;
    if (ofComplet === toComplet) {
      navigate(-1);
    }
    const valuesToSubmit = switchCommentValues
      .filter((input) => input.isDirty)
      .map((switchCommentValue) => (
        {
          safetyLightingEquipmentInputId: switchCommentValue.id,
          checked: switchCommentValue.checked,
          comment: switchCommentValue.comment,
        }
      ));
    if (valuesToSubmit.length === 0) {
      return toast.info(t('inputs.nothing_to_save'));
    }
    const objectToSubmit = {
      inputId,
      safetyLightingEquipmentInputs: valuesToSubmit,
    };
    return edit ? editLightingEquipmentQuery.mutate(objectToSubmit) : postLightingEquipmentQuery.mutate(objectToSubmit);
  };

  return (
    <Layout
      title={t('inputSafetyLightingEquipment.title')}
      layout="table"
      queryError={
        lightingEquipmentsQuery?.error
        || postLightingEquipmentQuery?.error
      }
    >
      <header className="header">
        <div className="row">
          <h1 className="title">{t('inputSafetyLightingEquipment.title')}</h1>
        </div>
        <div className="row">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('inputSafetyLightingEquipment.back')}</span>
          </button>
          {lightingEquipmentsQuery?.data?.data.numberOfCompleted ? (
            <ProgressBar
              value={lightingEquipmentsQuery?.data?.data?.numberOfCompleted}
              valueMax={lightingEquipmentsQuery?.data?.data?.totalToComplete}
            />
          ) : null}
        </div>
      </header>
      {lightingEquipmentsQuery.isLoading ? (
        <div className="loader" />
      )
        : (
          <div className={styles.block}>
            <ControlType columns={columns} onSubmit={handleValuesSubmit}>
              {switchCommentValues?.map((input, index) => (
                <SwitchComment
                  key={input.id}
                  label={[
                    { title: input.blockNumber, width: 100 },
                    { title: input.location, width: 360 },
                    { title: input.function, width: 240 },
                  ]}
                  id={input.id}
                  value={switchCommentValues[index]}
                  onChange={handleswitchCommentValues}
                  className={styles.spaceBetween}
                />
              ))}
            </ControlType>
          </div>
        )}
    </Layout>
  );
}

export default InputSafetyLightingEquipment;
