import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import ControlType from '../../template/ControlType/ControlType';
import SwitchListComment from '../InputTypes/SwitchListComment/SwitchListComment';

// utils
import { SSI_TROUBLE_TYPES } from '../../../utils/constant';

function SSIPermanent({ list, submit }) {
  // Hooks
  const { t } = useTranslation();
  const columns = [
    {
      label: '',
      width: 140,
    },
    {
      label: t('inputs.state'),
      width: 120,
    },
    {
      label: t('inputs.disturbance_type'),
      width: 340,
    },
    {
      label: t('inputs.comment'),
      width: 265,
    },
  ];
  const initialList = list?.map((input) => ({ ...input, isDirty: false }));
  const [inputsList, setInputsList] = useState(initialList);

  const handleChange = (input) => {
    const resultsChanged = inputsList.map((inputState) => {
      if (inputState.id === input.id) return ({ ...input, isDirty: true });
      return inputState;
    });
    setInputsList(resultsChanged);
  };

  const handleValuesSubmit = () => {
    const valuesToSubmit = inputsList
      .filter((input) => {
        if (input.isDirty
          && input.checked === true) return true;
        if (input.isDirty
          && input.checked === false
          && input.troubleType !== null
          && input.comment !== null
          && input.comment.trim() !== '') return true;
        if (input.isDirty
          && input.checked === null
          && input.comment !== null
          && input.comment.trim() !== '') return true;
        return false;
      })
      .map((input) => ({
        inputId: input?.id,
        checked: input?.checked,
        troubleType: input?.troubleType,
        comment: input?.comment,
      }));
    submit(valuesToSubmit);
  };

  const options = [
    {
      value: SSI_TROUBLE_TYPES.DETECTION_ISSUE,
      label: t('inputs.detection_issue'),
    },
    {
      value: SSI_TROUBLE_TYPES.SAFETY_ISSUE,
      label: t('inputs.safety_issue'),
    },
    {
      value: SSI_TROUBLE_TYPES.POWER_SUPPLY_ISSUE,
      label: t('inputs.power_supply_issue'),
    },
  ];

  return (
    <ControlType columns={columns} onSubmit={handleValuesSubmit}>
      {inputsList.map((input) => (
        <SwitchListComment
          key={input.id}
          id={input.id}
          label={input.title}
          options={options}
          value={input}
          onChange={handleChange}
        />
      ))}
    </ControlType>
  );
}

SSIPermanent.propTypes = {
  list: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
};

export default SSIPermanent;
