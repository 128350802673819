import PropTypes from 'prop-types';
import useAppContext from '../../../store/useAppContext';

function Permissions({ permission }) {
  const [context] = useAppContext();
  const permissions = context?.permissions?.[`${context?.choiceBooklet}/${context?.choiceEstablishment?.id}`];
  const result = permissions?.find((element) => element === permission);
  if (permissions === undefined) {
    return true;
  }
  return result;
}

Permissions.propTypes = {
  permission: PropTypes.string.isRequired,
};

Permissions.defaultProps = {
  permission: '',
};

export default Permissions;
