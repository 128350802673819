import React from 'react';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  useFormik,
} from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useMutation } from 'react-query';
// import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { getObservation } from '../../services/registre';
import Input from '../../components/atoms/Input/Input';
import Layout from '../../components/template/Layout';
import styles from './raisedObservation.module.css';
import Button from '../../components/atoms/Button/Button';
import cn from '../../utils/cn';
// import Radio from '../../components/atoms/Radio/Radio';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';
import Textarea from '../../components/atoms/Textarea/Textarea';
import useAppContext from '../../store/useAppContext';

function finishObservation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [context] = useAppContext();

  const initialValues = {
    upDate: '',
    prestataireName: '',
    prestataire: false,
    document: null,
    description: '',
    SocietyName: '',
  };
  const getObservationQuery = useQuery(['getObservation'], () => getObservation({
    id: urlParams.id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  // const goBackUrl = (message) => {
  // navigate(-1);
  // toast.success(message);
  // };

  const validationSchema = Yup.object({
    prestataire: Yup.boolean(),
    upDate: Yup.string().required('global.required_field'),
    prestataireName: Yup.string().required('global.required_field'),
    document: Yup.mixed().nullable().typeError(t('global.wrong_type')).required('global.required_field')
      .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 10000000)
      .test('type', 'global.accepted_formats', (value) => value && (value.type === 'application/pdf'
        || value.type === 'application/x-pdf'
        || value.type === 'image/jpeg'
        || value.type === 'image/jpg'
        || value.type === 'image/png'
        || value.type === 'image/tiff'
        || value.type === 'image/bmp'
        || value.type === 'image/heic'
        || value.type === 'image/vnd.dwg'
      )),
    description: Yup.string().required('global.required_field'),
    SocietyName: Yup.string()
      .when('prestataire', {
        is: true,
        then: Yup.string().required('global.required_field'),
      }),
  });

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append('upDate', values.upDate);
    formData.append('prestataireName', values.prestataireName);
    formData.append('document', values.document);
    formData.append('description', values.description);
    if (values.prestataire === true) {
      formData.append('SocietyName', values.SocietyName);
    } else {
      formData.append('SocietyName', 'regie_interne');
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  return (
    <Layout
      title="finishObservation"
      layout="table"
      queryError={
        getObservationQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
        <div className={cn(['row', styles.row])}>
          <h1 className="title">{t('raisedObs.add')}</h1>
        </div>
      </header>
      <div className="title">
        <div>
          {getObservationQuery.isLoading && (
            <div>Loading...</div>
          )}
          <div>
            <form
              onSubmit={formik.handleSubmit}
              className="form shadow"
            >
              {getObservationQuery?.data?.observationNumber}
              <div className="form_group">
                <Input
                  type="date"
                  name="upDate"
                  label={t('raisedObs.upDate')}
                  value={formik.values.upDate}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors.upDate && formik.touched.upDate ? (
                  <div className="error">
                    {t(formik.errors.upDate)}
                  </div>
                ) : null }
              </div>
              <div>
                {' '}
              </div>
              <div className="form_group">
                <Input
                  type="text"
                  name="prestataireName"
                  label={t('raisedObs.prestataireName')}
                  value={formik.values.prestataireName}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors.prestataireName && formik.touched.prestataireName ? (
                  <div className="error">
                    {t(formik.errors.prestataireName)}
                  </div>
                ) : null }
              </div>
              <div>
                <UploadFile
                  id="documentFile"
                  name="document"
                  label={t('contract.report')}
                  fileName={formik.values.document ? formik.values.document.name : formik.values.document}
                  onChange={(event) => formik.setFieldValue('document', event.currentTarget.files[0])}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.errors.document && formik.touched.document ? (
                  <div className="error">
                    {t(formik.errors.document)}
                  </div>
                ) : null }
              </div>
              <div className="form_group--fullwidth">
                <Textarea
                  name="description"
                  label={t('raisedObs.description')}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  cols="30"
                  rows="10"
                  required
                />
                {formik.errors.description && formik.touched.description ? (
                  <div className="error">
                    {t(formik.errors.description)}
                  </div>
                ) : null }
              </div>
              <div className="form_checkbox">
                <Checkbox
                  id="prestataire"
                  name="prestataire"
                  className="checkbox"
                  label={t('raisedObs.byPrestaataire')}
                  value={formik.values.prestataire}
                  onChange={formik.handleChange}
                  checked={formik.values.prestataire}
                />
              </div>

              {formik.values.prestataire === true && (
                <div className="form_group">
                  <Input
                    type="text"
                    name="SocietyName"
                    label={t('raisedObs.SocietyName')}
                    value={formik.values.SocietyName}
                    onChange={formik.handleChange}
                    required={formik.values.prestataire === true}
                    placeholder={t('raisedObs.SocietyName')}
                  />
                  {formik.errors.description && formik.touched.description ? (
                    <div className="error">
                      {t(formik.errors.description)}
                    </div>
                  ) : null }
                </div>
              )}
              <div className="form_footer">
                <div className="form_infos">
                  <small>{t('addInterventions.mandatory_fields')}</small>
                </div>
                <Button
                  type="submit"
                  className="form_submit"
                  label={t('raisedObs.save')}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default finishObservation;
