/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import {
  faEye, faChevronLeft, faEnvelopeOpenText, faClose, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../components/atoms/Button/Button';
import Table from '../../components/molecules/Table/Table';
import { getNotificationList, GetAccountNotification } from '../../services/notification';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import Pagination from '../../components/molecules/Pagination';
import styles from './NotificationDashbord.module.css';
import useAppContext from '../../store/useAppContext';

function Notification() {
  const { t } = useTranslation();
  const [responses, setResponses] = useState([]);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [context] = useAppContext();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });

  const getNotificationListQuery = useQuery(['notificationList', page], () => getNotificationList({
    page,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const customStylest = {
    content: {
      top: '50%',
      left: '50%',
      width: '140vh',
      height: '70vh',
      right: 'auto',
      bottom: 'auto',
      borderRadius: '1rem',
      boxShadow: '0.1rem 0.1rem white',
      marginRight: '-50%',
      zIndex: 2,
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const read = useMutation(GetAccountNotification, {
    onSuccess: (data) => {
      setResponses(data?.data);
    },
  });

  const open = (value) => {
    read.mutate(value);
    setOpenNotificationModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('notification.title'),
        accessor: 'title',
      },
      {
        Header: t('notification.dateView'),
        accessor: 'readAt',
        Cell: ({ value }) => <div className={styles.date}>{value && new Date(value).toLocaleDateString()}</div>,
      },
      {
        Header: t('notification.action'),
        accessor: 'id',
        Cell: ({ value }) => (
          <div>
            <FontAwesomeIcon icon={faEye} onClick={() => open(value)} />
          </div>
        ),
      },
    ],
    [t],
  );

  return (
    <>
      <div className={styles.table}>
        <Table
          columns={columns}
          isLoading={getNotificationListQuery?.isLoading}
          data={getNotificationListQuery?.data?.data?.accountNotifications || []}
          hasSort
          pagination={(
            <Pagination
              previousLabel={(
                <FontAwesomeIcon icon={faChevronLeft} />
              )}
              nextLabel={(
                <FontAwesomeIcon icon={faChevronRight} />
              )}
              onPageChange={onPageChange}
              totalCount={getNotificationListQuery?.data?.totalPages || 0}
              currentPage={page}
              haveToPaginate
              pageSize={10}
              numberElementsDisplayed={getNotificationListQuery?.data?.length || 0}
            />
          )}
        />
      </div>
      <Modal
        isOpen={openNotificationModal}
        onRequestClose={() => setOpenNotificationModal(false)}
        style={customStylest}
        ariaHideApp={false}
        id="notificationModal"
        contentLabel="notification Modal"
      >
        <div>
          <h2 className={styles.header}>
            <FontAwesomeIcon icon={faEnvelopeOpenText} />
            {t('notification.message')}
          </h2>
          <hr />
          <div className={styles.titre}>
            <div>{responses?.title}</div>
          </div>
          <div className={styles.text}>
            <p className={styles.message}>
              <pre>{responses?.message}</pre>
            </p>
          </div>
          <div className={styles.button}>
            <Button
              type="button"
              className={styles.close}
              label={<FontAwesomeIcon icon={faClose} />}
              onClick={() => setOpenNotificationModal(false)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Notification;
