/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import { useQuery } from 'react-query';
// components
import Layout from '../../components/template/Layout';
// import Notification from '../Notification/notifications';
import logoSanitaire from '../../assets/img/logos/e-carnet-sanitaire-logo.png';
import logoSecurite from '../../assets/img/logos/e-carnet-securite-logo.png';
import logoVehicule from '../../assets/img/logos/e-carnet-vehicule-logo.png';
import bckgSecurite from '../../assets/img/background_securite.jpg';
import bckgSanitaire from '../../assets/img/background_sanitaire.jpg';
import bckgVehicule from '../../assets/img/background_vehicule.png';
// import { getNotificationList } from '../../services/notification';
// hooks
import useAppContext from '../../store/useAppContext';

// style
import styles from './ChoiceBooklet.module.css';

// util
import cn from '../../utils/cn';

export const choicesBooklet = [
  {
    id: 2,
    key: 'securite',
    label: 'Sécurité',
    title: 'add_building_template.booklet_security',
    backgroundImg: bckgSecurite,
    logoChoice: logoSecurite,
  },
  {
    id: 1,
    key: 'sanitaire',
    label: 'Sanitaire',
    title: 'add_building_template.booklet_sanitary',
    backgroundImg: bckgSanitaire,
    logoChoice: logoSanitaire,
  },
  {
    id: 3,
    key: 'vehicule',
    label: 'Vehicule',
    title: 'add_building_template.booklet_vehicule',
    backgroundImg: bckgVehicule,
    logoChoice: logoVehicule,
  },
];

const navigateTo = (id) => {
  let urlNavigate = '';
  if (id === 1) {
    urlNavigate = '/sanitaire/choix-etablissement';
  }
  if (id === 2) {
    urlNavigate = '/securite/choix-etablissement';
  }
  if (id === 3) {
    urlNavigate = '/vehicule/choix-etablissement';
  }
  return urlNavigate;
};

function ChoiceBooklet() {
  const { t } = useTranslation();
  const [context, dispatch] = useAppContext();
  // verifier si context.choiceBooklet existe si ces le leffacer et le remplacer par le nouveau
  useEffect(() => {
    dispatch({ type: 'CHOICE_BOOKLET', payload: null });
    if (context?.choiceEstablishment !== null) {
      dispatch({ type: 'CHOICE_ESTABLISHMENT', payload: null });
    }
  }, []);

  const handleChoiceBooklet = (id) => dispatch({ type: 'CHOICE_BOOKLET', payload: id });
  return (
    <Layout
      title={t('choice_booklet.title_page')}
    >
      <header className={cn(['header', styles.headerChoice, 'shadow-md'])}>
        <h1 className="title">
          {t('choice_booklet.title_page')}
        </h1>
      </header>
      <section className={styles.sectionChoice}>
        { choicesBooklet.map((choice) => (
          <Link
            onClick={() => handleChoiceBooklet(choice?.id)}
            className={cn(['shadow-md', styles.choiceWrapper])}
            key={choice.key}
            to={navigateTo(choice.id)}
          >
            <div className={styles.backgroundBooklet} style={{ backgroundImage: `url(${choice.backgroundImg})` }} />
            <div className={styles.choiceBooklet}>
              <img src={choice.logoChoice} alt="" />
            </div>
          </Link>
        ))}
      </section>
    </Layout>
  );
}

export default ChoiceBooklet;
