import api from './_api';

/**
 * GET - Get Interventions list
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const getInterventionsList = (queryParams) => api.get('/interventions', { params: queryParams });

/**
 * GET - Get Intervention
 * @param {Number} interventionId
 * @returns {Promise<AxiosResponse>}
 */

export const getIntervention = (interventionId) => api.get(`/interventions/${interventionId}`);
/**
 * POST - Create Intervention
 * @param {{name:String, address:String, postalCode:Number, city:String}} interventionToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postInterventionInformations = (interventionToCreate) => api.post('/interventions', interventionToCreate);

/**
  * PUT - Edit Intervention
  * @param {{id:Number, name:String, parentId:Number, address:String, postalCode:Number, city:String}}
  * interventionToEdit
  * @returns {Promise<AxiosResponse>}
  */
// eslint-disable-next-line max-len
export const editInterventionInformations = (interventionToEdit) => api.put(`/interventions/${interventionToEdit.id}`, interventionToEdit);

/**
 * DEL - Delete Intervention
 * @param {Number} interventionId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteIntervention = (interventionId) => api.delete(`/interventions/${interventionId}`);
