/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-else-return */
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
  faChevronLeft, faChevronRight, faAddressCard, faEdit, faTrashCan, faPlus, faFileCsv,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
// import axios from 'axios';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Search from '../../components/molecules/Search/Search';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import Pagination from '../../components/molecules/Pagination';
import styles from './Society.module.css';
import useAppContext from '../../store/useAppContext';
import { getSocietiesList, unlinkPrestataireToSociety } from '../../services/societies';
import cn from '../../utils/cn';
import { downloadFileCsv } from '../../utils/downloadFile';
import Permissions from '../../components/molecules/Permissions/Permissions';

/* eslint-disable react/prop-types */

function Societies() {
  const [societies, setSocieties] = useState([]);
  const [supplierToDelete, setsupplierToDelete] = useState('');
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [context] = useAppContext();
  const [openModal, setOpenModal] = useState(false);
  // const auth = JSON.parse(localStorage.getItem('auth'));
  const [search, setSearch] = useState('');
  const [dowloadInfo, setDowloadInfo] = useState([]);

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const getSocietiesListQuery = useQuery(['societiesList', page, search], () => getSocietiesList({
    structureId: context?.choiceEstablishment?.id,
    bookletId: context?.choiceBooklet,
    search,
    page: 0,
    mine: true,
  }), {
    onSuccess: (data) => {
      setSocieties(data);
      setDowloadInfo(data?.data?.societies);
    },
  });
  const findSociety = (societyId) => {
    const booket = societyId?.societyStructures
      ?.find((s) => s.bookletId === context?.choiceBooklet && s?.structureId === context?.choiceEstablishment?.id);
    return booket;
  };
  const findCommentaire = (societyId) => {
    const booket = societyId?.societyStructures
      ?.find((s) => s.bookletId === context?.choiceBooklet && s?.structureId === context?.choiceEstablishment?.id);
    return booket;
  };
  const handleModal = (selectedInterventionToDelete) => {
    setOpenModal(true);
    setsupplierToDelete(selectedInterventionToDelete);
  };
  const hearder = [
    {
      label: t('societies.Service_provider'),
      key: 'name',
    },
    {
      label: t('societies.city'),
      key: 'city',
    },
    {
      label: t('societies.Comment'),
      key: 'comment',
      cell: (row) => findCommentaire(row.original)?.comment || '-',
    },
    {
      label: t('interventions.user'),
      key: 'user',
      cell: (row) => findSociety(row.original)?.lastNameContact1 || '-',
    },
    {
      label: t('interventions.phone'),
      key: 'phone',
      cell: (row) => findSociety(row.original)?.mainPhoneContact1 || '-',
    },
    {
      label: t('interventions.email'),
      key: 'email',
      cell: (row) => findSociety(row.original)?.emailContact1 || '-',
    },
  ];
  const handleDowload = () => {
    downloadFileCsv(dowloadInfo, hearder, `${t('societies.prestataires')}`);
  };

  const deletesociety = useMutation(unlinkPrestataireToSociety, {
    onSuccess: () => {
      getSocietiesListQuery.refetch();
      toast.success((t('prestataire.delete_ok')));
    },
    onError: () => {
      toast.error(t('prestataire.delete_ko'));
    },
  });

  const handleDelete = (id) => {
    setOpenModal(false);
    deletesociety.mutate({
      structureId: context?.choiceEstablishment?.id,
      bookletId: context?.choiceBooklet,
      id,
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('societies.Service_provider')}`,
        accessor: 'name',
        Cell: ({ row }) => (
          <div>
            {row?.original?.name}
            {'  '}
            <span className={styles.first}>
              (
              {t(`societies.${row?.original?.type}`)}
              )
            </span>
          </div>
        ),
      },
      {
        Header: `${t('societies.city')}`,
        accessor: 'city',
        Cell: ({ row }) => (
          <div>
            {row?.original?.city}
            {' '}
            (
            {row?.original?.postalCode}
            )
          </div>
        ),
      },
      {
        Header: `${t('societies.Comment')}`,
        accessor: 'comment',
        Cell: ({ row }) => (
          <div>
            {findCommentaire(row?.original)?.comment || '-'}
          </div>
        ),
      },
      {
        Header: t('interventions.user'),
        accessor: 'user',
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon data-tip data-for={`userColResult${row?.original?.id}`} icon={faAddressCard} />
            <ReactTooltip id={`userColResult${row?.original.id}`} place="top" type="info" effect="solid">
              {findSociety(row?.original) !== null ? (
                <>
                  <div className={styles.nomaj}>
                    {findSociety(row?.original)?.emailContact1}
                  </div>
                  <div>
                    <span className={cn([styles.first])}>
                      {findSociety(row?.original)?.firstNameContact1}
                    </span>
                    {' '}
                    <span>
                      {findSociety(row?.original)?.lastNameContact1}
                    </span>
                  </div>
                  <div>
                    {findSociety(row?.original)?.mainPhoneContact1}
                  </div>
                </>
              ) : (
                <div>
                  -
                </div>
              )}
            </ReactTooltip>
          </div>
        ),
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: ({ row }) => (
          <div className={styles.button}>
            {Permissions({ permission: 'SOCIETY_READ' }) === undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={<FontAwesomeIcon icon={faEdit} />}
                  type="button"
                  className={styles.edit}
                  title={t('societies.edit')}
                  onClick={() => navigate(`/society/edit/${row?.original?.id}`, { state: { society: row?.original } })}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              ) : null }
            {Permissions({ permission: 'SOCIETY_DELETE' }) !== undefined
              ? (
                <Button
                  type="button"
                  className="action suppr"
                  label={<FontAwesomeIcon icon={faTrashCan} />}
                  title={t('societies.suppr')}
                  onClick={() => handleModal(row?.original?.id)}
                />
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  return (
    <>
      <Layout
        title={t('societies.title_page')}
        layout="table"
        table={societies}
        queryError={
          getSocietiesListQuery?.error
          || deletesociety?.error
        }
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          {Permissions({ permission: 'SOCIETY_LINK' }) !== undefined
            ? (
              <div className="row">
                <h1 className="title">{t('societies.prestataires')}</h1>
                <Link className={cn([styles.add, 'add col-3'])} to="/society/add">
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="add_label">{t('societies.add')}</span>
                </Link>
              </div>
            ) : null }
        </header>
        <div className="recherche">
          <div className="row">
            <button
              className="add"
              type="button"
              onClick={handleDowload}
            >
              <FontAwesomeIcon icon={faFileCsv} />
              {' '}
              {t('reports.download')}
            </button>
            <Search
              className="mb-20"
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={societies.isLoading || getSocietiesListQuery.isLoading}
            data={societies?.data?.societies || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getSocietiesListQuery?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
              />
          )}
          />
        </div>
        <footer>
          <div className="row mt-20">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyle}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={() => handleDelete(supplierToDelete)}
          />
        </div>
      </Modal>
    </>
  );
}
export default Societies;
