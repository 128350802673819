import api from './_api';

/**
 * GET - Get Trainings list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getTrainingsList = (queryParams) => api.get('/trainings', { params: queryParams });

/**
 * GET - Get Training
 * @param {Number} trainingId
 * @returns {Promise<AxiosResponse>}
 */
export const getTraining = (trainingId) => api.get(`/trainings/${trainingId}`);

/**
 * GET - Get Training File To Download
 * @param {Number} trainingId
 * @returns {Promise<AxiosResponse>}
 */
export const getTrainingFileToDownload = (trainingId) => api
  .get(`trainings/${trainingId}/download-document`, { responseType: 'blob' });

/**
 * POST - Create Training
 * @param {{
 * establishmentId:Number,
 * type:String,
 * name:String,
 * date:Date,
 * headcount:Number,
 * documentFile:File,
 * speakerId:Number,
 * speakerName:String,
 * personInCharge:String,
 * firstName:String,
 * lastName:String,
 * validUntil:Date
 * }} trainingToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postTraining = (trainingToCreate) => {
  const formData = new FormData();
  formData.append('establishmentId', trainingToCreate.establishmentId);
  formData.append('type', trainingToCreate.type);
  formData.append('name', trainingToCreate.name);
  formData.append('date', trainingToCreate.date);
  formData.append('headcount', trainingToCreate.headcount);
  formData.append('documentFile', trainingToCreate.documentFile);
  if (trainingToCreate.type === 'fire_safety_prevention') {
    formData.append('speakerId', trainingToCreate.speakerId);
    formData.append('speakerName', trainingToCreate.speakerName);
    formData.append('personInCharge', trainingToCreate.personInCharge);
  }
  if (trainingToCreate.type === 'maintenance_manager') {
    formData.append('firstName', trainingToCreate.firstName);
    formData.append('lastName', trainingToCreate.lastName);
    formData.append('validUntil', trainingToCreate.validUntil);
  }
  return api.post('/trainings', formData);
};

/**
 * PUT - Edit Training
 * @param {{
 * id:Number,
 * type:String,
 * name:String,
 * date:Date,
 * headcount:Number,
 * documentFile:File,
 * speakerId:Number,
 * speakerName:String,
 * personInCharge:String,
 * firstName:String,
 * lastName:String,
 * validUntil:Date
 * }} trainingToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editTraining = (trainingToEdit) => {
  const formData = new FormData();
  formData.append('id', trainingToEdit.id);
  formData.append('type', trainingToEdit.type);
  formData.append('name', trainingToEdit.name);
  formData.append('date', trainingToEdit.date);
  formData.append('headcount', trainingToEdit.headcount);
  if (trainingToEdit.documentFile !== undefined) {
    formData.append('documentFile', trainingToEdit.documentFile);
  }
  if (trainingToEdit.type === 'fire_safety_prevention') {
    formData.append('speakerId', trainingToEdit.speakerId);
    formData.append('speakerName', trainingToEdit.speakerName);
    formData.append('personInCharge', trainingToEdit.personInCharge);
  }
  if (trainingToEdit.type === 'maintenance_manager') {
    formData.append('firstName', trainingToEdit.firstName);
    formData.append('lastName', trainingToEdit.lastName);
    formData.append('validUntil', trainingToEdit.validUntil);
  }
  return api.post(`/trainings/${trainingToEdit.id}`, formData);
};

/**
 * DEL - Delete Training
 * @param {Number} trainingId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteTraining = (trainingId) => api.delete(`/trainings/${trainingId}`);
