import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

// Styles
import styles from './Search.module.css';

// Utils
import cn from '../../../utils/cn';

function Search({
  className, id, value, onChange,
}) {
  // Hooks
  const { t } = useTranslation();

  return (
    <div className={cn([styles.search, className])}>
      <label className="visually-hidden" htmlFor={id}>{t('global.search')}</label>
      <input
        value={value}
        onChange={onChange}
        type="search"
        id={id}
        className={styles.input}
        placeholder={t('global.search')}
      />
      <FontAwesomeIcon icon={faMagnifyingGlass} />
    </div>
  );
}

Search.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Search.defaultProps = {
  className: '',
  id: 'search-input',
};

export default Search;
