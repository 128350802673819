import React from 'react';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  useFormik,
} from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { updateVacation } from '../../services/vacation';
import Input from '../../components/atoms/Input/Input';
import Layout from '../../components/template/Layout';
import styles from './addvacation.module.css';
import Button from '../../components/atoms/Button/Button';
import cn from '../../utils/cn';
import { formatDateToInput } from '../../utils/dates';

function AddVacation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();

  const initialValues = {
    startDate: '',
    endDate: '',
    buildingVacations: [
      {
        buildingId: urlParams.id,
        startDate: '',
        endDate: '',
      },
    ],
  };

  const goBackUrl = (message) => {
    navigate(-1);
    toast.success(message);
  };

  const validationSchema = Yup.object({
    startDate: Yup.string(),
    endDate: Yup.string(),
    buildingVacations: Yup.array().of(
      Yup.object().shape({
        startDate: Yup.string().required(t('vacantion.requis')),
        endDate: Yup.string().required(t('vacantion.requis')),
        buildingId: Yup.string(),
      }),
    ),
  });
  const updateVacationMutation = useMutation(updateVacation, {
    onSuccess: () => {
      goBackUrl(t('vacantion.updated'));
    },
    onError: () => {
      toast.error(t('vacantion.error'));
    },
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      updateVacationMutation.mutate({
        id: urlParams.id,
        body: values,
      });
    },
  });
  const calcule = (startdate, enddate) => {
    const startDate = new Date(startdate);
    const endDate = new Date(enddate);
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };
  return (
    <Layout
      title="Vacation"
      layout="table"
      queryError={
        updateVacationMutation?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('vacantion.return')}</span>
          </button>
        </div>
        <div className={cn(['row', styles.row])}>
          <h1 className="title">{t('vacantion.add')}</h1>
        </div>
      </header>
      <div className="title">
        <div>
          {' '}
          <div>
            <div>
              {formik.values.buildingVacations[0].startDate && formik.values.buildingVacations[0].endDate ? (
                <div className="form shadow">
                  {calcule(formik.values.buildingVacations[0].startDate, formik.values.buildingVacations[0].endDate)}
                  {' '}
                  {t('vacantion.days')}
                </div>
              ) : null}
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="form shadow"
            >
              <div className="form_group">
                <Input
                  id="startDate"
                  type="date"
                  name="startDate"
                  label={t('vacantion.start_date')}
                  value={formatDateToInput(formik.values.buildingVacations[0].startDate)}
                  onChange={(e) => {
                    formik.setFieldValue('buildingVacations[0].startDate', e.target.value);
                  }}
                  min={formatDateToInput(new Date())}
                  max="2099-12-31"
                  required
                />
                {formik.errors.buildingVacations
                && formik.touched.buildingVacations
                && formik.errors.buildingVacations[0].startDate ? (
                    formik.errors.buildingVacations.map((error) => (
                      <div className="error">{error.startDate}</div>
                    ))
                  ) : null}
              </div>
              <div className="form_group">
                <Input
                  id="endDate"
                  type="date"
                  name="endDate"
                  label={t('vacantion.end_date')}
                  value={formatDateToInput(formik.values.buildingVacations[0].endDate)}
                  onChange={(e) => {
                    formik.setFieldValue('buildingVacations[0].endDate', e.target.value);
                  }}
                  min={formatDateToInput(formik.values.buildingVacations[0].startDate)}
                  max="2099-12-31"
                  required
                />
                {formik.errors.buildingVacations
                && formik.touched.buildingVacations
                && formik.errors.buildingVacations[0].endDate ? (
                    formik.errors.buildingVacations.map((error) => (
                      <div className="error">{error.endDate}</div>
                    ))
                  ) : null}
              </div>
              <br />
              <div className="form_footer">
                <div className="form_infos">
                  <small>{t('addInterventions.mandatory_fields')}</small>
                </div>
                <Button
                  type="submit"
                  className="form_submit"
                  label={t('vacantion.save')}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('vacantion.return')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default AddVacation;
