/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { sortBy } from 'lodash';
import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faFileArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { getContract, updateContract, getContractDocument } from '../../services/contracts';
import { getEquipmentsList } from '../../services/equipments';
import { getSocietiesList } from '../../services/societies';
import useAppContext from '../../store/useAppContext';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import Layout from '../../components/template/Layout';
import Select from '../../components/atoms/Select/Select';
import Permissions from '../../components/molecules/Permissions/Permissions';
// import styles from './EditContract.module.css';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
// import UploadFile from '../../components/atoms/UploadFile/UploadFile';

function Contract() {
  const { t } = useTranslation();
  const context = useAppContext();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [society, setSociety] = React.useState('');
  const [equipments, setEquipment] = React.useState([]);

  const goBackUrl = (msg) => {
    navigate('/contracts');
    toast.success(msg);
  };

  const putContact = useMutation(updateContract, {
    onSuccess: () => {
      goBackUrl(t('contract.succes_save_modif'));
    },
    onError: () => {
      toast.error(t('contract.erreur_save_modif'));
    },
  });

  const prestataire = useQuery(['societiesList'], () => getSocietiesList({
    structureId: context[0]?.choiceEstablishment?.id,
    bookletId: context[0]?.choiceBooklet,
    establishmentId: context[0]?.choiceEstablishment?.id,
  }));
  const getEquipements = useQuery(['equipmentsList'], () => getEquipmentsList({
    structureId: context[0]?.choiceEstablishment?.id,
    bookletId: context[0]?.choiceBooklet,
    establishmentId: context[0]?.choiceEstablishment?.id,
  }));
  const mapForSelect = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['name']).map((item) => ({
      ...item,
      label: item.name + (item.postalCode ? ` (${item.postalCode})` : ''),
      value: item.id,
    }))
    : []);
  const initialValues = {
    prestataire: '',
    equipement: '',
    effectiveDate: '',
    initialDuration: '',
    noticePeriodDuration: '',
    documentName: null,
    reconduction: '',
    valorisation: '',
    tacitRenewalYears: '',
  };
  const validationSchema = Yup.object({
    prestataire: Yup.string(),
    equipement: Yup.string(),
    effectiveDate: Yup.string().required(t('global.required_field')),
    initialDuration: Yup.string().required(t('global.required_field')),
    reconduction: Yup.boolean(),
    tacitRenewalYears: Yup.string()
      .when('reconduction', {
        is: '1',
        then: Yup.string().required(t('global.required_field')),
      }),
    noticePeriodDuration: Yup.string()
      .when('reconduction', {
        is: '1',
        then: Yup.string().required(t('global.required_field')),
      }),
    valorisation: Yup.number(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      if (values.prestataire === '') {
        formData.append('societyId', values.prestataire);
      } else {
        formData.append('societyId', society.id);
      }
      formData.append('equipmentId', values.equipement);
      formData.append('effectiveDate', format(new Date(values.effectiveDate), 'yyyy-MM-dd'));
      formData.append('initialDuration', values.initialDuration);
      formData.append('noticePeriodDuration', values.noticePeriodDuration);
      formData.append('valorisation', values.valorisation);
      formData.append('tacitRenewalYears', values.tacitRenewalYears);
      const value = {
        societyId: `${values.prestataire}`,
        equipmentId: `${values.equipement}`,
        effectiveDate: `${format(new Date(values.effectiveDate), 'yyyy-MM-dd')}`,
        initialDuration: `${values.initialDuration}`,
        valorisation: `${values.valorisation}`,
        noticePeriodDuration: `${values.noticePeriodDuration}`,
        tacitRenewalYears: `${values.tacitRenewalYears}`,
      };
      const config = {
        id: urlParams.id,
        data: value,
      };
      putContact.mutate(config);
    },
  });
  const getContractQuery = useMutation(getContract, {
    onSuccess: (res) => {
      setSociety(res.data.societyName);
      setEquipment(res.data.equipmentName);
      formik.setFieldValue('equipement', res.data.equipmentId);
      formik.setFieldValue('prestataire', res.data.societyId);
      formik.setFieldValue('effectiveDate', res.data.effectiveDate);
      formik.setFieldValue('initialDuration', res.data.initialDuration);
      formik.setFieldValue('valorisation', res.data.valorisation);
      if (res.data.tacitRenewalYears || res.data.noticePeriodDuration) {
        formik.setFieldValue('reconduction', '1');
      }
      formik.setFieldValue('noticePeriodDuration', res.data.noticePeriodDuration);
      formik.setFieldValue('tacitRenewalYears', res.data.tacitRenewalYears);
      formik.setFieldValue('documentName', res.data.documentFileName);
    },
  });

  // dowload document contract
  const dowloaddoc = (data) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'document.pdf');
    document.body.appendChild(link);
    link.click();
  };

  // call api to get document contract
  const getContractDocuments = useMutation(getContractDocument, {
    onSuccess: (data) => {
      dowloaddoc(data.data);
    },
    onError: () => {
      toast.error(t('contract.erreur_get_document'));
    },
  });

  useEffect(() => {
    getContractQuery.mutate(urlParams.id);
  }, []);
  const noticePeriodDuration = [
    {
      label: '1 mois',
      value: '1',
    },
    {
      label: '2 mois',
      value: '2',
    },
    {
      label: '3 mois',
      value: '3',
    },
    {
      label: '4 mois',
      value: '4',
    },
    {
      label: '5 mois',
      value: '5',
    },
    {
      label: '6 mois',
      value: '6',
    },
    {
      label: '7 mois',
      value: '7',
    },
    {
      label: '8 mois',
      value: '8',
    },
    {
      label: '9 mois',
      value: '9',
    },
    {
      label: '10 mois',
      value: '10',
    },
    {
      label: '11 mois',
      value: '11',
    },
    {
      label: '12 mois',
      value: '12',
    },
  ];
  return (
    <Layout
      title="Contrat"
      layout="form"
      queryError={
        putContact?.error
         || getContractQuery?.error
         || getEquipements?.error
         || prestataire?.error
         || getContractDocuments?.error
      }
    >
      <div>
        <header>
          <div className="row mb-20">
            <button
              type="button"
              className="link"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('contract.back_add')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('contract.modifContract')}</h1>
          </div>
        </header>
      </div>
      <form onSubmit={formik.handleSubmit} className="form shadow-sm">
        <div className="form_group">
          <Select
            name="prestataire"
            type="text"
            label={t('contract.prestataire')}
            value={society ? mapForSelect(prestataire).find(
              (item) => item.value === formik.values.prestataire,
            ) : null}
            onChange={(value) => formik.setFieldValue('prestataire', value.value)}
            options={mapForSelect(prestataire?.data?.data?.societies)}
            placeholder={society}
            loading={prestataire.isLoading}
          />
          {formik.errors.prestataire && formik.touched.prestataire ? (
            <div className="error">
              {t(formik.errors.prestataire)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Select
            name="equipement"
            type="text"
            label={t('contract.equipement')}
            options={mapForSelect(getEquipements?.data?.data?.equipments)}
            value={
              mapForSelect(getEquipements).find(
                (item) => item.value === formik.values.equipement,
              )
            }
            onChange={(value) => formik.setFieldValue('equipement', value.value)}
            placeholder={equipments}
            loading={getEquipements.isLoading}
          />
          {formik.errors.equipement && formik.touched.equipement ? (
            <div className="error">
              {t(formik.errors.equipement)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            name="effectiveDate"
            type="date"
            label={t('contract.dateDebut')}
            value={formik.values.effectiveDate ? format(new Date(formik.values.effectiveDate), 'yyyy-MM-dd') : ''}
            onChange={formik.handleChange}
            required
          />
          {formik.errors.effectiveDate && formik.touched.effectiveDate ? (
            <div className="error">
              {t(formik.errors.effectiveDate)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            name="initialDuration"
            type="number"
            min="0"
            label={t('contract.dureeinitial')}
            value={formik.values.initialDuration}
            onChange={formik.handleChange}
            required
          />
          {formik.errors.initialDuration && formik.touched.initialDuration ? (
            <div className="error">
              {t(formik.errors.initialDuration)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            name="valorisation"
            type="number"
            min="0"
            label={t('contract.valorisation')}
            value={formik.values.valorisation}
            onChange={formik.handleChange}
          />
          {formik.errors.valorisation && formik.touched.valorisation ? (
            <div className="error">
              {t(formik.errors.valorisation)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          {' '}
        </div>
        <div className="form_group">
          <Checkbox
            id="reconduction"
            name="reconduction"
            label={t('contract.reconduction')}
            value={formik.values.reconduction === '1' ? '1' : '0'}
            checked={formik.values.reconduction === '1'}
            onChange={(e) => formik.setFieldValue('reconduction', e.target.checked ? '1' : '0')}
            onBlur={formik.handleBlur}
          />
          {formik.errors.reconduction && formik.touched.reconduction ? (
            <div className="error">
              {t(formik.errors.reconduction)}
            </div>
          ) : null }
        </div>
        <br />
        {formik.values.reconduction === '1' && (
          <>
            <div className="form_group">
              <Input
                name="tacitRenewalYears"
                type="number"
                label={t('contract.anneerenouvellement')}
                value={formik.values.tacitRenewalYears}
                onChange={formik.handleChange}
                required={formik.values.reconduction === '1'}
              />
              {formik.errors.tacitRenewalYears
                && formik.touched.tacitRenewalYears
                && formik.touched.reconduction === 1 ? (
                  <div className="error">
                    {t(formik.errors.tacitRenewalYears)}
                  </div>
                ) : null }
            </div>
            <div className="form_group">
              <Select
                name="noticePeriodDuration"
                type="select"
                label={t('contract.dureepreavis')}
                value={
                  noticePeriodDuration.find(
                    (item) => item.value === formik.values.noticePeriodDuration,
                  )
                 }
                onChange={(value) => formik.setFieldValue('noticePeriodDuration', value.value)}
                options={noticePeriodDuration}
                required={formik.values.reconduction === '1'}
                valueInput={formik.values.noticePeriodDuration}
                placeholder={formik.values.noticePeriodDuration}
              />
              {formik.errors.noticePeriodDuration
               && formik.touched.noticePeriodDuration && formik.touched.reconduction === 1 ? (
                 <div className="error">
                   {t(formik.errors.noticePeriodDuration)}
                 </div>
                ) : null }
            </div>
          </>
        )}
        <div className="form_group">
          <Button
            label={(
              <FontAwesomeIcon icon={faFileArrowDown} transform="grow-15" />
            )}
            type="button"
            className="action edit"
            title={t('societies.edit')}
            onClick={() => getContractDocuments.mutate(urlParams.id)}
          />
        </div>
        {Permissions({ permission: 'CONTRACT_EDIT' }) !== undefined
          ? (
            <section className="form_footer">
              <div className="form_infos">
                <small>{t('add_structure.mandatory_fields')}</small>
              </div>
              <Button
                type="submit"
                className="form_submit"
                label={t('contract.add_contract')}
              />
            </section>
          ) : null }
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('contract.back_add')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default Contract;
