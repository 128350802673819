import api from './_api';

/**
 * GET - Get vesion list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 *  */
export const GetVersion = () => api.get('/version');

/**
 * GET - Get Vehicle list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 *  */
export const getVersions = () => api.get('/version');
