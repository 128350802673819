import api from './_api';

/**
 * GET - Cron securite user
 * @returns {Promise<AxiosResponse>}
 */
export const sendUserSecuriteEmailAll = () => api.get('/SecuriteUser');

/**
 * GET - Cron sanitaire user
 * @returns {Promise<AxiosResponse>}
 */
export const sendUserSanitaireEmailAll = () => api.get('/SanitaireUser');

/**
 * GET - Cron securite admin
 * @returns {Promise<AxiosResponse>}
 */
export const sendAdminSecuriteEmailAll = () => api.get('/SecuriteAdmin');

/**
 * GET - Cron sanitaire admin
 * @returns {Promise<AxiosResponse>}
 */
export const sendAdminSanitaireEmailAll = () => api.get('/SanitaireAdmin');

/**
 * GET - recap report
 * @returns {Promise<AxiosResponse>}
 */
export const sendRecapreport = () => api.get('/Recapreport');
