import api from './_api';

/**
 * GET - Get list amendements
 *  @param {Number: page, Number: contractId} pageId
 * @returns {Promise<AxiosResponse>}
 * @param {Object} queryParams
 * */
export const getAmendementsList = (queryParams) => api.get('/amendments', { params: queryParams });

/**
 * GET - Get amendement
 * @param {Number} amendementId
 * @returns {Promise<AxiosResponse>}
 * */
export const getAmendement = (amendementId) => api.get(`/amendments/${amendementId}`);

/**
 * POST - Create amendement
 * @param {Object} amendementToCreate
 * @returns {Promise<AxiosResponse>}
 * */
export const createAmendement = (amendementToCreate) => api.post('/amendments', amendementToCreate);

/**
 * Edit- edit amendement
 * @param {Object} amendementToEdit
 *  @returns {Promise<AxiosResponse>}
 */
export const editAmendement = (amendementToEdit) => api.put(`/amendments/${amendementToEdit.id}`, amendementToEdit);

/**
 * GET - Get Document amendement
 * @param {Number} contractId
 * @returns {Promise<AxiosResponse>}
 */
export const getAmendementDocument = (contractId) => api.get(`/amendments/${contractId}/download-file`);

// contractModif: 2,
// noticePeriodDuration: '',
// prestataire: '',
// equipement: '',
// effectiveDate: '',
// initialDuration: '',
// documentContract: null,
// reconduction: '',
// valorisation: '',
// tacitRenewalYears: '',

// contractModif: Yup.number(),
//     documentContract: Yup.mixed().nullable().typeError(t('global.wrong_type'))
//       .when('contractModif', {
//         is: 1,
//         then: Yup.mixed().nullable().typeError(t('global.wrong_type')).required(t('global.required_field'))
//           .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 10000000)
//           .test('type', 'global.accepted_formats', (value) => value && (value.type === 'application/pdf'
//             || value.type === 'application/x-pdf'
//             || value.type === 'image/jpeg'
//             || value.type === 'image/jpg'
//             || value.type === 'image/png'
//             || value.type === 'image/tiff'
//             || value.type === 'image/bmp'
//             || value.type === 'image/heic'
//             || value.type === 'image/vnd.dwg'
//           )),
//       }),
//     prestataire: Yup.string()
//       .when('contractModif', {
//         is: 1,
//         then: Yup.string().required(t('global.required_field')),
//       }),
//     equipement: Yup.string()
//       .when('contractModif', {
//         is: 1,
//         then: Yup.string().required(t('global.required_field')),
//       }),
//     effectiveDate: Yup.string()
//       .when('contractModif', {
//         is: 1,
//         then: Yup.string().required(t('global.required_field')),
//       }),
//     initialDuration: Yup.string()
//       .when('contractModif', {
//         is: 1,
//         then: Yup.string().required(t('global.required_field')),
//       }),
//     reconduction: Yup.boolean(),
//     tacitRenewalYears: Yup.string()
//       .when(['reconduction', 'contractModif'], {
//         is: '1' || 1,
//         then: Yup.string().required(t('global.required_field')),
//       }),
//     noticePeriodDuration: Yup.string()
//       .when(['reconduction', 'contractModif'], {
//         is: '1' || 1,
//         then: Yup.string().required(t('global.required_field')),
//       }),
//     valorisation: Yup.number(),
