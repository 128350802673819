import React from 'react';
import { toast } from 'react-toastify';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  useFormik,
} from 'formik';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from 'react-query';
import styles from './Signature.module.css';
import { getObservation, postLift } from '../../services/registre';
import Layout from '../../components/template/Layout';
import cn from '../../utils/cn';
import Button from '../../components/atoms/Button/Button';
import Canvassignature from '../../components/atoms/Canvas/Canvas';
import useAppContext from '../../store/useAppContext';
/* eslint-disable max-len */
/* eslint no-unsafe-optional-chaining: "error" */
function InternalLifting() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [signature, setSignature] = React.useState('');
  const UrlParams = useParams();
  const [info, setInfo] = React.useState([]);
  const [context] = useAppContext();
  // console.log('location', location);

  const getObservationQuery = useQuery(['getObservation'], () => getObservation({
    id: UrlParams.id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setInfo(data?.data);
    },
  });
  const postLiftMutation = useMutation(postLift, {
    onSuccess: () => {
      navigate(`/observations/remaining/${info?.report?.id}`);
      toast.success(t('raisedObs.successave'));
    },
    onError: () => {
      toast.error(t('raisedObs.echecsave'));
    },
  });

  const validationSchema = Yup.object({
    signature: Yup.mixed(),
    photo: Yup.string().default(location?.state?.photo),
    prestataireName: Yup.string().default(location?.state?.prestataireName),
    societyName: Yup.string().default(location?.state?.societyName),
    action: Yup.string().default(location?.state?.action),
  });
  const initialValues = {
    signature: '',
    photo: location?.state?.photo,
    prestataireName: location?.state?.prestataireName,
    societyName: location?.state?.societyName,
    action: location?.state?.action,
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('observationIds[0]', UrlParams?.id);
      formData.append('reportId', info?.report?.id);
      if (values.photo) {
        formData.append('documentFile', values.photo);
      }
      if (values.prestataireName) {
        formData.append('stakeholder', values.prestataireName);
      }
      if (values.societyName) {
        formData.append('society', values.societyName);
      }
      formData.append('actionPerformed', values.action);
      // formData.append('signatureFile', values.signature);
      postLiftMutation.mutate(formData);
    },
  });
  const sigCanvas = React.useRef({});
  const trim = () => {
    setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
  };
  const valid = () => {
    formik.setFieldValue('signature', signature);
    formik.handleSubmit();
  };
  const invalid = () => {
    setSignature('');
  };
  const clear = () => {
    sigCanvas.current.clear();
    setSignature('');
  };
  return (
    <Layout
      title="InternalLifting"
      table={getObservationQuery}
      layout="table"
      queryError={
        getObservationQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
        <div className={cn(['row', 'row'])}>
          <h1 className="title">{t('Lifted.title')}</h1>
        </div>
        <span className="title-info">
          {info?.equipement}
          -
          {info?.numeroReport}
        </span>
      </header>
      <div className="title">
        <div>
          <div>
            <form
              onSubmit={formik.handleSubmit}
              className="form shadow"
            >
              {signature ? (
                <>
                  <img className={styles.img} src={signature} alt="signature" />
                  <br />
                  <div className={styles.signatureContainer}>
                    <Button
                      type="button"
                      className={cn(['action suppr', styles.clearbtn])}
                      onClick={() => invalid()}
                      label="effacer"
                    />
                    <Button
                      type="button"
                      className={cn(['action', styles.trimbtn])}
                      label="Valider"
                      onClick={() => valid()}
                    />
                  </div>
                </>
              ) : (
                <div className="form_group--fullwidth">
                  {t('Lifted.signature')}
                  <Canvassignature
                    canvasProps={{
                      className: styles.canvas,
                    }}
                    name="signature"
                    trim={() => trim()}
                    clear={() => clear()}
                    setSignature={setSignature}
                    signature={signature}
                    sigCanvas={sigCanvas}
                  />
                </div>
              )}
              <br />
              <div className="form_footer">
                <div className="form_infos">
                  <small>{t('addInterventions.mandatory_fields')}</small>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default InternalLifting;
