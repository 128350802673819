import { useMutation, useQuery } from 'react-query';
import React, { useState } from 'react';
import {
  faChevronLeft,
  faChevronRight,
  faRotateRight,
  faMessage,
  faXmark,
  faFileArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getlifted, putUnlift, getCommentaire } from '../../services/registre';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import Button from '../../components/atoms/Button/Button';
import Layout from '../../components/template/Layout';
import Pagination from '../../components/molecules/Pagination';
import useAppContext from '../../store/useAppContext';
import styles from './Observation.module.css';
import Table from '../../components/molecules/Table/Table';
import cn from '../../utils/cn';
import Permissions from '../../components/molecules/Permissions/Permissions';
/* eslint-disable react/prop-types */

function ObservationRemaining() {
  const [context] = useAppContext();
  const [supplierToDelete, setsupplierToDelete] = useState('');
  const [vacations, setVacations] = React.useState([]);
  const [title, setTitle] = React.useState('');
  const urlParams = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [OpenCommentaire, setOpenCommentaire] = useState(false);
  const [commentId, setCommentId] = useState();
  const [comments, setComments] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };
  const customCommentaireStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '50%',
      height: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };
  const getliftedQuery = useQuery(['getlifted'], () => getlifted({
    id: urlParams.id,
    data: {
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
    },
  }), {
    onSuccess: (data) => {
      setVacations(data?.data?.observations);
      setTitle(data?.data?.interventionName);
    },
  });

  const getCommentaireQuery = useQuery(['getCommentaire'], () => getCommentaire({
    id: commentId,
  }), {
    enabled: commentId !== undefined && OpenCommentaire === true,
    onSuccess: (data) => {
      setComments(data?.data?.comments);
    },
  });

  const putUnliftMutation = useMutation(putUnlift, {
    onSuccess: () => {
      toast.success(t('observation.obs_rest'));
      getliftedQuery.refetch();
    },
  });

  const handleCommentaire = (id) => {
    setOpenCommentaire(true);
    setCommentId(id);
  };
  const handleDelete = (id) => {
    setOpenModal(false);
    putUnliftMutation.mutate(id);
  };
  const handleModal = (id) => {
    setOpenModal(true);
    setsupplierToDelete(id);
  };
  const docfile = (id, data) => {
    window.open(`http://api-ecarnet.dev.zol.fr/reports/download-report/${id}/${data}`, '_blank');
  };

  const collum = React.useMemo(
    () => [
      {
        Header: t('observation.observationNumber'),
        accessor: 'number',
      },
      {
        Header: t('Lifted.observationName'),
        accessor: 'title',
      },
      {
        Header: t('Lifted.observationDate'),
        accessor: 'liftDate',
        Cell: ({ row: { original: { liftDate } } }) => (
          <span>{new Date(liftDate).toLocaleDateString()}</span>
        ),
      },
      {
        Header: t('Lifted.liftedName'),
        accessor: 'creatorName',
        Cell: ({ row: { original: { creatorName, society } } }) => (
          society && creatorName ? (
            <div>
              <span>{creatorName}</span>
              -
              <span>{society}</span>
            </div>
          ) : (
            <div>
              <span>{creatorName}</span>
              -
              <span>{t('Lifted.noSociety')}</span>
            </div>
          )
        ),
      },
      {
        Header: t('Lifted.document'),
        accessor: 'documentFileName',
        Cell: ({ row: { original: { documentFileName, id } } }) => (
          <div>
            {documentFileName && (
              <Button
                label={(
                  <FontAwesomeIcon icon={faFileArrowDown} transform="grow-15" />
                )}
                type="button"
                className="action edit"
                title={t('societies.edit')}
                onClick={() => docfile(id, documentFileName)}
              />
            )}
          </div>
        ),
      },
      {
        Header: t('Lifted.action'),
        accessor: 'actionPerformed',
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ row: { original: { id } } }) => (
          <div className={styles.button} key={id}>
            <Button
              type="button"
              className={cn([styles.form_cancel, 'action'])}
              alt="les commentaires"
              title={t('observation.AllComments')}
              label={<FontAwesomeIcon icon={faMessage} transform="grow-5" />}
              onClick={() => handleCommentaire(id)}
            />
            {Permissions({ permission: 'OBSERVATION_UNLIFT' }) !== undefined
              ? (
                <Button
                  type="button"
                  className="action"
                  label={(
                    <FontAwesomeIcon icon={faRotateRight} />
                  )}
                  onClick={() => handleModal(id)}
                  title={t('Lifted.restaure')}
                />
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Layout
        title="Observations Levées"
        layout="table"
        queryError={
          getliftedQuery?.error
          || getCommentaireQuery?.error
        }
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate(-2)}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('observation.back')}</span>
            </button>
          </div>
          <div className={cn(['row', styles.row])}>
            <h1 className="title">
              {t('observation.levéeTitle')}
              :
            </h1>
          </div>
          <span className="title-info">
            {title}
          </span>
        </header>
        <div className={styles.table}>
          <Table
            columns={collum}
            isLoading={getliftedQuery.isLoading}
            data={vacations || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getliftedQuery?.data?.total || 0}
                currentPage={page}
                pageSize={10}
                numberElementsDisplayed={getliftedQuery?.data?.data?.lifted?.length || 0}
              />
            )}
          />
        </div>
        <footer>
          <div className="row mt-20">
            <button type="button" className="link" onClick={() => navigate(-2)}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('observation.back')}</span>
            </button>
          </div>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyle}
      >
        <p className="modal_paragraph">{t('global.modal_restor')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.restaurer')}
            onClick={() => handleDelete(supplierToDelete)}
          />
        </div>
      </Modal>
      <Modal
        isOpen={OpenCommentaire}
        onRequestClose={() => setOpenCommentaire(false)}
        style={customCommentaireStyle}
      >
        <div>
          <Button
            type="button"
            className={cn([styles.buttoncommentaire, 'modal_buttons'])}
            label={<FontAwesomeIcon icon={faXmark} transform="10" />}
            onClick={() => setOpenCommentaire(false)}
          />
        </div>
        <p className={cn([styles.Titlecommentaire, 'modal_paragraph'])}>
          {t('raisedObs.TitleCommentaire')}
          {' '}
          {commentId}
        </p>
        <br />
        <div className={styles.getcommentaire}>
          {comments?.map((commentaire) => (
            <div key={commentaire.id} className={styles.commentaires}>
              <div className={styles.divcommentaire}>
                <p className={styles.commentaire}>
                  {commentaire.content}
                </p>
                <p className={styles.usercommentaire}>
                  {commentaire.creatorName}
                </p>
                <p>
                  {new Date(commentaire.createdAt).toLocaleDateString()}
                </p>
              </div>
              <br />
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
}

export default ObservationRemaining;
