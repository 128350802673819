/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useMemo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faChevronRight, faPlus, faCirclePlus,
} from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
// Components
// import { CSVLink } from 'react-csv';
import Layout from '../../components/template/Layout';
import Search from '../../components/molecules/Search/Search';
import Table from '../../components/molecules/Table/Table';
import Pagination from '../../components/molecules/Pagination';
import Button from '../../components/atoms/Button/Button';
// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import useAppContext from '../../store/useAppContext';
// Services
import { getReportsList } from '../../services/reports';
import { getBuildingsList } from '../../services/structures';
// Styles
import styles from './FillingReports.module.css';
// Utils
import { formatDateToUser } from '../../utils/dates';
import cn from '../../utils/cn';
import { downloadFileCsv } from '../../utils/downloadFile';
import Permissions from '../../components/molecules/Permissions/Permissions';

function FillingReports() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [context] = useAppContext();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const [search, setSearch] = useState('');
  const [buildingId, setBuildingId] = useState();
  const currentPage = window.location.href.split('=')[1];
  const [response, setResponse] = useState([]);
  const [dowloadInfo, setDowloadInfo] = useState([]);

  // API Calls
  const getBuildingsQuery = useQuery('buildings', () => getBuildingsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      if (data?.data?.buildings?.length > 0) setBuildingId(data.data.buildings[0].id);
    },
  });
  const getReportsListQuery = useQuery(['reports', page, search, buildingId], () => getReportsList({
    bookletId: context.choiceBooklet,
    establishmentId: context.choiceEstablishment.id,
    page: 0,
    search,
    buildingId,
  }), {
    enabled: buildingId !== undefined,
    onSuccess: (data) => {
      setResponse(data?.data?.reports);
      setDowloadInfo(data?.data?.reports);
    },
  });

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  useEffect(() => {
    // sorry, for this hacky function, but it exist a little defer
    // between the feedback of back after post and the real refresh of list
    // and as back dev say it's not from DB then..
    setTimeout(() => getReportsListQuery.refetch(), 1500);
  }, []);

  const calculeRange = (pagest, size) => {
    const start = (pagest - 1) * size;
    const end = start + size;
    if (end > getReportsListQuery?.data?.data?.reports?.length) {
      return { start, end };
    }
    return { start, end: end - 1 };
  };
  const { start, end } = calculeRange(currentPage, 10);

  const isDateBeforeToday = (dateToCheck) => dateToCheck && new Date(dateToCheck) < new Date();

  const handleBuilding = (id) => {
    setBuildingId(id);
    getReportsListQuery.refetch().then(() => {
      setResponse(getReportsListQuery?.data?.data?.reports);
    });
    getReportsListQuery.remove();
  };
  const hearder = [
    {
      label: `${t('reports.building')}`, key: 'buildingName',
    },
    {
      label: `${t('reports.name')}`, key: 'title',
    },
    {
      label: `${t('reports.date')}`,
      key: 'reportDate',
      cell: (row) => new Date(row.original.reportDate).toLocaleDateString(),
    },
  ];

  const handleDowload = () => {
    downloadFileCsv(dowloadInfo, hearder, `${t('reports.title')}`);
  };
  const columns = useMemo(
    () => [
      {
        Header: `${t('reports.building')}`,
        accessor: 'buildingName',
        Cell: ({ row: { original: { buildingName, reportDate } } }) => (
          <div className={`${isDateBeforeToday(reportDate) ? 'tag--error' : ''}`}>
            {buildingName}
          </div>
        ),
      },
      {
        Header: `${t('reports.name')}`,
        accessor: 'title',
        Cell: ({ row: { original: { title, reportDate } } }) => (
          <div className={`${isDateBeforeToday(reportDate) ? 'tag--error' : ''}`}>
            {title}
          </div>
        ),
      },
      {
        Header: `${t('reports.date')}`,
        accessor: 'reportDate',
        Cell: ({ row: { original: { reportDate } } }) => (
          <div className={`${isDateBeforeToday(reportDate) ? 'tag--error' : ''}`}>
            {formatDateToUser(reportDate)}
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id, societyInChargeId, societyInChargeName } } }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to="/rapports/deposit"
                  title={t('reports.deposit')}
                  state={{
                    interventionId: id,
                    societyInChargeId,
                    societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faCirclePlus} style={{ width: '1.5rem', height: '1.5rem' }} />
                </Link>
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  const TableauVehicule = useMemo(
    () => [
      {
        Header: `${t('reports.name')}`,
        accessor: 'title',
        Cell: ({ row: { original: { title, reportDate } } }) => (
          <div className={`${isDateBeforeToday(reportDate) ? 'tag--error' : ''}`}>
            {title}
          </div>
        ),
      },
      {
        Header: `${t('reports.date')}`,
        accessor: 'reportDate',
        Cell: ({ row: { original: { reportDate } } }) => (
          <div className={`${isDateBeforeToday(reportDate) ? 'tag--error' : ''}`}>
            {formatDateToUser(reportDate)}
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id, societyInChargeId, societyInChargeName } } }) => (
          <div className="actions">
            <Link
              className="action"
              to="/vehicule/document_attente/deposit"
              title={t('reports.deposit')}
              state={{
                interventionId: id,
                societyInChargeId,
                societyInChargeName,
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Link>
          </div>
        ),
      },
    ],
    [],
  );

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  return (
    <Layout
      title={t('reports.title')}
      layout="table"
      queryError={getReportsListQuery?.error || getBuildingsQuery?.error || getReportsListQuery?.error}
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigateDashboards()}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
        <div className="row">
          {context.choiceBooklet === 3 ? (
            <h1 className="title">{t('reports.titleVehicule')}</h1>
          ) : (
            <h1 className="title">{t('reports.title')}</h1>
          )}
        </div>
        <div className="row">
          {/* <button
            className="add"
            type="button"
            onClick={handleDowload}
          >
            <FontAwesomeIcon icon={faFileCsv} />
            {' '}
            {t('reports.download')}
          </button> */}
          <Search className="mb-20" onChange={handleSearch} />
        </div>
      </header>
      <div className={styles.filters}>
        <div className={styles.filtersToComplete}>
          {getBuildingsQuery?.data?.data?.buildings?.map((building) => (
            building.bookletId !== 3 && (
            <Button
              type="button"
              label={`${building.name}`}
              className={cn([styles.filter, buildingId === building.id
                ? styles.active
                : '', 'shadow-md'])}
              onClick={() => handleBuilding(building?.id)}
            />
            )
          ))}
        </div>
      </div>
      <div className={context.choiceBooklet === 3 ? styles.tableauVehicule : styles.tableau}>
        <Table
          columns={context.choiceBooklet === 3 ? TableauVehicule : columns}
          isLoading={getReportsListQuery.isLoading}
          data={response || []}
          hasSort
          pagination={(
            <Pagination
              previousLabel={(
                <FontAwesomeIcon icon={faChevronLeft} />
                )}
              nextLabel={(
                <FontAwesomeIcon icon={faChevronRight} />
                )}
              onPageChange={onPageChange}
              totalCount={getReportsListQuery?.data?.data?.total || 0}
              currentPage={page}
              pageSize={10}
            />
          )}
        />
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigateDashboards()}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default FillingReports;
