/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Select from '../../atoms/Select/Select';
import ControlType from '../../template/ControlType/ControlType';
import Button from '../../atoms/Button/Button';
import Style from './inputStyle.module.css';
import cn from '../../../utils/cn';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function EtatPneu({ id, value, handleSubmit }) {
  const { t } = useTranslation();

  const formSelectOptions = [
    { value: 'bon', label: t('inputs.good') },
    { value: 'moyen', label: t('inputs.medium') },
    { value: 'use', label: t('inputs.worn') },
  ];

  const [valuesGenerating, setValuesGenerating] = useState(value);
  const [openModal, setOpenModal] = useState(false);

  const columns = [
    {
      label: value.title,
    },
    {
      label: t('inputs.state'),
      width: 230,
    },
  ];

  const handleValuesSubmit = () => {
    handleSubmit({
      inputId: valuesGenerating?.id,
      12: valuesGenerating?.avd,
      11: valuesGenerating?.avg,
      22: valuesGenerating?.ard,
      21: valuesGenerating?.arg,
    });
    setOpenModal(false);
  };

  const isAtLeastOneValueNull = () => {
    if (
      valuesGenerating?.avd === null
     || valuesGenerating?.avg === null
     || valuesGenerating?.ard === null
     || valuesGenerating?.arg === null
     || valuesGenerating?.avd === undefined
     || valuesGenerating?.avg === undefined
     || valuesGenerating?.ard === undefined
     || valuesGenerating?.arg === undefined
    ) {
      return true;
    }
    return false;
  };

  const selectInputs = [
    { label: t('inputs.avd'), value: 'avd', id: 'avd' },
    { label: t('inputs.avg'), value: 'avg', id: 'avg' },
    { label: t('inputs.ard'), value: 'ard', id: 'ard' },
    { label: t('inputs.arg'), value: 'arg', id: 'arg' },
  ];

  return (
    <>
      <ControlType columns={columns} onSubmit={() => (isAtLeastOneValueNull() ? setOpenModal(true) : handleValuesSubmit())}>
        {selectInputs.map((input) => (
          <div className="inputForm" key={value.id + input.id}>
            <div className="inputForm_label inputForm_control">
              {input.label}
            </div>
            <div className={cn(['inputForm_control inputForm_control--center', Style.inputVehicule_select])}>
              <Select
                id={`${id}-${input.id}`}
                value={valuesGenerating[input.id]}
                options={formSelectOptions.map((option) => ({ ...option, label: t(option.label) }))}
                onChange={(event) => {
                  setValuesGenerating({
                    ...valuesGenerating,
                    [input.id]: event,
                  });
                }}
              />
            </div>
          </div>
        ))}
      </ControlType>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_some_value_neutral')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.no')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.yes')}
            onClick={handleValuesSubmit}
          />
        </div>
      </Modal>
    </>
  );
}
EtatPneu.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default EtatPneu;
