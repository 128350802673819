/* eslint-disable react/jsx-props-no-spreading */
import * as Yup from 'yup';
import {
  Formik, Form, ErrorMessage,
} from 'formik';
import { useState } from 'react';
// import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  faChevronLeft,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/template/Layout';
import SelectForm from '../../components/atoms/Select/Select';
import Input from '../../components/atoms/Input/Input';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';
import Radio from '../../components/atoms/Radio/Radio';
import Button from '../../components/atoms/Button/Button';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import './AddAnalyse.css';
import { createAnalyse } from '../../services/analyses';

function AddAnalyse() {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [array, setArray] = useState([0]);
  const navigate = useNavigate();
  const context = JSON.parse(sessionStorage.getItem('context'));
  const etablissement = context.choiceEstablishment.id;
  const validationSchema = Yup.object().shape({
    type: Yup.string()
      .required(t('global.required_field')),
    samplingDate: Yup.date()
      .required(t('global.required_field')),
    document: Yup.mixed()
      .required(t('global.required_field'))
      .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 10000000)
      .test(
        'fileFormat',
        'Le format de fichier est invalide :Les formats autorisés sont "pdf"; "autocad" et tous les formats "image"',
        (value) => {
          if (value) {
            return value.type === 'application/pdf'
              || value.type === 'application/x-pdf'
              || value.type === 'image/jpeg'
              || value.type === 'image/jpg'
              || value.type === 'image/png'
              || value.type === 'image/tiff'
              || value.type === 'image/bmp'
              || value.type === 'image/heic'
              || value.type === 'image/vnd.dwg';
          }
          return true;
        },
      ),
    Analysis_point: Yup.mixed()
      .required(t('global.required_field')),
    ucf: Yup.array()
      .of(Yup.string()),
    isPneumophila: Yup.array()
      .of(Yup.mixed()
        .test('isPneumophila', 'Le champ est obligatoire', (value) => {
          if (value) {
            return value !== 'null';
          }
          return true;
        })),
    quantite: Yup.mixed(),
    conforme: Yup.mixed()
      .when('type', {
        is: 'potability' || 'standard_care' || 'physico_chemical' || 'other_analyzes',
        then: Yup.mixed()
          .required(t('global.required_field')),
      }),
  });

  const initialValues = {
    type: '',
    samplingDate: '',
    document: '',
    Analysis_point: [],
    ucf: '',
    isPneumophila: '',
    conforme: '',
    quantite: '',
  };
  const handleAdd = () => {
    const counts = count + 1;
    setCount(counts);
    const arr = Array.from(array);
    arr.push(counts);
    setArray(arr);
  };

  const CreateAnalyse = useMutation(createAnalyse, {
    onSuccess: () => {
      toast.success((t('contact.success')));
      navigate('/analyse');
    },
    onError: () => {
      toast.error((t('contact.error')));
    },
  });
  const handleSubmite = (values) => {
    const formData = new FormData();
    formData.append('type', values.type);
    formData.append('samplingDate', values.samplingDate);
    formData.append('establishmentId', etablissement);
    formData.append('reportFile', values.document, values.document.name);
    array.forEach((element) => {
      formData.append(`points[${element}][name]`, values.Analysis_point[element]);
      if (values.ucf[element] === '1') {
        formData.append(`points[${element}][underTenUfcPerLiter]`, 1);
      } else {
        formData.append(`points[${element}][underTenUfcPerLiter]`, 0);
      }
      if (values.quantite[element]) {
        if (values.quantite[element] === undefined) {
          formData.append(`points[${element}][quantity]`, 0);
        } else {
          formData.append(`points[${element}][quantity]`, values.quantite[element]);
        }
      }
      if (values.isPneumophila[element]) {
        formData.append(`points[${element}][isPneumophila]`, values.isPneumophila[element]);
      }
      if (values.conforme[element]) {
        formData.append(`points[${element}][valid]`, values.conforme[element]);
      }
    });
    CreateAnalyse.mutate(formData);
  };

  const handleRemove = (index) => {
    if (array.length > 1) {
      const arr = Array.from(array);
      arr.splice(index, 1);
      setArray(arr);
    }
  };

  const opt = [
    { value: 'legionella', label: 'Légionelles' },
    { value: 'potability', label: 'Potabilité' },
    { value: 'standard_care', label: 'Soins Standars' },
    { value: 'physico_chemical', label: 'Physico-Chimique' },
    { value: 'other_analyzes', label: 'Autre Analyses' },
  ];
  const controlSelected = opt.find((control) => control.value === initialValues.type);

  return (
    <Layout
      title={t('analyses.addtitle')}
      layout="table"
      queryError={validationSchema?.error || initialValues?.error || initialValues?.error}
    >
      <header className="header">
        <button type="button" className="link" onClick={() => navigate('/analyse')}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('analyses.back')}</span>
        </button>
        <br />
        <div className="row">
          <h1 className="title">{t('analyses.add')}</h1>
        </div>
      </header>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(value) => {
            handleSubmite(value);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} className="form shadow-sm">
              <div>
                <div>
                  <div className="form_group">
                    <SelectForm
                      name="type"
                      label={t('analyses.type')}
                      options={opt}
                      value={controlSelected}
                      onChange={(value) => setFieldValue('type', value.value)}
                      onBlur={handleBlur}
                      error={errors.type}
                      touched={touched.type}
                      valueInput={values.type}
                      required
                    />
                    {errors.type && touched.type && (
                    <div className="error">
                      <ErrorMessage name="type" />
                    </div>
                    )}
                  </div>
                  <div className="form_group">
                    <Input
                      name="samplingDate"
                      label={t('analyses.date_creation_input')}
                      type="date"
                      value={values.samplingDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.samplingDate}
                      touched={touched.samplingDate}
                      required
                    />
                    {errors.samplingDate && touched.samplingDate && (
                    <div className="error">
                      <ErrorMessage name="samplingDate" />
                    </div>
                    )}
                  </div>
                  {values.type === 'legionella' && (
                    <div className="legionnella">
                      <div className="form_group">
                        {array.map((item) => (
                          <div key={item} className="liner">
                            <div className="form_group">
                              <Input
                                name={`Analysis_point[${item}]`}
                                label="Point d'analyse"
                                type="text"
                                value={values.Analysis_point[item]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.Analysis_point}
                                touched={touched.Analysis_point}
                                required
                              />
                            </div>
                            {errors.Analysis_point && touched.Analysis_point && (
                              <div className="error">
                                <ErrorMessage name="Analysis_point" />
                              </div>
                            )}
                            <div className="form_group">
                              <Checkbox
                                id="ucf"
                                name={`ucf[${item}]`}
                                label="<10 UCF/L"
                                value={values.ucf[item] === 1 ? 1 : 0}
                                checked={values.ucf[item] === '1'}
                                onChange={(event) => (event.target.checked
                                  ? setFieldValue(`ucf[${item}]`, '1') : setFieldValue(`ucf[${item}]`, '0'))}
                                onBlur={handleBlur}
                              />
                            </div>
                            {errors.ucf && touched.ucf && (
                              <div className="error">
                                <ErrorMessage name="ucf" />
                              </div>
                            )}
                            <div className="form_group">
                              <Input
                                name={`quantite[${item}]`}
                                label="Quantité"
                                type="number"
                                value={values.quantite[item]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.quantite}
                                min="0"
                                touched={touched.quantite}
                                disabled={values.ucf[item] === '1' || values.ucf[item] === ''}
                                required={values.ucf[item] !== '1'}
                              />
                            </div>
                            {errors.quantite && touched.quantite && values.ucf !== '1' && (
                              <div className="error">
                                <ErrorMessage name="quantite" />
                              </div>
                            )}
                            {values.ucf[item] !== '1' ? (
                              <div>
                                <div className="liner">
                                  <div className="pneumo_block">
                                    <div className="form_group">
                                      <Radio
                                        id="conforme"
                                        name={`isPneumophila[${item}]`}
                                        label="Non Pneumophila"
                                        type="radio"
                                        value="0"
                                        checked={values.isPneumophila[item] === '0'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.isPneumophila}
                                        touched={touched.isPneumophila}
                                        disabled={values.ucf[item] === '1' || values.ucf[item] === ''}
                                        required={values.ucf[item] !== '1' || values.ucf[item] !== ''}
                                      />
                                    </div>
                                    <div className="form_group">
                                      <Radio
                                        id="conforme"
                                        name={`isPneumophila[${item}]`}
                                        label="Pneumophila"
                                        type="radio"
                                        value="1"
                                        checked={values.isPneumophila[item] === '1'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.isPneumophila}
                                        touched={touched.isPneumophila}
                                        required={values.ucf[item] !== '1' || values.ucf[item] !== ''}
                                      />
                                    </div>
                                    {errors.isPneumophila && (
                                      <div className="error">
                                        <ErrorMessage name="isPneumophila" />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="liner">
                                {' '}
                              </div>
                            )}
                            <div className="form_group supprime" id="plus">
                              <Button
                                type="button"
                                label={<FontAwesomeIcon icon={faTrash} />}
                                className="action suppr"
                                onClick={() => handleRemove(item)}
                              />
                              <br />
                              <Button
                                type="button"
                                label={<FontAwesomeIcon icon={faPlus} />}
                                className="action"
                                onClick={() => handleAdd()}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {values.type === 'legionella' || values.type === '' || (
                  <div>
                    <div className="from_group">
                      {array.map((item) => (
                        <div key={item} className="liner">
                          <div className="conform">
                            <Input
                              name={`Analysis_point[${item}]`}
                              label="Point d'analyse"
                              type="text"
                              value={values.Analysis_point[item]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.Analysis_point}
                              touched={touched.Analysis_point}
                              required
                            />
                            {errors.Analysis_point && touched.Analysis_point && (
                              <div className="error">
                                <ErrorMessage name="Analysis_point" />
                              </div>
                            )}
                          </div>
                          <div className="form_group conforme">
                            <Radio
                              id="conforme"
                              name={`conforme[${item}]`}
                              label="Conforme"
                              type="radio"
                              value="1"
                              checked={values.conforme[item] === '1'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.conforme}
                              touched={touched.conforme}
                              required={values.type[item] !== 'legionella' && values.conforme[item] !== '0'}
                            />

                            <Radio
                              id="conforme"
                              name={`conforme[${item}]`}
                              label="Non conforme"
                              type="radio"
                              value="0"
                              checked={values.conforme[item] === '0'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.conforme}
                              touched={touched.conforme}
                              required={values.type[item] !== 'legionella' && values.conforme[item] !== '1'}
                            />
                          </div>
                          {errors.conforme && touched.conforme && (
                            <div className="error">
                              <ErrorMessage name="conforme" />
                            </div>
                          )}
                          <div className="form_group supprimer" id="plus">
                            <Button
                              type="button"
                              label={<FontAwesomeIcon icon={faTrash} />}
                              className="action suppr"
                              onClick={() => handleRemove(item)}
                            />
                            <br />
                            <Button
                              type="button"
                              label={<FontAwesomeIcon icon={faPlus} />}
                              className="action"
                              onClick={() => handleAdd()}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  )}
                  <div className="form_group file">
                    <UploadFile
                      id="document"
                      name="document"
                      label="Document"
                      fileName={values.document ? values.document.name : values.documentName}
                      onChange={(event) => setFieldValue('document', event.currentTarget.files[0])}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="document" component="div" className="error" />
                  </div>
                </div>
                <div className="form_group send">
                  <Button type="submit" label="SAUVEGARDER" className="form_submit" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <footer>
        <button type="button" className="link" onClick={() => navigate('/analyse')}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('analyses.back')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default AddAnalyse;
